<template>
  <v-row>
    <v-card-title>
      <h1 color="primary" class="ph-nonex-card-title">Remco Partners</h1>
    </v-card-title>
    <v-card-text class="ph-nonex-card border border-solid border-black" style="width: 100%;">
      <v-row class="custom-row" gutters="0" justify="start">
        <v-col cols="4" v-for="(value, index) in filteredList" :key="index">
          <v-card elevation="0">
            <v-checkbox
              v-model="selectedItems"
              :value="value.id"
              class="custom-checkbox"
              hide-details="auto"
              append-icon="mdi-circle"
              @change="emitSelectedItems"
            >
              <template #append>
                <!-- Append the mdi-circle icon with dynamic color -->
                <v-icon :style="{ color: (value.online === 1) ? '#46B746' : '#E79BA2' }">mdi-circle</v-icon>
              </template>
              <template #label>
                <span class="checkbox-label">{{ value.remco_name }}</span>
                <span class="checkbox-message">Ex. {{ value.sample_control_number }}</span>
              </template>
            </v-checkbox>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.common.js'
export default {
  props: {
    controlNumber: {
      type: String
    }
  },
  data () {
    return {
      remcoList: [],
      statusList: [],
      filterList: [],
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters({
      nonexRemcoList: 'nonex/getNonexRemcoList',
      remcos: 'nonex/remcos'
    }),
    filteredList () {
      // return unique remco item list
      return this.filterList.filter((item, index, self) =>
        index === self.findIndex((t) => t.remco_name === item.remco_name)
      )
    }
  },
  watch: {
    remcos: {
      handler (getRemco) {
        // update the list base on the status of the remco
        this.statusList = getRemco.data.filter(item => item.can_receive_money === true)
      },
      deep: true
    },
    async controlNumber (newVal) {
      const list = []
      // change the list base on regex pattern of the remco
      this.remcoList.forEach(data => {
        const pattern = new RegExp(data.regex_pattern)
        const partial = new RegExp(data.partial_regex)

        if (partial.test(newVal) || pattern.test(newVal)) {
          list.push(data)
        }
      })

      list.forEach(data => {
        // Find a matching item in statusList based on nonex_code and remco_id
        const match = this.statusList.find(item => item.non_ex_code === String(data.remco_id))
        // If a match is found, set the online property
        if (match) {
          data.online = match.status
        } else {
          data.online = null
        }
      })
      // Ensure unique `remco_name`
      this.filterList = list.filter((item, index, self) =>
        index === self.findIndex((t) => t.remco === item.remco)
      )
      // Filter the list base on the online and status
      this.filterList = this.filterList.filter(item => item.status)
      this.filterList = this.filterList.filter(item => item.online)
      // Clear selected items if filtered list changes
      this.selectedItems = []
    },
    filterList: {
      immediate: true,
      handler (newList) {
        // filter the selectedItems base on online and if remco is not null
        this.selectedItems = newList
          .filter(item => item.online && item.remco !== null)
          .map(item => item.id)
        // emmit the selected items
        this.emitSelectedItems()
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('nonex/nonexRemcoList')
    this.remcoList = this.nonexRemcoList
    await this.$store.dispatch('nonex/getRemcos')
  },
  methods: {
    emitSelectedItems () {
      console.log('selct', this.selectedItems)
      this.$emit('selectedRemcos', this.selectedItems)
    }
  }
}
</script>
<style scoped>
.ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }
.custom-checkbox {
  background-color: #f3f5ff;
  border-color: #e8eaff;
  padding: 10px;
  margin: 0px;
  width: 500px;
}
.custom-checkbox .checkbox-label {
  font-weight: bold;
}
.custom-checkbox .checkbox-message {
  color: gray;
  font-size: 12px;
  font-style: italic;
  margin-left: 5px;
}
.custom-row {
  margin: 5px;
  padding: 0px;
}
.custom-col {
  padding: 0px;
  margin: 5px;
}
</style>

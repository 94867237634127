<template>
  <div>
    <v-row class="mt-md-5" no-gutters>
      <v-col :cols="12" :md="4">
        <h3 class="pb-md-6 custom-heading primary--text">
          KYC Lookup
        </h3>
        <p class="text-body-2">
          Search for Last name, First name<br>e.g. Doe, John
        </p>
      </v-col>
      <v-col class="pl-md-6" :cols="12" :md="8">
        <h4 class="mb-5 grey--text text--darken-2">
          Customer's name
        </h4>
        <v-text-field
          v-model="firstName"
          class="ph-textbox mb-md-6 ph-textbox-quickpay"
          label="First Name"
          @input="convertToProperCase('firstName')"
          @keyup.enter="doSearch"
          outlined
        />
        <v-text-field
          v-model="lastName"
          class="ph-textbox mb-md-6 ph-textbox-quickpay"
          label="Last Name"
          @input="convertToProperCase('lastName')"
          @keyup.enter="doSearch"
          outlined
        />
        <div class="text-right">
          <v-btn @click="doSearch" :disabled="!firstName || !lastName" class="rounded-lg ph-search-customer" color="primary" large>
            Search
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="resultDialog" persistent :width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '100%' : '60%'">
      <v-card class="rounded-lg">
        <v-card-title class="py-md-6">
          <h3 class="custom-heading primary--text">
            PERA Hub Customer Results
          </h3>
          <v-spacer />
          <v-btn @click="resultDialog = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="searchData.results ? (searchData.results.length > 0 ? true : false) : false">
          <v-row align="center" no-gutters>
            <v-col :cols="12" :md="7">
              <strong>Select Customer</strong>
            </v-col>
            <v-col :cols="12" :md="5">
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                class="ph-textbox rounded-lg"
                placeholder="Search"
                outlined
              />
            </v-col>
          </v-row>
          <v-data-table
            class="mt-md-6 ph-clickable-table"
            @click:row="selectCustomer"
            :headers="headers"
            :items="searchData.results"
            :items-per-page="5"
            :search="searchText"
            no-data-text="No customers found"
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ toProperCase(item.gender) || '-' }}
            </template>
            <template v-slot:[`item.mobile_no`]="{ item }">
              {{ item.mobile_no || '-' }}
            </template>
          </v-data-table>
          <div class="d-flex align-center">
            <span>Can't find customer here?</span>
            <v-btn
              @click="addNewCustomer"
              color="primary"
              x-small
              text
            >
              Add New Customer
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true">
          <h3>Looks like the customer you are looking for doesn't exist.<br> You need to create a new customer record for this transaction or retry searching for that customer.</h3>
        </v-card-text>
        <v-divider v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true"></v-divider>
        <v-card-actions v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true">
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="showResult = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            theme="dark"
            v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true"
            :to="{ path: '/customers/new', query: { persistData: true, redirectURL: this.$route.path, isMobileUser: isMobileUser } }"
            @click="showResult = false"
          >
            Create New Customer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getForeignRefNo,
  avatarColor,
  avatarInitial,
  toProperCase,
  trim,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      searchData: 'customers/searchData',
      oarNumber: 'oar/oarNumber'
    })
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      resultDialog: false,
      searchText: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      showRemoteNotice: false,
      isMobileUser: false,
      headers: [
        {
          text: 'Customer No.',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'Mobile Number',
          sortable: true,
          value: 'mobile_no'
        }
      ]
    }
  },
  methods: {
    renderToast,
    addNewCustomer () {
      this.$router.push({
        path: '/customers/new',
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    convertToProperCase (field) {
      this[field] = toProperCase(trim(this[field]))
    },
    async selectCustomer (customerDetails) {
      // const formNumber = await this.$store.dispatch('getFormNumber', {
      //   locationID: this.locationId,
      //   type: 'OAR'
      // })
      // Checking of available offer for the customer
      const { code, result } = await this.$store.dispatch('prompt/checkAvailableOffer', {
        customer_number: customerDetails.customer_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      // Populate hidden and masked customer information
      const custDetails = await this.$store.dispatch('customers/getCustSpecificDetails', {
        customer_number: customerDetails.customer_number,
        customer_id: customerDetails.customer_id.toString()
      })

      if (code === 200 && result) {
        this.$store.commit('prompt/SET_AVAILABLE_OFFER', result)
        this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', true)
      }

      const isDuplicate = false

      // await this.$store.dispatch('customerProfileV3/checkIfDuplicate', customerDetails.customer_number).then(res => {
      //   if (!(res.code >= 200 && res.code <= 299)) {
      //     isDuplicate = true
      //     this.renderToast('error', 'Customer transaction already exists!', res.error.message)
      //   } else {
      //     isDuplicate = false
      //   }
      // }).catch(err => {
      //   return err
      // })

      if (!isDuplicate) {
        const foreignRefNo = await getForeignRefNo()
        this.$store.commit('customers/SET_SELECTED_CUSTOMER', custDetails.result[0])

        const updateState = [
          {
            prop: 'senderFirstName',
            value: customerDetails.first_name
          },
          {
            prop: 'senderMiddleName',
            value: customerDetails.middle_name
          },
          {
            prop: 'senderLastName',
            value: customerDetails.last_name
          },
          {
            prop: 'senderBirthDate',
            value: customerDetails.birth_date
          },
          {
            prop: 'refNo',
            value: foreignRefNo
          }
        ]
        updateState.map((payload) => this.$store.commit('send/SET_TRANSACTION_DATA', payload))

        this.$store.commit('debitCard/SET_DEBIT_CARD_CURRENT_PAGE', 1)
      }
    },
    async doSearch () {
      if (this.firstName && this.lastName) {
        const firstName = this.firstName.replace(/(^\s+|\s+$)/g, '')
        const lastName = this.lastName.replace(/(^\s+|\s+$)/g, '')
        const keyword = `${lastName},${firstName}`

        this.searchData.results = []
        await this.$store.dispatch('customers/doSearch', keyword).then(res => {
          if (res.code >= 200 && res.code <= 210) {
            this.resultDialog = true
          }
        })

        this.$store.commit('send/SET_CURRENT_STEP', 1)
      } else {
        this.searchData.results = []
        this.renderToast('error', 'Search Error', 'The required fields must be completed.')
      }
    },
    toProperCase,
    avatarColor,
    avatarInitial
  },
  async mounted () {
    this.$store.commit('cico/SET_CASHIN_CURRENT_TRX', null)
    this.$store.commit('cico/SET_CASHOUT_CURRENT_TRX', null)
    this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)

    await this.$store.dispatch('getIDList', 'wu')
    await this.$store.dispatch('debitCard/getEONSourceOfFund')
    await this.$store.dispatch('debitCard/getEONEmployment')
    await this.$store.dispatch('debitCard/getEONNatureWork')
    await this.$store.dispatch('debitCard/getEONIdList')
    await this.$store.dispatch('phvc/getServiceFeeList', {
      user_id: this.currUser.user_id,
      module_id: 19,
      access_permission: 'view_access'
    })

    if (this.$route.query.lname) {
      this.firstName = this.$route.query.fname ? this.$route.query.fname : ''
      this.lastName = this.$route.query.lname ? this.$route.query.lname : ''
      this.isMobileUser = this.$route.query.isMobileUser ? this.$route.query.lname : false
      this.doSearch()
    }
  }
}
</script>
<style scoped>
  .ph-textbox-quickpay {
    margin-bottom: 16px !important;
  }
</style>

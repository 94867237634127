<template>
  <div class="remco-status-container">
    <v-expansion-panels accordion >
      <v-expansion-panel class="remco-status-header">
        <v-expansion-panel-header>
          <div class="ph-nonex-card-title">
            <h1 color="primary">Partners Status</h1>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="remco-status-content">
          <v-row>
            <v-col cols="12" md="10" v-for="item in remcoList" :key="item.length">
              <v-chip append-icon="mdi-circle">
                <span class="checkbox-label">{{ item.non_ex_name }}</span>
                  <v-icon :style="{ color: (item.status === 1) ? '#46B746' : '#E79BA2' }">mdi-circle</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReceiveMoneyStatus',
  data () {
    return {
      remcoList: [],
      onlineCount: 0,
      offlineCount: 0
    }
  },
  computed: {
    ...mapGetters({
      remcos: 'nonex/remcos'
    })
  },
  watch: {
    remcos: {
      handler (getRemco) {
        console.log(getRemco)
        this.remcoList = getRemco.data.filter(item => { return item.can_receive_money === true })
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.$store.dispatch('nonex/getRemcos')
    const getOnlineList = this.remcoList.filter((item) => { return item.status === 1 })
    const getOfflineList = this.remcoList.filter((item) => { return item.status === 0 })

    console.log(this.remcoList)
    this.onlineCount = getOnlineList.length
    this.offlineCount = getOfflineList.length
  }
}
</script>
<style scoped>
  .remco-status-container {
    position: fixed; /* Makes it float */
    right: 20px; /* Pushes it to the right */
    top: 75px;
    z-index: 4; /* Ensures it appears above other elements */
    background-color: #fff; /* Optional: Add a background color */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
    border-radius: 8px; /* Optional: Rounds the corners */
    max-width: 300px; /* Restricts the maximum width */
    overflow: hidden; /* Prevents overflow outside the panel */
  }

  .remco-status-header {
    position: sticky; /* Keeps it at the top of the panel */
    top: 0; /* Ensures it sticks to the top of its container */
    background-color: white; /* Matches the background color */
    z-index: 2; /* Ensures it stays above the scrollable content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for separation */
  }

  .remco-status-content {
    max-height: 550px; /* Sets a maximum height for the scrollable content */
    overflow-y: auto; /* Enables vertical scrolling */
  }
  .ph-nonex-card-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 10px;
    color: #1A2791;
  }

  .ph-nonex-receive-search-title {
    display: flex;
  }

  .ph-textbox-title {
    margin: auto;
    margin-left: 0;
    font-family: 'Proxima Nova Bold';
    font-size: 16px;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 160px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
  }

  .ph-inquire-result-content {
    line-height: 1.5;
  }

  .ph-inquire-result-content p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .ph-nonex-receive-action-buttons {
    display: flex;
  }

  .ph-inquire-result-receiver-name,
  .ph-inquire-result-header {
    font-family: 'Proxima Nova Bold';
  }

  .ph-inquire-error-items {
    padding: 0;
  }

  .ph-inquire-success-count,
  .ph-inquire-error-count {
    flex: unset !important;
    margin-left: 0;
    margin-right: 10px;
  }

  .ph-nonex-status-remco-sheet {
    padding: 10px;
  }

  .ph-nonex-status-remco-name {
    font-family: "Proxima Nova Bold";
    font-size: 20px;
  }

  .ph-nonex-status-text {
    font-size: 14px;
  }

  .ph-expansion-panel-header {
    display: flex;
    flex-direction: row;
  }

  .ph-nonex-card-title-container {
    display: flex;
    flex-direction: column;
  }

  .ph-nonex-card-chip-container {
    margin-top: 10px;
  }

  @media only screen and (min-width: 600px) {
    .ph-nonex-card-title-container {
      flex-direction: row;
    }

    .ph-nonex-card-chip-container {
      margin-left: auto;
      margin-top: 0;
    }

    .ph-inquire-success-count,
    .ph-inquire-error-count {
      margin-left: 10px;
      margin-right: 0;
    }
  }
</style>

export default {
  SET_TRANSACTIONS (state, payload) {
    state.transactions = payload
  },
  RESET_INQUIRE_RESULT (state) { // Reset the value of inquireResult state
    state.inquireResult = {
      data: [],
      errors: []
    }
  },
  SET_SUCCESS_INQUIRE_RESULT (state, payload) { // will update the data of inquireResult
    state.inquireResult.data = [...state.inquireResult.data, payload]
  },
  SET_ERROR_INQUIRE_RESULT (state, payload) { // will errors the data of inquireResult
    state.inquireResult.errors = [...state.inquireResult.errors, payload]
  },
  SET_INQUIRE_RESULT (state, payload) {
    state.inquireResult = payload
  },
  SET_REMCO_LIST_RESULT (state, payload) {
    state.remcos = payload
  },
  SET_CURRENT_TRANSACTION (state, payload) {
    state.currentTrx = payload
  },
  SET_SENDOUT_CONFIRM_DIALOG (state, payload) {
    state.sendoutConfirmDialog = payload
  },
  SET_SENDOUT_OTP_DIALOG (state, payload) {
    state.sendoutOTPDialog = payload
  },
  SET_SENDOUT_SERVICE_FEES (state, payload) {
    state.sendoutServiceFees = payload
  },
  SET_REFERENCE_NUMBER (state, payload) {
    state.payoutReferenceNo = payload
  },
  SET_IS_GCASH_TRX (state, payload) {
    state.isGCashTrx = payload
  },
  SET_CEBUANA_GENDER_LIST (state, payload) {
    state.cebuanaGenderList = payload // Change the state of cebuanaGenderList
  },
  SET_CEBUANA_PURPOSE_LIST (state, payload) {
    state.cebuanaPurposeList = payload // Change the state of cebuanaPurposeList
  },
  SET_CEBUANA_RELATION_LIST (state, payload) {
    state.cebuanaRelationList = payload // Change the state of cebuanaRelationList
  },
  SET_CEBUANA_OCCUPATION_LIST (state, payload) {
    state.cebuanaOccupationList = payload // Change the state of cebuanaOccupationList
  },
  SET_NONEX_REMCO_LIST (state, payload) {
    state.remcoList = payload
  }

}

<template>
  <v-dialog
    v-model="showResult"
    max-width="1000"
  >
    <v-card>
      <v-card-title class="ph-wu-search-result-header text-h5 text-primary">
        <h5 class="ph-wu-search-text">PERA Hub Customer Results ({{searchData.results ? searchData.results.length : 0}})</h5>
      </v-card-title>

      <v-card-text v-if="searchData.results ? (searchData.results.length > 0 ? true : false) : false">
        <v-data-table
          class="ph-clickable-table"
          :headers="listViewHeaders"
          :items="searchData.results"
          @click:row="doSelect"
          no-data-text="No customers found"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-avatar class="mr-1" size="25px" :style="avatarColor(item.full_name)">
              <span class="white--text text-body-2">{{ avatarInitial(item.full_name) }}</span>
            </v-avatar>
            {{ toProperCase(item.first_name) }} {{ toProperCase(item.last_name) }}
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            {{ item.gender ? toProperCase(item.gender) : '-' }}
          </template>
          <template v-slot:[`item.wu_card_no`]="{ item }">
            {{ item.wu_card_no || '-' }}
          </template>
          <template v-slot:[`item.LastTransaction`]="{ item }">
            {{ item.LastTransaction || '-' }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true">
        <h3>Looks like the customer you are looking for doesn't exist.<br> You need to create a new customer record for this transaction or retry searching for that customer.</h3>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showResult = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          theme="dark"
          v-if="searchData.results ? (searchData.results.length < 1 ? true : false) : true"
          :to="{ path: '/customers/new', query: { persistData: true, redirectURL: this.$route.path } }"
          @click="showResult = false"
        >
          Create New Customer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  customerRiskAssessment,
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'ReceiveMoneyWUSearchResult',
  data () {
    return {
      listViewHeaders: [
        {
          text: 'Customer Number',
          sortable: true,
          value: 'customer_number'
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'Birth Date',
          sortable: true,
          value: 'birth_date'
        },
        {
          text: 'WU No.',
          sortable: true,
          value: 'wu_card_no'
        },
        {
          text: 'ID Indicator',
          sortable: true,
          value: '-'
        },
        {
          text: 'Last Transaction',
          sortable: true,
          value: 'LastTransaction'
        }
      ],
      showResult: false,
      isNotCustomerPage: true,
      custSearchResult: ''
    }
  },
  computed: {
    ...mapGetters({
      searchData: 'customers/searchData',
      currUser: 'auth/currUser'
    })
  },
  watch: {
    searchData: {
      handler (getSearch) {
        this.custSearchResult = getSearch.results

        if (this.custSearchResult !== null) {
          this.showResult = true
        } else {
          this.showResult = false
        }
      },
      deep: true
    }
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    async doSelect (customerDetails) {
      this.showResult = false
      if (this.currUser.cisrat === 1) {
        const { code, data } = await customerRiskAssessment(customerDetails)

        if (code === 'FOUND_HIGH_RISK_GO' || code === 'FOUND_HIGH_RISK_STOP') {
          this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
            isShow: true,
            type: code,
            details: data,
            customer: customerDetails
          })
          return true
        }

        if (code === 'ERR_INTERNAL_CATCH') {
          renderToast('error', 'USP Error', 'An error encountered while checking the customer')
          return true
        }

        if (code === 'ERROR_CODE') {
          renderToast('error', `[${data.code}] Error`, 'An error encountered while checking the customer')
          return true
        }

        if (code === 'ERR_NO_API_RESPONSE') {
          renderToast('error', 'USP Error', 'Invalid API response')
          return true
        }

        if (code === 'FOUND_NEGATIVE_LIST') {
          if (customerDetails.is_negative_list !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }

        if (code === 'FOUND_ADVERSE') {
          if (customerDetails.is_fraud !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }

        if (code === 'FOUND_PEP') {
          if (customerDetails.is_pep !== 1) {
            this.$store.commit('compliance/UPDATE_PNA_ALERT_DIALOG', {
              isShow: true,
              type: code,
              details: data,
              customer: customerDetails
            })
            return true
          }
        }
      }

      this.proceedToTransaction(customerDetails)
    },
    async proceedToTransaction (customerDetails) {
      // Checking of available offer for the customer
      const { code, result } = await this.$store.dispatch('prompt/checkAvailableOffer', {
        customer_number: customerDetails.customer_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id
      })

      if (code === 200 && result) {
        this.$store.commit('prompt/SET_AVAILABLE_OFFER', result)
        this.$store.commit('prompt/SET_SHOW_OFFER_DIALOG', true)
      }
      // Auto populate masked details
      const custDetails = await this.$store.dispatch('customers/getCustSpecificDetails', {
        customer_number: customerDetails.customer_number,
        customer_id: customerDetails.customer_id.toString()
      })

      this.$store.commit('customers/SET_SELECTED_CUSTOMER', custDetails.result[0])
      // this.$store.commit('customers/SET_SELECTED_CUSTOMER', customerDetails)
      this.renderToast('info', 'Customer Selected!', `${customerDetails.full_name} has been successfully selected.`)
      this.$store.commit('wu/SET_SHOW_PAYOUT_SEARCH_FORM', false)
      this.$store.commit('wu/SET_SHOW_PAYOUT_RESULT_FORM', true)
    }
  }
}
</script>
<style>
  .v-card__title.text-h5.ph-wu-search-result-header .ph-wu-search-text {
    font-family: 'Baloo Extra Bold', sans-serif !important;
    font-size: 24px;
    color: #1A2791;
  }
</style>

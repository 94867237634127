<template>
  <div class="payment-receipt-container">
    <div class="btn-container">
        <div id="btn-receipt" class="div-button">
            <v-btn @click="finalPrint()" id="printButton" class="ph-primary-button ph-stepper-button ph-primary-execute-btn" outlined text dark>{{isGenerating ? 'Generating receipt, please wait...' : 'Ready to Print'}}</v-btn>
        </div>
    </div>
    <div id="perabills_receipt">
      <div id="branch-copy">
        <pre id="pre-branch-copy" style="padding-left: 25px; font-size: 16px; letter-spacing: 2px; font-family: arial;">
          {{bayadReceiptData.billerName}}<br>
          {{bayadReceiptData.transactionRefNo}}<br>
          {{bayadReceiptData.transactionTimestamp}}<br>
          {{bayadReceiptData.referenceNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{bayadReceiptData.paymentMethod}}: Php {{bayadReceiptData.amount}}<br>
          THANK YOU FOR PAYING AT PeraHUB.<br>
          AUTHORIZED PARTNER OF BAYAD CENTER.
        </pre>
      </div>

      <div id="customer-copy">
        <pre id="pre-customer-copy" style="padding-left: 25px; font-size: 16px; letter-spacing: 2px; font-family: arial;">
          {{bayadReceiptData.billerName}}<br>
          {{bayadReceiptData.transactionRefNo}}<br>
          {{bayadReceiptData.transactionTimestamp}}<br>
          {{bayadReceiptData.referenceNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{bayadReceiptData.paymentMethod}}: Php {{bayadReceiptData.amount}}<br>
          THANK YOU FOR PAYING AT PeraHUB.<br>
          AUTHORIZED PARTNER OF BAYAD CENTER.
        </pre>
      </div>
    </div>
    <div id="perabills_receipt_render_img"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import domtoimage from 'dom-to-image'

export default {
  name: 'PeraBillsReceipt',
  data () {
    return {
      billerName: '',
      transactionRefNo: '',
      transactionTimestamp: '',
      referenceNo: '',
      paymentMethod: '',
      amount: '',
      trxId: '',
      tpaId: '',
      isGenerating: true
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      bayadReceiptData: 'bills/bayadReceiptData'
    })
  },
  watch: {
    bayadReceiptData: {
      handler (item) {
        console.log(item)
      },
      deep: true
    }
  },
  mounted () {
    const that = this
    if (!this.bayadReceiptData) {
      window.location.href = '/pera-bills/payments'
    } else {
      const printContents = document.getElementById('perabills_receipt')
      const that = this
      this.isGenerating = true

      domtoimage.toPng(printContents).then(function (dataUrl) {
        const img = new Image()
        img.src = dataUrl
        document.getElementById('perabills_receipt_render_img').appendChild(img)
        that.isGenerating = false
      }).catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
    }

    document.getElementById('printButton').addEventListener('click', () => {
      location.reload()
      setTimeout(() => {
        that.finalPrint()
      }, 100)
    })
  },
  methods: {
    finalPrint () {
      const printContents = document.getElementById('perabills_receipt_render_img').innerHTML
      const originalContents = document.body.innerHTML

      document.body.innerHTML = printContents

      window.print()

      document.body.innerHTML = originalContents
    }
  }
}
</script>
<style>
  .payment-receipt-container{
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    font-family: arial;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .div-button button {
    background-color: rgb(14, 130, 238);
    color: white;
    border-radius: 5px;
    border:none;
    padding: .75rem 30px;
    margin-right: 10px;
  }

  #perabills_receipt{
    padding-top: 10px;
    padding-left: 20px;
    font-family: arial;
    display: block;
    color: #000;
    background: transparent;
  }

  #branch-copy{
    margin-bottom: 145px;
  }

  #perabills_receipt pre {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 0.7;
    white-space: pre-line;
  }

  .ph-primary-button {
    background: #FFF;
    color: #1A2791 !important;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    margin: 10px 0;
    margin-right: 10px;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff !important;
    /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
    font-size: 14px;
  }

  .ph-primary-button.ph-primary-execute-btn.v-btn--disabled {
    color: #fff !important
  }

  .ph-primary-button.ph-primary-execute-btn:hover {
    background: #fff !important;
    color: #1A2791 !important;
  }

  #perabills_receipt_render_img {
    padding-top: 30px;
    display: none;
  }

  @media print {
    #perabills_receipt{
      position: relative;
      font-family: arial;
      padding-left: 25px;
      display: none;
    }

    #pre-branch-copy {
      line-height: 0.6;
      white-space: pre-line;
    }

    #pre-customer-copy {
      line-height: 0.6;
      white-space: pre-line;
    }

    #perabills_receipt_render_img {
      display: block;
    }

    img {
      position: absolute;
      top: 1100px;
    }

    /* #branch-copy{
      position:absolute;
      top:735px;
    }

    #customer-copy{
      position: absolute;
      bottom:23px;
    } */
  }
</style>

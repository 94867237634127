<template>
  <table class="report-table exportable" id="reportTable" :style="params.rpt_value === 'ctpl_report' ? 'width: 250%' : 'width: 100%'">
    <tr class="report-table-head" style="background-color: #1A2791; color: #fff; border: 1px solid black; print-color-adjust: exact;">
      <th v-for="({ label }, i) in columns" :key="i" style="padding-left: 4px !important; padding-right: 4px !important;">
        {{ params.rpt_value === 'sales_report_journal_EReceipt' ? (label === 'Particulars' ? 'Description' : label) : label }}
      </th>
    </tr>
    <tbody v-if="reportData && reportData.length > 0">
      <tr class="report-table-data text-center" v-for="(data, x) in reportData" :key="x">
        <td v-for="({ type, value }, y) in columns" :key="y" style="padding-left: 4px !important; padding-right: 4px !important;">
          <!-- For Non Pera Loan -->
          <span v-if="type !== 'text'">{{ formatData(data[value], type) }}</span>
          <span v-if="type === 'text' && params.rpt_value !== 'sales_peraloan' && params.rpt_value !== 'ibt_report'">{{ data[value] }}</span>
          <!-- For Pera Loan Handling -->
          <span v-if="type === 'text' && params.rpt_value === 'sales_peraloan' && value === 'provider'">{{ data.loan_type === 'P' ? 'Pension' : (data.loan_type === 'D' ? 'Teachers' : 'Betterway') }}</span>
          <span v-if="type === 'text' && params.rpt_value === 'sales_peraloan' && value !== 'provider'">{{ data[value] }}</span>
          <!-- For IBT Report -->
          <span v-if="type === 'text' && params.rpt_value === 'ibt_report' && value === 'is_cash'">{{ (data.is_cash === '1') ? 'Deliver/Pick-up by cash' : 'Deposit to Account'  }}</span>
          <span v-if="type === 'text' && params.rpt_value === 'ibt_report' && value === 'status'">{{ (data.status === '2') ? 'Approved' : (data.status === '3' ? 'Disapproved' : 'Pending') }}</span>
          <span v-if="type === 'text' && params.rpt_value === 'ibt_report' && value !== 'status' && value !== 'is_cash'">{{ data[value] }}</span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr class="report-table-data text-center">
        <td :colspan="columns.length">
          <small>{{ (isLoading ? 'LOADING DATA, PLEASE WAIT...' : 'NO RECORDS FOUND') }}</small>
        </td>
      </tr>
    </tbody>
    <tr
      v-if="columns && footer && columns.length > footer.span && isLoading === false && params.rpt_value !== 'sales_wupay' && params.rpt_value !== 'sales_peraload' && params.rpt_value !== 'sales_perapalit'"
      class="report-table-data text-center font-weight-bold"
    >
      <td
        class="py-2"
        v-for="({ type, value }, x) in columns"
        :key="x"
        :style="((x >= footer.span) ? 'border-top: 2px solid #000' : '')"
      >
        <span v-if="x >= footer.span">{{ (Object.keys(footer.data).includes(value) && footer.data[value] !== '') ? formatData(footer.data[value], 'accounting') : (type === 'float' || type === 'accounting') ? '0.00' : '' }}</span>
        <span v-else></span>
      </td>
    </tr>
    <!-- Wu Transaction Logs -->
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'trxlogs'">
      <td colspan="7">Total Amount for SO (PHP): </td>
      <td style="text-align: center;">{{calculateAmount(reportData, 'SO', 'PHP', 'principal_amount')}}</td>
      <td></td>
      <td style="text-align: center;">{{calculateAmount(reportData, 'SO', 'PHP', 'net_amount')}}</td>
      <td colspan="7"></td>
    </tr>
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'trxlogs'">
      <td colspan="7">Total Amount for PO (PHP): </td>
      <td style="text-align: center;">{{calculateAmount(reportData, 'PO', 'PHP', 'principal_amount')}}</td>
      <td></td>
      <td style="text-align: center;">{{calculateAmount(reportData, 'PO', 'PHP', 'net_amount')}}</td>
      <td colspan="7"></td>
    </tr>
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'trxlogs'">
      <td colspan="7">Total Amount for PO (USD): </td>
      <td style="text-align: center;">{{calculateAmount(reportData, 'PO', 'USD', 'principal_amount')}}</td>
      <td></td>
      <td style="text-align: center;">{{calculateAmount(reportData, 'PO', 'USD', 'net_amount')}}</td>
      <td colspan="7"></td>
    </tr>
    <!-- CTPL -->
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'ctpl_report' || params.rpt_value === 'ctpl_report_cancelled'">
      <td style="text-align: center;" colspan="1"><b>Total Amount(s):</b></td>
      <td style="text-align: center;" v-if="params.rpt_value === 'ctpl_report'" colspan="38"></td>
      <td style="text-align: center;" v-if="params.rpt_value === 'ctpl_report_cancelled'" colspan="6"></td>
      <td style="text-align: center;" v-if="params.rpt_value === 'ctpl_report_cancelled'" class="digit">{{calculateTotalAmount(reportData,'total_premium')}}</td>
      <td style="text-align: center;" v-if="params.rpt_value === 'ctpl_report_cancelled'" class="digit">{{calculateTotalAmount(reportData,'authentication_fee')}}</td>
      <td style="text-align: center;" v-if="params.rpt_value === 'ctpl_report_cancelled'" class="digit">{{calculateTotalAmount(reportData,'doc_stamps')}}</td>
      <td style="text-align: center;" v-if="params.rpt_value === 'ctpl_report_cancelled'" class="digit">{{calculateTotalAmount(reportData,'cancellation_fee')}}</td>
      <td style="text-align: center;">{{calculateTotalAmount(reportData,'amount')}}</td>
    </tr>
    <!-- Pay @ WU -->
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'sales_wupay'">
      <td style="text-align: left;" colspan="8">Total: </td>
      <td></td>
      <td style="text-align: center">{{calculateTotalAmount(reportData,'pd_amount')}}</td>
      <td colspan="2"></td>
    </tr>
    <!-- PHV -->
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'sales_peracard'">
      <td style="text-align: left;" colspan="4">Total: </td>
      <td style="text-align: center">{{calculateTotalAmount(reportData,'amount')}}</td>
      <td colspan="2"></td>
    </tr>
    <!-- Pera Palit -->
    <tr class="report-table-data report-data-footer font-weight-bold" v-if="params.rpt_value === 'sales_perapalit'">
      <td colspan="8">Total Amount(s): </td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'principal_amount')}}</td>
      <td></td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'ds_tax')}}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'net_amount')}}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'usd_released')}}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'buy_back')}}</td>
      <td></td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'php_released')}}</td>
      <td></td>
    </tr>
    <!-- Pera Load -->
    <tr class="report-table-data report-data-footer font-weight-bold"  v-if="params.rpt_value === 'sales_peraload'">
      <td colspan="7">Total Amount(s): </td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'amount') }}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'wallet_cost') }}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'comission') }}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'added_comission') }}</td>
      <td style="text-align: center;" class="digit">{{calculateTotalAmount(reportData,'bal') }}</td>
      <td></td>
    </tr>
    <tr class="report-table-data report-data-footer font-weight-bold"  v-if="params.rpt_value === 'sales_peraloan'">
      <td colspan="16">Total Amounts: </td>
      <td style="text-align: center;">{{calculateTotalAmount(reportData, 'gloanamt')}}</td>
      <td></td>
      <td style="text-align: center;">{{calculateTotalAmount(reportData, 'gcashout')}}</td>
      <td style="text-align: center;">{{calculateTotalAmount(reportData, 'gnetpro')}}</td>
      <td></td>
    </tr>
    <tr class="report-table-data report-data-footer font-weight-bold"  v-if="params.rpt_value === 'sales_report_journal_EReceipt'">
      <td colspan="1">Total Amounts: </td>
      <td style="text-align: center;">{{calculateTotalAmount(reportData, 'amount')}}</td>
      <td></td>
    </tr>
  </table>
</template>

<script>
import numeral from 'numeral'
import { getAge } from '@/utils'

export default {
  props: [
    'isLoading',
    'reportData',
    'columns',
    'footer',
    'params'
  ],
  methods: {
    formatData (data, type) {
      if (type === 'yearFromNow') return getAge(data)
      if (type === 'float') return numeral(data).format('0,0.00')
      if (type === 'accounting') return this.getAccountingFormat(data)
      if (type === 'transactionCount') return ((data === 0) ? 0 : 1)
      if (type === 'insuranceProvider') return this.getProviderName(data)
      if (type === 'accountNumber') return this.formatAccountNumber(data)

      return data
    },
    formatAccountNumber (data) {
      const str = String(data)

      return str.slice(-4).padStart(str.length, 'X')
    },
    getAccountingFormat (amount) {
      const number = Number(amount)

      if (number < 0) {
        return `(${numeral(Math.abs(amount)).format('0,0.00')})`
      }

      return numeral(amount).format('0,0.00')
    },
    getProviderName (category) {
      if (category === 'COC') return 'Accident Protect'
      if (category === 'COC3') return 'Dengue Protect Plus'
      if (category === 'COC6') return 'Covid Protect Plus Microinsurance'
      if (category === 'COC8') return 'Dengue Protect v2'
      if (category === 'COC9') return 'Microsafe'
      if (category === 'COC10') return 'Pera Payapa v2'
      return ''
    },
    calculateAmount (data, trxType, currency, amountType) {
      let amount = 0
      if (data) {
        data.forEach(item => {
          if (item.wu_country_currency_paid === currency && item.wu_tran_type === trxType) {
            amount += item[amountType]
          }
        })
      }

      return numeral(amount).format('0,0.00')
    },
    calculateTotalAmount (data, type) {
      let amount = 0
      if (data) {
        data.forEach(item => {
          if (type === 'wallet_cost') {
            amount += parseFloat(item.amount) - parseFloat(item.comission)
          } else {
            amount += parseFloat(item[type])
          }
        })
      }

      return numeral(amount).format('0,0.00')
    }
  }
}
</script>
<style>
.report-data-footer td {
  border-top: 2px solid #000;
}
</style>

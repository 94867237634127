import api from '@/api'
import apigee from '@/api/apigee'

export default {
  searchACR (payload) {
    return apigee.post('/digicur/v1/api/customer-acr-search', payload)
  },
  searchCount (payload) {
    return apigee.post('/digicur/v1/api/get-upload-count', payload)
  },
  downloadFile (url) {
    return api.post('/v1/maintenance/api/digicur/download', url)
  }
}

<template>
  <v-col :cols="0" :md="4">
    <v-card id="summaryOverview" class="d-none d-md-block px-6 py-6 grey--text text--darken-2" flat>
      <h3 class="text-center mb-md-8">Transaction Summary</h3>
      <v-row no-gutters>
        <v-col v-for="data in summaryData" :key="data.text" :cols="12">
          <div class="d-flex justify-space-between">
            <div>{{ data.text }}</div>
            <div>
              <strong>{{ data.value || '—' }}</strong>
            </div>
          </div>
          <v-divider class="my-md-2" style="border-style: dashed" />
        </v-col>
      </v-row>
      <v-btn
        @click="checkEmail"
        class="rounded-lg mt-md-4 font-weight-black"
        :disabled="!completedForm"
        color="primary"
        block
        x-large
      >
        Confirm
      </v-btn>
    </v-card>
    <v-dialog
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      v-model="summaryDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ph-wu-receive-summary-dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="ph-wu-receive-summary-minified">
          <div class="ph-wu-receive-summary-content">
            <div class="ph-wu-receive-summary-minified-info">
              <p><b>Summary Overview</b></p>
              <p>Total Amount: &nbsp;<b>{{ toMoney(transactionData.amount) }}</b></p>
            </div>
            <div class="ph-wu-receive-summary-minified-action">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title class="ph-dialog-title">Summary Overview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="summaryDialog = false"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row no-gutters>
          <v-col v-for="data in summaryData" :key="data.text" :cols="12">
            <div class="my-2 px-2 my-md-0 mx-md-0 d-flex justify-space-between">
              <div>{{ data.text }}</div>
              <div>
                <strong>{{ data.value || '—' }}</strong>
              </div>
            </div>
            <v-divider class="my-md-2" style="border-style: dashed" />
          </v-col>
        </v-row>
        <div class="px-1">
          <v-btn
            @click="checkEmail"
            class="rounded-lg mt-6 font-weight-black"
            :disabled="!completedForm"
            color="primary"
            block
            x-large
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withoutEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updateDetails(selectedCustomer)"
            color="primary"
            text
          >
            Update Customer Record
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withEmailPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Receipt will send to the client/s email address. Would you like to proceed this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="printReceiptInstead"
            color="primary"
            text
          >
            I want a printed Receipt
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="manualConfirmPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Confirmation
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          Would you like to proceed with this transaction?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="manualConfirmPrompt = false"
            color="primary"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="proceedTransaction"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <wacom-transaction
      v-if="currUser.has_wacom"
      @submit-sig="submitSig" type="transactional"
      :referenceSignatures="customerSignaturesReference"
      :trxData="{sender_name: selectedCustomer.full_name, receiver_name: 'N/A', total_amount: transactionData.amount}"
    />
    <wacom-data-consent-first />
    <wacom-data-consent-second />
    <wacom-data-consent-third />
    <wacom-data-consent-fourth />
    <wacom-data-consent-fifth />
    <wacom-data-consent-sixth />
    <wacom-data-consent-seventh
      :remco="'Pera Protect'"
    />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { differenceInYears, format } from 'date-fns'
import { trim, toMoney, renderToast, getForeignRefNo } from '@/utils'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'

export default {
  mixins: wacomMixin,
  components: {
    wacomTransaction,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  computed: {
    ...mapGetters({
      transactionData: 'insurance/transactionData',
      insuranceList: 'insurance/insuranceList',
      ctplList: 'insurance/ctplList',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser',
      receipt: 'receipt',
      consentValues: 'wacom/consentValues',
      seriesDetail: 'manualReceipt/seriesDetail'
    }),
    completedForm () {
      const data = this.transactionData
      const insuredMiddleName = (data.insuredNoMiddleNameTag ? true : data.insuredMiddleName)
      const beneficiaryMiddleName = (data.beneficiaryNoMiddleNameTag ? true : data.beneficiaryMiddleName)
      const beneficiary2MiddleName = (data.beneficiary2NoMiddleNameTag ? true : data.beneficiary2MiddleName)
      const beneficiary3MiddleName = (data.beneficiary3NoMiddleNameTag ? true : data.beneficiary3MiddleName)
      const beneficiary4MiddleName = (data.beneficiary4NoMiddleNameTag ? true : data.beneficiary4MiddleName)

      if (data.insurance && data.insurance.category === 'COC7') {
        return data.mvType && data.premiumType && data.amount && data.insuredFirstName && insuredMiddleName &&
          data.insuredLastName && data.inceptionDate && data.expiryDate && data.vehicleFileNumber && data.vehicleChassisNumber &&
          data.vehicleEngineNumber && data.vehicleYear && data.vehicleMake && data.vehicleColor && data.vehicleBody &&
          (data.vehicleIsCorporate ? data.vehicleCompanyName : true)
      } else {
        const fieldStatus = data.insurance && data.policy && data.inceptionDate && data.expiryDate &&
          data.amount && data.insuredCustomerID && data.insuredFirstName && insuredMiddleName && data.insuredLastName &&
          data.insuredBirthDate && data.beneficiaryFirstName && beneficiaryMiddleName && data.beneficiaryLastName &&
          data.beneficiaryRelationship

        if (data.insurance.category === 'COC' || data.insurance.category === 'COC6') {
          return fieldStatus && data.insuredOccupation && data.insuredPensionCode
        } else if (data.insurance.category === 'COC3' || data.insurance.category === 'COC8') {
          return fieldStatus && data.insuredOccupation
        } else if (data.insurance.category === 'COC4') {
          return fieldStatus && data.insuredMobileNumber
        } else if (data.insurance.category === 'COC9' || data.insurance.category === 'COC10') {
          if (data.beneficiary4 === true && data.beneficiary3 === true && data.beneficiary2 === true) {
            return fieldStatus && data.gender && data.province && data.city && data.insuredOccupation && data.civilStatus && data.referrer && data.beneficiary4FirstName && data.beneficiary4LastName && data.beneficiary4Relationship && data.isFormValid && beneficiary4MiddleName && beneficiary3MiddleName && beneficiary2MiddleName
          } else if (data.beneficiary3 === true && data.beneficiary2 === true) {
            return fieldStatus && data.gender && data.province && data.city && data.insuredOccupation && data.civilStatus && data.referrer && data.beneficiary3FirstName && data.beneficiary3LastName && data.beneficiary3Relationship && data.isFormValid && beneficiary3MiddleName && beneficiary2MiddleName
          } else if (data.beneficiary2 === true) {
            return fieldStatus && data.gender && data.province && data.city && data.insuredOccupation && data.civilStatus && data.referrer && data.beneficiary2FirstName && data.beneficiary2LastName && data.beneficiary2Relationship && data.isFormValid && beneficiary2MiddleName
          } else {
            return fieldStatus && data.gender && data.province && data.city && data.insuredOccupation && data.civilStatus && data.referrer && data.isFormValid
          }
        } else {
          return fieldStatus
        }
      }
    },
    summaryData () {
      const fullData = this.transactionData
      let summary = {
        insurance: { text: 'Insurance', value: null },
        policy: { text: 'Policy Name', value: null },
        amount: { text: 'Amount to Collect', value: null },
        insured: { text: 'Insured', value: null },
        beneficiary: { text: 'Beneficiary', value: null },
        relationship: { text: 'Relationship to Insured', value: null }
      }

      if (fullData.insurance && fullData.insurance.category === 'COC7') {
        summary = {
          insurance: { text: 'Insurance', value: null },
          mvType: { text: 'MV Type', value: null },
          premiumType: { text: 'Premium Type', value: null },
          amount: { text: 'Amount to Collect', value: null },
          insured: { text: 'Customer Name', value: null }
        }
      }

      if (fullData.insurance.category === 'COC9' || fullData.insurance.category === 'COC10') {
        if (fullData.beneficiary4 === true) {
          summary = {
            insurance: { text: 'Insurance', value: null },
            policy: { text: 'Policy Name', value: null },
            amount: { text: 'Amount to Collect', value: null },
            insured: { text: 'Insured', value: null },
            beneficiary: { text: 'Beneficiary', value: null },
            beneficiary2: { text: '2nd Beneficiary', value: null },
            beneficiary3: { text: '3rd Beneficiary', value: null },
            beneficiary4: { text: '4th Beneficiary', value: null }
          }
        } else if (fullData.beneficiary3 === true) {
          summary = {
            insurance: { text: 'Insurance', value: null },
            policy: { text: 'Policy Name', value: null },
            amount: { text: 'Amount to Collect', value: null },
            insured: { text: 'Insured', value: null },
            beneficiary: { text: 'Beneficiary', value: null },
            beneficiary2: { text: '2nd Beneficiary', value: null },
            beneficiary3: { text: '3rd Beneficiary', value: null }
          }
        } else if (fullData.beneficiary2 === true) {
          summary = {
            insurance: { text: 'Insurance', value: null },
            policy: { text: 'Policy Name', value: null },
            amount: { text: 'Amount to Collect', value: null },
            insured: { text: 'Insured', value: null },
            beneficiary: { text: 'Beneficiary', value: null },
            beneficiary2: { text: '2nd Beneficiary', value: null }
          }
        } else {
          summary = {
            insurance: { text: 'Insurance', value: null },
            policy: { text: 'Policy Name', value: null },
            amount: { text: 'Amount to Collect', value: null },
            insured: { text: 'Insured', value: null },
            beneficiary: { text: 'Beneficiary', value: null }
          }
        }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'insurance':
            if (fullData.insurance) {
              summary[field].value = fullData.insurance.name
            }
            break
          case 'policy':
            if (fullData.policy) {
              summary[field].value = fullData.policy.policy_name
            }
            break
          case 'mvType':
            if (fullData.mvType) {
              const selected = this.ctplList.filter(data => data.mv_type === fullData.mvType)

              if (selected.length === 1) summary[field].value = selected[0].mv_type_name
            }
            break
          case 'premiumType':
            if (fullData.premiumType) {
              const selected = this.ctplList.filter(data => (data.premium_type === fullData.premiumType && data.mv_type === fullData.mvType))

              if (selected.length === 1) summary[field].value = selected[0].premium_type_name
            }
            break
          case 'amount':
            if (fullData.amount) {
              summary[field].value = toMoney(fullData.amount)
            }
            break
          case 'insured':
            var insuredFullName = [
              fullData.insuredFirstName,
              fullData.insuredMiddleName,
              fullData.insuredLastName
            ]

            summary[field].value = trim(insuredFullName.join(' '))
            break
          case 'beneficiary':
            var beneficiaryFullName = [
              fullData.beneficiaryFirstName,
              fullData.beneficiaryMiddleName,
              fullData.beneficiaryLastName
            ]

            summary[field].value = trim(beneficiaryFullName.join(' '))
            break
          case 'beneficiary2':
            var beneficiary2FullName = [
              fullData.beneficiary2FirstName,
              fullData.beneficiary2MiddleName,
              fullData.beneficiary2LastName
            ]

            summary[field].value = trim(beneficiary2FullName.join(' '))
            break
          case 'beneficiary3':
            var beneficiary3FullName = [
              fullData.beneficiary3FirstName,
              fullData.beneficiary3MiddleName,
              fullData.beneficiary3LastName
            ]

            summary[field].value = trim(beneficiary3FullName.join(' '))
            break
          case 'beneficiary4':
            var beneficiary4FullName = [
              fullData.beneficiary4FirstName,
              fullData.beneficiary4MiddleName,
              fullData.beneficiary4LastName
            ]

            summary[field].value = trim(beneficiary4FullName.join(' '))
            break
          case 'relationship':
            summary[field].value = fullData.beneficiaryRelationship
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      return Object.values(summary)
    }
  },
  data () {
    return {
      summaryDialog: false,
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      customerSignaturesReference: [],
      hasCustomerDataConsent: false,
      customerSignature: '',
      foreignRefNo: '',
      manualConfirmPrompt: false
    }
  },
  methods: {
    getForeignRefNo,
    submitSig (emitted) {
      this.customerSignature = emitted
      this.generateConsent()
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.doSave()
      this.$store.commit('wacom/SET_WACOM_DIALOG', false)
    },
    proceedTransaction () {
      if (this.currUser.has_wacom && !this.hasCustomerDataConsent && this.transactionData.insurance.category === 'COC') {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      } else if (this.currUser.has_wacom && this.hasCustomerDataConsent && this.transactionData.insurance.category === 'COC') {
        this.$store.commit('wacom/SET_WACOM_DIALOG', true)
      } else {
        this.withoutEmailPrompt = false
        this.withEmailPrompt = false
        this.doSave()
      }
    },
    checkEmail () {
      if (this.currUser.location_type === '1') {
        if (this.currUser.receipt_type === 3) {
          this.sendViaEmail = true
          if (this.selectedCustomer.email_add) {
            this.withoutEmailPrompt = false
            this.withEmailPrompt = true
          } else {
            this.withoutEmailPrompt = true
            this.withEmailPrompt = false
            this.sendViaEmail = false
          }
        } else {
          this.withoutEmailPrompt = false
          this.withEmailPrompt = false
          this.sendViaEmail = false
          this.manualConfirmPrompt = true
        }
      } else {
        this.proceedTransaction()
      }
    },
    printReceiptInstead () {
      this.sendViaEmail = false
      this.proceedTransaction()
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    getAmountPayapa (str) {
      var newStr = str.match(/\(([^)]+)\)/)[1]
      var final = newStr.substr(newStr.indexOf('Plan') + 5, newStr.length)
      console.log(final)
      return final
    },
    async saveLifeInsurance () {
      var str = this.transactionData.policy.policy_name.substring(0, this.transactionData.policy.policy_name.indexOf('('))
      console.log(str)
      const policyName = this.transactionData.policy.policy_name
      const beneficiaryFullName = trim([
        this.transactionData.beneficiaryFirstName,
        this.transactionData.beneficiaryMiddleName,
        this.transactionData.beneficiaryLastName
      ].join(' '))
      const fullAddress = [
        this.selectedCustomer.address,
        this.selectedCustomer.barangay,
        this.selectedCustomer.municipality,
        this.selectedCustomer.province,
        this.selectedCustomer.country
      ]

      const category = this.transactionData.insurance.category

      let payload

      if (category === 'COC9' || category === 'COC10') {
        payload = {
          category: this.transactionData.insurance.category
        }
      } else {
        payload = {
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          trx_date: this.currUser.trx_date,
          customer_id: this.transactionData.insuredCustomerID,
          customer_name: `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`,
          first_name: this.transactionData.insuredFirstName,
          middle_name: this.transactionData.insuredMiddleName,
          last_name: this.transactionData.insuredLastName,
          mobile_number: this.transactionData.insuredMobileNumber,
          plan_amount: 0,
          birthdate: this.transactionData.insuredBirthDate,
          occupation: this.transactionData.insuredOccupation,
          beneficiary: `${beneficiaryFullName}|${this.transactionData.beneficiaryRelationship}`,
          inceptiondate: this.transactionData.inceptionDate,
          expirydate: this.transactionData.expiryDate,
          coverage_count: this.transactionData.policy.product_type,
          amount: this.transactionData.amount,
          form_type: (this.currUser.location_type === '1' ? 'OAR' : ''),
          form_number: (this.currUser.location_type === '1' ? (this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no) : this.foreignRefNo),
          category: this.transactionData.insurance.category,
          ba_pa_codename: this.transactionData.agentPrefix + this.transactionData.agentCode,
          rso_roh_codename: this.transactionData.rsoPrefix + this.transactionData.rsoCode,
          address: fullAddress.join(' ')
        }
      }

      this.transactionData.formNumber = payload.form_number

      let masterPolicyNumber = ''

      if (policyName.includes('100,000')) {
        if (policyName.includes('64')) masterPolicyNumber = 'P0326277'
        else masterPolicyNumber = 'P0326282'
      } else if (policyName.includes('200,000')) {
        if (policyName.includes('64')) masterPolicyNumber = 'P0326279'
        else masterPolicyNumber = 'P0326283'
      } else if (policyName.includes('300,000')) {
        if (policyName.includes('64')) masterPolicyNumber = 'P0326281'
        else masterPolicyNumber = 'P0326285'
      }

      if (payload.category === 'COC4') {
        payload.plan_amount = Number(this.transactionData.policy.policy_name.match(/\d/g).join(''))
      }

      if (payload.category === 'COC' || payload.category === 'COC6') {
        payload.branch_code = this.currUser.location_code
        payload.ba_pa_codename = `${this.transactionData.insuredPensionCode}_${masterPolicyNumber}`

        if (payload.category === 'COC') {
          payload.email_address = this.transactionData.insuredEmailAddress
          payload.policy_type = this.transactionData.policyType
        }
      }

      if (payload.category === 'COC6') {
        payload.masterpolicynumber = masterPolicyNumber
      }
      console.log(this.transactionData.policy.policy_name)
      if (payload.category === 'COC9' || payload.category === 'COC10') {
        // const parsedDate = parseISO(this.transactionData.insuredBirthDate)
        payload.coc_number = this.transactionData.insurance.category
        payload.category = this.transactionData.insurance.category
        payload.user_id = this.currUser.user_id
        payload.customer_id = this.transactionData.insuredCustomerID
        payload.location_id = this.currUser.location_id
        payload.coverage_count = this.transactionData.policy.product_type
        payload.plan_amount = this.transactionData.amount
        payload.amount = this.transactionData.amount
        payload.trx_date = this.currUser.trx_date
        payload.inceptiondate = this.transactionData.inceptionDate
        payload.expirydate = this.transactionData.expiryDate
        payload.customer_name = `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`
        payload.location_code = this.currUser.location_type
        payload.form_number = (this.currUser.location_type === '1' ? this.receipt.oar_series_no : this.foreignRefNo)
        payload.form_type = (this.currUser.location_type === '1' ? 'OAR' : '')
        payload.processedby_lastname = this.currUser.last_name
        payload.branch_code = this.currUser.location_code
        payload.referer = this.transactionData.referrer
        payload.last_name = this.transactionData.insuredLastName
        payload.first_name = this.transactionData.insuredFirstName
        payload.middle_name = this.transactionData.insuredMiddleName
        payload.gender = this.transactionData.gender
        payload.birthdate = this.transactionData.insuredBirthDate
        payload.mobile_number = this.transactionData.insuredMobileNumber
        payload.provinceCode = this.transactionData.province
        payload.cityCode = this.transactionData.city
        payload.address = this.transactionData.address
        payload.maritalStatus = this.transactionData.civilStatus
        payload.occupation = this.transactionData.insuredOccupation
        payload.insCardNo = this.transactionData.insCardNo
        payload.beneFirstName = this.transactionData.beneficiaryFirstName
        payload.beneLastName = this.transactionData.beneficiaryLastName
        payload.beneMiddleName = this.transactionData.beneficiaryMiddleName
        payload.beneRelation = this.transactionData.beneficiaryRelationship
        payload.bene2LastName = this.transactionData.beneficiary2LastName
        payload.bene2FirstName = this.transactionData.beneficiary2FirstName
        payload.bene2MiddleName = this.transactionData.beneficiary2MiddleName
        payload.bene2Relation = this.transactionData.beneficiary2Relationship
        payload.bene3LastName = this.transactionData.beneficiary3LastName
        payload.bene3FirstName = this.transactionData.beneficiary3FirstName
        payload.bene3MiddleName = this.transactionData.beneficiary3MiddleName
        payload.bene3Relation = this.transactionData.beneficiary3Relationship
        payload.bene4LastName = this.transactionData.beneficiary4LastName
        payload.bene4FirstName = this.transactionData.beneficiary4FirstName
        payload.bene4MiddleName = this.transactionData.beneficiary4MiddleName
        payload.bene4Relation = this.transactionData.beneficiary4Relationship
      }

      if (this.completedForm) {
        const req = await this.$store.dispatch('insurance/save', payload)

        if (req.code === 200) {
          const address = this.selectedCustomer.address
          const barangay = this.selectedCustomer.barangay
          const city = this.selectedCustomer.municipalty
          const province = this.selectedCustomer.province
          const zipCode = this.selectedCustomer.zip_code

          // If location are COWs
          if (this.currUser.location_type === '1') {
            if (this.currUser.receipt_type === 3) {
              const saveReceipt = await this.$store.dispatch('saveReceipt', {
                ereceipt_id: this.receipt.ereceipt_id,
                application_id: this.currUser.application_id,
                application_header_name: this.currUser.branchDetail.header_name,
                application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                receipt_type: this.receipt.type,
                customer_id: this.selectedCustomer.customer_id,
                customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                customer_tin_no: '',
                customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                cashier_id: this.currUser.user_id,
                cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                oar_receipt_no: this.receipt.oar_receipt_no,
                oar_number: this.receipt.oar_series_no,
                oar_service: 'Pera Protect',
                oar_currency: 'PHP',
                oar_principal_amount: this.transactionData.amount,
                oar_discount: 0,
                oar_total: this.transactionData.amount,
                grand_total: this.transactionData.amount,
                print_email_hold: 0,
                is_forex_logo: 0
              })

              if (saveReceipt.code === 200) {
                if (saveReceipt.result.receipt_status === 1) {
                  renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                } else if (saveReceipt.result.receipt_status === 2) {
                  const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                    application_id: this.currUser.application_id,
                    ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                  })
                  var newBlob = new Blob([res], { type: 'application/pdf' })
                  var newFileURL = URL.createObjectURL(newBlob)
                  window.open(newFileURL, '_blank')
                } else {
                  renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                }
              } else {
                renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
              }
            } else {
              this.generateManualReceipt()
            }
          }

          this.$store.commit('insurance/SET_SUCCESS_DATA', req.result)
          this.$store.commit('insurance/SET_CURRENT_PAGE', 2)
          var age = differenceInYears(new Date(), new Date(this.transactionData.insuredBirthDate))
          console.log(age)
          var printCOCPayload
          var cocEndpoint
          var cocEndpoint2
          if (this.transactionData.insurance.category === 'COC') {
            cocEndpoint = 'coc/printMalayanNew'
            let getInsuranceType = ''

            if (this.transactionData.policyType === 'accident_plus_a') {
              getInsuranceType = '25k'
            } else {
              getInsuranceType = '100k'
            }

            printCOCPayload = {
              insurance_type: getInsuranceType,
              vlocation_name: this.currUser.location_name,
              location_id: this.currUser.location_id,
              coc_number: req.result.coc_number,
              customer_id: this.selectedCustomer.customer_id.toString(),
              crn: this.selectedCustomer.customer_number,
              vuser_fullname: `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`,
              vtrx_date: this.currUser.trx_date,
              has_wacom: this.currUser.has_wacom,
              uploaded_by: this.currUser.user_id,
              img: this.customerSignature,
              email: this.transactionData.insuredEmailAddress, // this.transactionData.insuredEmailAddress !== '' ? this.transactionData.insuredEmailAddress : ''
              inception_date: this.transactionData.inceptionDate
            }
          } else if (this.transactionData.insurance.category === 'COC3') {
            cocEndpoint = 'coc/printDengueProtectPlus'
            printCOCPayload = {
              vtrx_date: this.currUser.trx_date,
              vuser_fullname: `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`,
              vexpiredate: this.transactionData.expiryDate,
              vref_no: req.result.coc_number,
              vinceptiondate: this.transactionData.inceptionDate,
              vbirthdate: this.transactionData.insuredBirthDate,
              vage: age,
              voccupation: this.transactionData.insuredOccupation,
              vrelationship: this.transactionData.beneficiaryRelationship,
              beneficiary: `${this.transactionData.beneficiaryFirstName} ${this.transactionData.beneficiaryLastName}`
            }
          } else if (this.transactionData.insurance.category === 'COC4') {
            cocEndpoint = 'coc/printPeraPayapa'
            printCOCPayload = {
              vtrx_date: this.currUser.trx_date,
              vuser_fullname: `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`,
              vexpiredate: this.transactionData.expiryDate,
              vref_no: req.result.coc_number,
              vinceptiondate: this.transactionData.inceptionDate,
              vbirthdate: this.transactionData.insuredBirthDate,
              vage: age,
              beneficiary: `${this.transactionData.beneficiaryFirstName} ${this.transactionData.beneficiaryLastName}`,
              vinsurance: this.getAmountPayapa(this.transactionData.policy.policy_name)
            }
          } else if (this.transactionData.insurance.category === 'COC6') {
            cocEndpoint = 'coc/printCovidProtectPlus'
            printCOCPayload = {
              vtrx_date: this.currUser.trx_date,
              vuser_fullname: `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`,
              vexpiredate: this.transactionData.expiryDate,
              vref_no: req.result.coc_number,
              plan_type: this.transactionData.policy.policy_name
            }
          } else if (this.transactionData.insurance.category === 'COC8') {
            cocEndpoint = 'coc/printDengueProtectv2'
            printCOCPayload = {
              insurance_type: this.transactionData.insurance.category,
              vlocation_name: this.currUser.location_name,
              location_id: this.currUser.location_id,
              coc_number: req.result.coc_number,
              vref_no: req.result.coc_number,
              customer_id: this.selectedCustomer.customer_id.toString(),
              crn: this.selectedCustomer.customer_number,
              vuser_fullname: `${this.transactionData.insuredLastName}, ${this.transactionData.insuredFirstName}`,
              vtrx_date: this.currUser.trx_date,
              has_wacom: this.currUser.has_wacom,
              uploaded_by: this.currUser.user_id,
              img: this.customerSignature,
              inception_date: this.transactionData.inceptionDate,
              vrelationship: this.transactionData.beneficiaryRelationship,
              vinceptiondate: this.transactionData.inceptionDate,
              vexpiredate: this.transactionData.expiryDate,
              vbirthdate: this.transactionData.insuredBirthDate,
              occupation: this.transactionData.insuredOccupation,
              beneficiary: `${beneficiaryFullName}`,
              vage: age,
              voccupation: this.transactionData.insuredOccupation,
              email: this.transactionData.insuredEmailAddress
            }
          } else if (this.transactionData.insurance.category === 'COC9' || this.transactionData.insurance.category === 'COC10') {
            cocEndpoint = 'insurance/getCOC'
            cocEndpoint2 = 'insurance/getPOC'
            printCOCPayload = {
              traceNo: req.result.trace_number
            }
          }
          if (this.transactionData.insurance.category === 'COC9' || this.transactionData.insurance.category === 'COC10') {
            const reqCOC = await this.$store.dispatch(cocEndpoint, printCOCPayload)
            const reqPOC = await this.$store.dispatch(cocEndpoint2, printCOCPayload)
            var decodedCOC = Buffer.from(reqCOC.result, 'base64')
            var decodedPOC = Buffer.from(reqPOC.result, 'base64')
            var blobCOC = new Blob([decodedCOC], { type: 'application/pdf' })
            var blobPOC = new Blob([decodedPOC], { type: 'application/pdf' })
            const getCOC = URL.createObjectURL(blobCOC)
            const getPOC = URL.createObjectURL(blobPOC)
            window.open(getCOC, '_blank')
            setTimeout(() => {
              window.open(getPOC, '_blank')
            }, 1000)
          } else {
            const reqCOC = await this.$store.dispatch(cocEndpoint, printCOCPayload)
            console.log(reqCOC)
            console.log(printCOCPayload)
            var decoded = Buffer.from(reqCOC.result, 'base64')
            console.log(decoded)
            var blob = new Blob([decoded], { type: 'application/pdf' })
            var fileURL = URL.createObjectURL(blob)
            console.log(fileURL)
            window.open(fileURL, '_blank')
          }
        } else {
          if (Object.prototype.hasOwnProperty.call(req.error, 'message')) {
            if (this.transactionData.insurance.category === 'COC9' || this.transactionData.insurance.category === 'COC10') {
              if (req.error.message.statusDesc) {
                renderToast('error', 'Error', req.error.message.statusDesc)
              } else {
                renderToast('error', 'Error', req.error.message)
              }
            } else {
              renderToast('error', 'Error', req.error.message)
            }
          } else {
            renderToast('error', 'Error', 'Failed to process pera protect transaction')
          }
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to save this transaction')
      }
    },
    async saveNonLifeInsurance () {
      const fullAddress = [
        this.selectedCustomer.address,
        this.selectedCustomer.barangay,
        this.selectedCustomer.municipality,
        this.selectedCustomer.province,
        this.selectedCustomer.country
      ]

      const payload = {
        category: this.transactionData.insurance.category,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        trx_date: this.currUser.trx_date,
        customer_id: this.selectedCustomer.customer_id,
        customer_name: this.selectedCustomer.full_name,
        first_name: this.selectedCustomer.first_name,
        middle_name: this.selectedCustomer.middle_name,
        last_name: this.selectedCustomer.last_name,
        mobile_number: this.selectedCustomer.mobile_no,
        email_address: this.selectedCustomer.email_add,
        tin: '000000000000',
        address: fullAddress.join(' '),
        birth_date: this.selectedCustomer.birth_date,
        provider: 'CTPL',
        trx_type: 'R',
        trx_number: `${this.currUser.location_code}${getForeignRefNo()}`,
        inception_date: this.transactionData.inceptionDate,
        expiry_date: this.transactionData.expiryDate,
        mv_type: this.transactionData.mvType,
        premium_type: this.transactionData.premiumType,
        year: this.transactionData.vehicleYear,
        make: this.transactionData.vehicleMake,
        file_number: this.transactionData.vehicleFileNumber,
        chassis_number: this.transactionData.vehicleChassisNumber,
        plate_number: (this.transactionData.noPlateNumber) ? `${this.transactionData.vehicleFileNumber.substr(0, 4)}${this.transactionData.ORCRMonth}` : this.transactionData.plateNumber,
        engine_number: this.transactionData.vehicleEngineNumber,
        amount: this.transactionData.amount,
        form_type: (this.currUser.location_type === '1' ? 'OAR' : ''),
        form_number: (this.currUser.location_type === '1' ? (this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no) : this.foreignRefNo),
        ip_address: this.currUser.ip_address,
        color: this.transactionData.vehicleColor,
        unladen_weight: this.transactionData.vehicleUnladenWeight,
        type_of_body: this.transactionData.vehicleBody,
        authorized_capacity: this.transactionData.vehicleAuthorizedCapacity,
        basic_premium: this.transactionData.vehicleBasicPremium,
        authentication_fee: this.transactionData.vehicleAuthenticationFee,
        doc_stamps: this.transactionData.vehicleDocStamps,
        cancellation_fee: this.transactionData.vehicleCancellationFee,
        vat: this.transactionData.vehicleVAT,
        lgt: this.transactionData.vehicleLGT,
        total_commission: this.transactionData.vehicleTotalCommission,
        petnet_commission: this.transactionData.vehiclePetnetCommission,
        bp_commission: this.transactionData.vehicleBPCommission,
        discounted_price: this.transactionData.vehicleDiscountedPrice,
        promo_code: this.transactionData.vehiclePromoCode,
        plate_tag: (this.transactionData.noPlateNumber) ? 'N' : 'Y',
        is_corporate: this.transactionData.vehicleIsCorporate,
        company_name: this.transactionData.vehicleCompanyName
      }

      this.transactionData.formNumber = payload.form_number

      if (this.completedForm) {
        const req = await this.$store.dispatch('insurance/save', payload)

        if (req.code === 200) {
          if (this.currUser.location_type === '1') {
            if (this.currUser.receipt_type === 3) {
              const address = this.selectedCustomer.address
              const barangay = this.selectedCustomer.barangay
              const city = this.selectedCustomer.municipalty
              const province = this.selectedCustomer.province
              const zipCode = this.selectedCustomer.zip_code
              const saveReceipt = await this.$store.dispatch('saveReceipt', {
                ereceipt_id: this.receipt.ereceipt_id,
                application_id: this.currUser.application_id,
                application_header_name: this.currUser.branchDetail.header_name,
                application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                receipt_type: this.receipt.type,
                customer_id: this.selectedCustomer.customer_id,
                customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                customer_tin_no: '',
                customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                cashier_id: this.currUser.user_id,
                cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                oar_receipt_no: this.receipt.oar_receipt_no,
                oar_number: this.receipt.oar_series_no,
                oar_service: 'Pera Protect',
                oar_currency: 'PHP',
                oar_principal_amount: this.transactionData.amount,
                oar_discount: this.transactionData.vehicleDiscountedPrice,
                oar_total: this.transactionData.amount,
                grand_total: this.transactionData.amount,
                print_email_hold: 0,
                is_forex_logo: 0
              })

              if (saveReceipt.code === 200) {
                if (saveReceipt.result.receipt_status === 1) {
                  renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                } else if (saveReceipt.result.receipt_status === 2) {
                  const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                    application_id: this.currUser.application_id,
                    ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                  })
                  var newBlob = new Blob([res], { type: 'application/pdf' })
                  var newFileURL = URL.createObjectURL(newBlob)
                  window.open(newFileURL, '_blank')
                } else {
                  renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                }
              } else {
                renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
              }
            } else {
              this.generateManualReceipt()
            }
          }

          this.$store.commit('insurance/SET_SUCCESS_DATA', req.result)
          this.$store.commit('insurance/SET_CURRENT_PAGE', 2)

          const payload = {
            af_number: req.result.af_number,
            policy_number: req.result.policy_number,
            name: req.result.customer_name,
            address: req.result.address,
            coc_number: req.result.coc_number,
            date_issued: req.result.trx_date,
            inception_date: req.result.inception_date,
            expiry_date: req.result.expiry_date,
            model: req.result.year,
            make: req.result.make,
            type_of_body: req.result.type_of_body,
            color: req.result.color,
            mv_file_number: req.result.file_number,
            plate_number: req.result.plate_number,
            chassis_number: req.result.chassis_number,
            engine_number: req.result.engine_number,
            premium_paid: req.result.amount
          }

          if (this.transactionData.vehicleIsCorporate) {
            payload.name = req.result.company_name
          }

          const reqCOC = await this.$store.dispatch('coc/printCTPL', payload)

          if (reqCOC.code === 200) {
            var decoded = Buffer.from(reqCOC.result, 'base64')
            var blob = new Blob([decoded], { type: 'application/pdf' })
            var fileURL = URL.createObjectURL(blob)
            window.open(fileURL, '_blank')
          }
        } else {
          renderToast('error', 'Error', 'Failed to process pera protect transaction')
        }
      } else {
        renderToast('warning', 'Validation Error', 'Please complete the form to save this transaction')
      }
    },
    async doSave () {
      if (this.currUser.location_type !== '1') {
        this.doSaveInsurance()
      } else {
        if (this.currUser.receipt_type === 3) {
          const getReceipt = await this.$store.dispatch('getReceipt', {
            location_id: this.currUser.location_id,
            type: '2',
            application_id: this.currUser.application_id
          })

          if (getReceipt.code === 200) {
            this.doSaveInsurance()
          } else {
            renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
          }
        } else {
          const payload = {
            location_id: this.currUser.location_id,
            type: 'OAR'
          }

          await this.$store.dispatch('manualReceipt/getAvailableSeriesNo', payload).then(res => {
            if (res.code >= 200 && res.code <= 299) {
              if (res.result.length > 0) {
                this.doSaveInsurance()
              } else {
                renderToast('error', 'Error', 'No available series number for this branch. Please request from support.')
              }
            } else {
              renderToast('error', 'Error', res.message)
            }
          })
        }
      }
    },
    async doSaveInsurance () {
      if (this.transactionData.insurance && this.transactionData.insurance.category === 'COC7') {
        this.saveNonLifeInsurance()
      } else {
        this.saveLifeInsurance()
      }
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: 'Pera Protect - ' + this.transactionData.insurance.name,
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    formatAmount (amount, currency) {
      if (amount) {
        return toMoney(amount, currency)
      } else {
        return ''
      }
    },
    toMoney,
    // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
    async generateManualReceipt () {
      const payload = {
        id: this.seriesDetail.id,
        status: 2,
        customer_id: this.selectedCustomer.customer_id,
        encoded_by: this.currUser.user_id,
        date_encoded: format(new Date(), 'yyyy-MM-dd'),
        amount: this.transactionData.amount,
        description: 'Pera Protect',
        remarks: 'Pera Protect'
      }

      await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
        } else {
          renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        renderToast('error', 'Error', err)
      })
    }
  },
  async mounted () {
    const that = this
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
    that.customerSignaturesReference = signatures.result
    this.checkConsent()
    this.foreignRefNo = this.getForeignRefNo()
  }
}
</script>

<style scoped>
#summaryOverview {
  position: sticky !important;
  top: 80px !important;
  background: #f4f4fa !important;
  border-radius: 5px !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:100%;
}
.v-stepper.ph-wu-receive-stepper .v-stepper__header {
  box-shadow: none !important;
}

.ph-wu-receive-summary .v-card {
  padding: 20px;
}

.ph-transfer-summary-header {
  font-size: 18px;
  text-align: center;
}

.ph-transfer-summary-content-title {
  font-size: 14px;
}

.transfer-info-title,
.transfer-info-value {
  border-bottom: thin dashed rgba(0,0,0,.12) !important;
}

.transfer-info-title {
  font-weight: bolder;
  padding-left: 0 !important;
}

.transfer-info-value {
  text-align: right;
  padding-right: 0 !important;
}

.ph-transfer-summary-table {
  background-color: transparent !important;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  height: 60px !important;
  width: 100%;
  margin: 10px 0;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
  /* border: 2px solid #1A2791; */
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  /* box-shadow: -4.75px 30px 30px 0px #1A279140 !important; */
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-transaction-container {
  padding: 0;
}

.ph-stepper-action-buttons {
  display: flex;
  margin-bottom: 100px;
}

.ph-primary-button.ph-stepper-button {
  height: 40px !important;
  width: 115px;
}

/* @media only screen and (min-width: 600px) {
  .v-stepper.ph-wu-receive-stepper .v-stepper__step {
    padding-left: 0;
  }
} */

.ph-wu-receive-summary-minified {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #F4F4FA;
}

.ph-wu-receive-summary-content {
  display: flex;
  flex-direction: row;
  padding: 15px 30px;
}

.ph-wu-receive-summary-minified-info {
  margin-right: auto;
}

.ph-wu-receive-summary-minified-info p {
  margin-bottom: 0;
}

.ph-wu-receive-summary-minified-action {
  margin-left: auto;
}

.ph-wu-receive-form {
  padding: 12px 0;
}

@media only screen and (min-width: 960px) {
  .ph-stepper-action-buttons {
    display: flex;
    margin-bottom: 0;
  }
}
</style>

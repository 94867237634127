import { endpoints } from '@/api/endpoints'

const cico = endpoints.use('cico')

export default {
  getCICOList: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.getCICOList(payload)
      .then(({ data }) => {
        commit('SET_TRANSACTIONS', data.result)
        resolve(data.result)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  trxCICOValidate: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxCICOValidate(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxCICOExecute: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxCICOExecute(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxCICOInquire: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxCICOInquire(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxCICORetry: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxCICORetry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxOTPCustomer: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxOTPCustomer(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxEONCashIn: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxEONCashIn(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxEONCashOut: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxEONCashOut(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxEONRetry: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxEONRetry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxOTPSend: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxOTPSend(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxOTPPerahub: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxOTPPerahub(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxPHVCashOutExecute: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxPHVCashOutExecute(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  trxPHVCashOutRetry: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.trxPHVCashOutRetry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  generateVoucher: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.generateCICOVoucher(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getCICOProviders: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.getCICOProviders(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  getDiskartechRefNoDetails: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.getDiskartechRefNoDetails(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  updateTotalAmount: ({ commit }, payload) => new Promise((resolve, reject) => {
    cico.updateTotalAmount(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}

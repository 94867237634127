<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-card-title class="ph-nonex-datatable-title">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="ph-textbox ph-textbox-grid-search"
                outlined
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="peraLoanTransactions"
              :search="search"
              :calculate-widths="true"
              class="ph-nonex-datatable"
            >
              <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.customer_name`]="{item}">
                <div class="ph-nonex-receiver-container">
                  <v-avatar class="mr-1 ph-nonex-status-circle" size="25px" :style="avatarColor(item.entryfn)">
                    <span class="white--text text-body-2">{{ avatarInitial(item.entryfn) }}</span>
                  </v-avatar>
                  <p class="ph-nonex-table-names-text">{{item.entryfn}} {{item.entrymn ? item.entrymn : ''}} {{item.entryln}}</p>
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container orange--text" v-if="item.status === '0'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Encoded
                </span>
                <span class="ph-nonex-status-container primary--text" v-if="item.status === '1'">
                  <v-icon color="primary" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Released
                </span>
              </template>
              <template v-slot:[`item.remarks`]="{ item }">
                <p class="ph-nonex-table-text">{{ item.status === '0' ? 'Step 1 (Incomplete)' : 'Done'}}</p>
              </template>
              <template v-slot:[`item.loan_type`]="{ item }">
                <p class="ph-nonex-table-text" v-if="item.loan_type === 'D'">Teacher's Loan</p>
                <p class="ph-nonex-table-text" v-if="item.loan_type === 'P'">Pension</p>
                <p class="ph-nonex-table-text" v-if="(item.loan_type === 'Pera Loan')">{{item.loan_type}}</p>
              </template>
              <template v-slot:[`item.service_fee`]="{item}">
                <p class="ph-nonex-table-text">PHP {{formatAmount(item.service_fee)}}</p>
              </template>
              <template v-slot:[`item.total_amount`]="{item}">
                <p class="ph-nonex-table-text">PHP {{formatAmount(item.total_amount)}}</p>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn v-if="item.status === '0'" @click="openUpdateDialog(item)" color="primary" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="item.status === '1'" @click="openCashOutDialog(item)" color="primary" icon>
                  <v-icon>mdi-cash-check</v-icon>
                </v-btn>
                <v-btn v-if="item.status === '2'" @click="generateVoucher(item)" color="primary" icon>
                  <v-icon>mdi-file-eye-outline</v-icon>
                </v-btn>
                <v-tooltip left v-if="item.adj_status == 0 && item.status === '1'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="requestCancellation(item)" color="primary" icon>
                      <v-icon>mdi-marker-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Request for Transaction Cancellation</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
          color="primary"
          fab
          large
          fixed
          dark
          bottom
          right
          to="/loans/pera-loan/new"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>
    <PeraLoanUpdateDialog v-bind:updateLoanDetailsDialog.sync="updateLoanDetailsDialog" v-bind:selectedTrx="selectedTrx" v-bind:peraLoanTransactions="peraLoanTransactions"/>
    <PeraLoanCashOutDialog v-bind:cashOutDialog.sync="cashOutDialog" v-bind:selectedTrx="selectedTrx" />
    <v-dialog
      v-model="cancelTrxDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Cancel Selected Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          <p>
            Are you sure you want to request this transaction for cancellation?
          </p>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeCancelTrxDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="executeRequestCancel"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Transaction Successful
        </v-card-title>

        <v-card-text>
          <br>
          <p><b>The print receipt window will open in 3 seconds.</b></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button ph-action-button-receive-confirm"
            elevation="0"
            @click="printReceipt"
          >
            Print Receipt
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="white"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import PeraLoanUpdateDialog from '@/components/peraLoan/PeraLoanUpdateDialog.vue'
import PeraLoanCashOutDialog from '@/components/peraLoan/PeraLoanCashOutDialog.vue'

import {
  avatarColor,
  avatarInitial,
  toProperCase,
  renderToast
} from '@/utils'

export default {
  name: 'PeraLoanIndex',
  components: {
    PeraLoanUpdateDialog,
    PeraLoanCashOutDialog
  },
  data () {
    return {
      base64String: '',
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Loans',
          disabled: false,
          href: '/loans'
        },
        {
          text: 'Pera Loan',
          disabled: true,
          href: '#'
        }
      ],
      filteredACRs: [],
      search: '',
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Application Number',
          sortable: true,
          value: 'aplno'
        },
        {
          text: 'Name',
          sortable: true,
          value: 'customer_name'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Remarks',
          sortable: true,
          value: 'remarks'
        },
        {
          text: 'Loan Type',
          sortable: true,
          value: 'loan_type'
        },
        {
          text: 'Actions',
          sortable: true,
          value: 'action'
        }
      ],
      successDialog: false,
      updateLoanDetailsDialog: false,
      interval: {},
      progressValue: 0,
      countdown: 3,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-y-reverse-transition',
      selectedTrx: {},
      cashOutDialog: false,
      cancelTrxRemarks: '',
      cancelTrxDialog: false,
      cancelRequestPayload: {}
    }
  },
  computed: {
    ...mapGetters({
      peraLoanTransactions: 'loans/peraLoanTransactions',
      collections: 'collections',
      currUser: 'auth/currUser',
      remcos: 'nonex/remcos',
      oarNumber: 'oar/oarNumber'
    })
  },
  watch: {
    updateLoanDetailsDialog: {
      handler (data) {
        console.log(data)
      },
      deep: true
    },
    countdown: {
      handler (count) {
        if (count === 0) {
          const that = this
          setTimeout(function () {
            that.progressValue = 0
            that.successDialog = false
          }, 1000)

          clearInterval(this.interval)
        }
      }
    }
  },
  async beforeMount () {
    this.getTransactions()
  },
  methods: {
    avatarColor,
    avatarInitial,
    toProperCase,
    renderToast,
    getTransactions () {
      this.$store.commit('loans/SET_PERA_LOAN_RESPONSE_DATA', null)
      this.$store.commit('loans/SET_PERA_LOAN_TPA_ID', '')
      this.$store.commit('loans/SET_PERA_LOAN_ACCOUNT_NO', '')

      this.$store.dispatch('loans/getPeraLoanTrx', {
        date_from: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        date_to: format(parseISO(this.currUser.trx_date + ' ' + '00:00:00'), 'yyyy-MM-dd'),
        location_id: this.currUser.location_id
      })
    },
    formatDate (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd HH:mm:SS')
    },
    formatDateTimeACR (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    printReceipt (ACRPayload) {
      const that = this
      this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
        that.base64String = res.result
        that.pdfConvert()
        that.getTransactions()
      }).catch((error) => {
        console.log(error)
      })
    },
    async retryTransaction (item) {
      const currentUser = this.currUser
      const apiRequestData = JSON.parse(item.api_request)

      const tpaPayload = {
        bill_id: 2,
        location_id: this.currUser.location_id
      }

      let tpaId = ''

      await this.$store.dispatch('loans/getTPARequest', tpaPayload)
        .then(res => {
          tpaId = res.tpa_id
        }).catch(e => {
          console.log(e)
        })

      const retryPayload = {
        coy: item.coy,
        type: apiRequestData.type,
        amount: item.amount,
        tpa_id: tpaId,
        bill_id: item.bill_id,
        user_id: currentUser.user_id.toString(),
        trx_date: currentUser.trx_date,
        form_type: item.form_type,
        otherInfo: apiRequestData.otherInfo ? apiRequestData.otherInfo : '',
        biller_tag: item.biller_tag,
        identifier: item.identifier,
        biller_name: apiRequestData.biller_name,
        callbackUrl: null,
        currency_id: item.currency_id,
        customer_id: item.customer_id,
        form_number: item.form_number,
        location_id: item.location_id,
        total_amount: item.total_amount,
        location_name: item.location_name,
        account_number: item.account_number,
        partner_charge: item.partner_charge,
        payment_method: apiRequestData.payment_method,
        service_charge: item.service_charge,
        reference_number: item.reference_number,
        validation_number: item.validation_number,
        client_reference_number: item.client_reference_number,
        receipt_validation_number: apiRequestData.receipt_validation_number,
        id: item.id_loan
      }

      await this.$store.dispatch('loans/retryTrx', retryPayload).then(res => {
        if (res?.result) {
          this.successDialog = true
          this.countdown = 3
          this.interval = setInterval(() => {
            if (this.progressValue === 99.99 && this.countdown === 0) {
              return (this.progressValue = 0)
            }
            this.progressValue += 33.3333333333
            this.countdown -= 1
          }, 1000)
          const that = this

          setTimeout(function () {
            that.printPeraLoanReceipt(tpaId, retryPayload)
          }, 3000)
        } else {
          this.renderToast('error', 'Transaction Error', res.error.moreInfo.details.message)
        }
      }).catch(() => {
        this.renderToast('error', 'Transaction Error', 'Something went wrong in retrying the transaction. Please Try Again.')
      })
    },
    selectedTransaction (item) {
      if (item.assign_oar) {
        this.selectedTrx = item
      } else {
        this.selectedTrx = {}
      }
    },
    generateOAR (formNumber) {
      window.open(`/receipt?ref=${formNumber}&trx_type=peraloan&type=OAR`, '_blank')
    },
    generateVoucher (data) {
      console.log(data)
      const payload = {
        location_id: data.location_id,
        trx_date: this.formatDate(data.trx_date),
        ref_no: data.gvouchnum,
        trx_type: 'loans'
      }

      const that = this

      this.$store.dispatch('loans/generatePeraLoanVoucher', payload).then(res => {
        const voucherData = res.result[0]

        const voucherItem = {
          trx_data: data,
          voucher_data: voucherData,
          trx_type: 'loan'
        }

        console.log(voucherItem)

        that.$store.commit('loans/SET_VOUCHER_DETAILS', voucherItem)

        setTimeout(function () {
          window.open('/loans/print-voucher', '_blank')
        }, 2500)
      }).catch(err => {
        console.log(err)
      })
    },
    openUpdateDialog (item) {
      this.updateLoanDetailsDialog = true
      this.selectedTrx = item
    },
    openCashOutDialog (item) {
      this.cashOutDialog = true
      this.selectedTrx = item
    },
    requestCancellation (item) {
      this.cancelRequestPayload = item
      this.cancelTrxDialog = true
    },
    closeCancelTrxDialog () {
      this.cancelRequestPayload = {}
      this.cancelTrxDialog = false
    },
    async executeRequestCancel () {
      const item = this.cancelRequestPayload

      const payload = {
        id: item.id_loan,
        service_code: 'peraloan',
        status: '1',
        location_id: this.currUser.location_id,
        payload: {
          adj_status: '1',
          adj_requested_by: this.currUser.user_id,
          adj_requested_date: this.currUser.trx_date,
          adj_requested_reason: this.cancelTrxRemarks,
          adj_category: 'cancel'
        }
      }

      await this.$store.dispatch('transactionCancel/cancelRequest', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Success', 'The Transaction Cancellation has been successfully requested.')
          this.closeCancelTrxDialog()
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      })
    }
  }
}
</script>
<style scoped>
/* .ph-nonex-datatable-title {
  padding-left: 0;
} */

.v-data-table.ph-nonex-datatable {
  /* background: #FFFFFF; */
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.06) !important;
}

.ph-nonex-sender-container,
.ph-nonex-receiver-container {
  display: flex;
}

.ph-nonex-table-avatar {
  margin: auto 0;
}

.ph-nonex-table-text {
  margin: auto;
  margin-left: 0;
}

.ph-nonex-table-names-text,
.ph-nonex-status-text {
  margin: auto;
  margin-left: 10px;
}

.ph-nonex-status-circle {
  height: 10px;
  width: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: auto 0;
}

.ph-nonex-status-success {
  background: #46B746;
}

.ph-nonex-status-success-text {
  color: #46B746;
}

.ph-nonex-status-pending {
  background: #F76F34;
}

.ph-nonex-status-pending-text {
  color: #F76F34;
}

.ph-nonex-status-container {
  display: flex;
}

.ph-nonex-action-container {
  display: flex;
  flex-direction: row;
}

.ph-wu-logo {
  height: 25px;
  width: 10px;
}

.ph-nonex-logo {
  transform: scale(0.45);
  border-radius: 0;
}

.ph-fulfill-logo {
  transform: scale(0.35);
  border-radius: 0;
}

.ph-tooltip {
  background: #EDEDED;
  color: #000;
  border-radius: 50px;
}

.ph-primary-button {
  background: #FFF;
  color: #1A2791 !important;
  /* border: 2px solid #1A2791; */
  border-radius: 10px;
  font-family: 'Proxima Nova';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 16px;
  padding: 23px;
  transition: .2s;
  margin: 10px 0;
  margin-right: 10px;
}

.ph-primary-button:hover {
  background: #1A2791 !important;
  color: #fff !important;
}

.ph-primary-button.ph-primary-execute-btn {
  background: #1A2791;
  color: #fff !important;
  font-size: 14px;
}

.ph-primary-button.ph-primary-execute-btn:hover {
  background: #fff !important;
  color: #1A2791 !important;
  border: 2px solid #1A2791;
}

.ph-datatable-checked {
  margin-left: 2px;
}
</style>

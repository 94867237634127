<template>
  <v-navigation-drawer
    v-model="drawer"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant="mini"
    mini-variant-width="120"
    width="256"
    app
    class="ph-navigation-drawer"
  >
    <v-list-item class="ph-logo-container">
      <v-list-item-avatar class="ph-logo-sidebar">
        <v-img src="@/assets/images/ph-logo-circular.png"></v-img>
      </v-list-item-avatar>
    </v-list-item>
    <v-list dense nav>
      <!-- Dashboard -->
      <div class="ph-list-dashboard-container">
        <!-- If minified -->
        <v-tooltip right v-if="mini">
          <template v-slot:activator="{ on, attrs }">
             <v-list-item link class="ph-list-dashboard" to="/" v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-home-city</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Dashboard</span>
        </v-tooltip>
        <!-- If not minified -->
        <v-list-item link class="ph-list-dashboard" to="/" v-if="!mini">
          <v-list-item-icon>
            <v-icon>mdi-home-city</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <!-- Lists -->
      <!-- If minified -->
      <div v-if="mini">
        <v-tooltip right v-for="item in items" :key="item.title">
          <template v-slot:activator="{ on, attrs }">
            <v-list-group
                :prepend-icon="item.icon"
                class="ph-list-group"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title slot="activator">
                  <div>{{item.title}}</div>
                </v-list-item-title>
                <div v-if="item.sub_items && item.sub_items.length > 0">
                  <v-tooltip right v-for="link in item.sub_items" :key="link.title">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                        router
                        :to="link.to"
                        link
                        active-class="primary white--text"
                        class="ph-list"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-icon>
                          <v-icon small>{{ link.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ link.title }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{link.title}}</span>
                  </v-tooltip>
                </div>
              </v-list-group>
          </template>
          <span>{{item.title}}</span>
        </v-tooltip>
      </div>
      <!-- If not minified -->
      <div v-if="!mini">
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          class="ph-list-group"
        >
          <v-list-item-title slot="activator">
            <div>{{item.title}}</div>
          </v-list-item-title>
          <div v-if="item.sub_items && item.sub_items.length > 0">
            <v-list-item
              v-for="link in item.sub_items"
              :key="link.title"
              router
              :to="link.to"
              link
              active-class="primary white--text"
              class="ph-list"
            >
              <v-list-item-icon>
                <v-icon small>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
      </div>

      <!-- Compliance -->
      <div class="ph-list-dashboard-container" v-if="complianceModule.hasAccess">
        <!-- If minified -->
        <v-tooltip right v-if="mini">
          <template v-slot:activator="{ on, attrs }">
             <v-list-item link class="ph-list-dashboard" to="/compliance" v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Compliance</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Compliance</span>
        </v-tooltip>
        <!-- If not minified -->
        <v-list-item link class="ph-list-dashboard" to="/compliance" v-if="!mini">
          <v-list-item-icon>
            <v-icon>mdi-alert</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Compliance</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <!-- Digicur -->
      <div class="ph-list-dashboard-container" v-if="digicurAccess">
        <!-- If minified -->
        <v-tooltip right v-if="mini">
          <template v-slot:activator="{ on, attrs }">
             <v-list-item link class="ph-list-dashboard" to="/digicur" v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-file-account</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Digicur</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Digicur</span>
        </v-tooltip>
        <!-- If not minified -->
        <v-list-item link class="ph-list-dashboard" to="/digicur" v-if="!mini">
          <v-list-item-icon>
            <v-icon>mdi-file-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Digicur</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <!-- Reports -->
      <div class="ph-list-dashboard-container">
        <!-- If minified -->
        <v-tooltip right v-if="mini">
          <template v-slot:activator="{ on, attrs }">
             <v-list-item link class="ph-list-dashboard" to="/reports" v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-poll</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Reports</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Reports</span>
        </v-tooltip>
        <!-- If not minified -->
        <v-list-item link class="ph-list-dashboard" to="/reports" v-if="!mini">
          <v-list-item-icon>
            <v-icon>mdi-poll</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <template v-slot:append>
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list dense nav>
            <div class="ph-list-logout-container">
              <!-- If minified -->
              <v-tooltip right v-if="mini">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-on="on" v-bind="attrs" link class="ph-list-logout" @click="dialog = true">
                    <v-list-item-icon>
                      <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Log Out</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Log Out</span>
              </v-tooltip>
              <!-- If not minified -->
              <v-list-item v-if="!mini" v-on="on" v-bind="attrs" link class="ph-list-logout">
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </template>

        <v-card>
          <v-card-title class="ph-dialog-title">
            Logout User
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            Are you sure you want to log out?
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="dialog = false"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="logout()"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import { renderToast } from '@/utils'

export default {
  data () {
    return {
      drawer: false,
      items: [],
      mini: false,
      dialog: false,
      digicurAccess: false
    }
  },
  computed: {
    ...mapGetters({
      getMiniState: 'app/getMiniState',
      getDrawerState: 'app/getDrawerState',
      currUser: 'auth/currUser',
      complianceModule: 'compliance/module'
    })
  },
  watch: {
    drawer: {
      handler (getState) {
        this.$store.commit('app/TOGGLE_DRAWER', getState)
      },
      deep: true
    },
    getMiniState: {
      handler (getState) {
        this.mini = getState
      },
      deep: true
    },
    getDrawerState: {
      handler (getState) {
        this.drawer = getState
      },
      deep: true
    }
  },
  mounted () {
    this.mini = this.$route.meta.mini
    const modules = this.currUser.modules.includes('&quot') ? JSON.parse(this.currUser.modules.replace(/&quot;/g, '"')) : this.currUser.modules
    this.$store.commit('app/TOGGLE_MINI_SIDEBAR', this.mini)

    modules.forEach(accessModule => {
      const currentItem = this.items.filter(item => item.title === accessModule.module_code)

      if (currentItem.length === 0) {
        let icon = ''

        switch (accessModule.module_code) {
          case 'Teller':
            icon = 'mdi-cash'
            break
          case 'BOS':
            icon = 'mdi-account-multiple'
            break
          case 'Treasury':
            icon = 'mdi-bank'
            break
          case 'Compliance':
            icon = 'mdi-alert'
            break
          case 'Administrator':
            icon = 'mdi-cog'
            break
        }

        if (accessModule.module_code === 'Compliance') {
          this.$store.commit('compliance/UPDATE_MODULE', {
            hasAccess: true,
            subModules: accessModule.sub_modules.map(item => item.id)
          })
          if (accessModule.module_name === 'Digicur') this.digicurAccess = true
        } else {
          this.items.push({
            title: accessModule.module_code,
            icon: icon,
            sub_items: [
              { title: accessModule.module_name, icon: accessModule.module_img, to: accessModule.access_link }
            ]
          })
        }
      } else {
        this.items.forEach(item => {
          if (item.title === accessModule.module_code) {
            item.sub_items.push({ title: accessModule.module_name, icon: accessModule.module_img, to: accessModule.access_link })
          }
        })
      }
    })
  },
  methods: {
    renderToast,
    async logout () {
      const that = this
      this.dialog = false

      const payload = {
        username: this.currUser.user_name,
        ip_address: this.currUser.ip_address
      }

      this.$store.commit('auth/SET_IS_OTP_PASSED', false)
      this.$store.commit('app/TOGGLE_LOADING', true)

      await this.$store.dispatch('auth/doLogout', payload).then(res => {
        that.$store.commit('auth/SET_CURRENT_USER', {})
        that.$store.commit('auth/AUTH_SUCCESS', false)
        that.$store.commit('auth/SET_SHOW_REFRESH', false)
        that.$store.commit('app/TOGGLE_LOADING', false)
        that.$store.commit('wacom/SET_WACOM_IDLE_DIALOG', false)
        location.href = `${this.$uspAuthAppURL}/login`
        localStorage.setItem('logout_message', 'You are now logged-out.')
      }).catch(() => {
        this.renderToast('error', 'Error logging-out', 'An error has occured, please try again.')
      })
    }
  }
}
</script>
<style >
  .ph-logo-container {
    margin: 70px 0;
  }

  .ph-navigation-drawer.v-navigation-drawer--mini-variant .ph-logo-sidebar {
    margin: auto !important;
    height: 50px !important;
    width: 50px !important;
  }

  .ph-logo-sidebar {
    margin: auto !important;
    height: 80px !important;
    width: 80px !important;
  }

  .ph-list-group,
  .ph-list-dashboard-container,
  .ph-list-logout-container {
    font-family: 'Proxima Nova Bold';
    padding: 5px 15px;
    transition: .2s;
  }

  .ph-list-dashboard,
  .ph-list,
  .ph-list-group .v-list-group__header,
  .ph-list-logout {
    font-family: 'Proxima Nova Bold';
    transition: .2s;
    padding: 0 5px !important;
    color: #757575 !important;
  }

  .ph-list-dashboard .v-icon,
  .ph-list-group .v-list-item .v-icon,
  .ph-list-logout .v-icon {
    color: unset !important;
  }

  .ph-list-dashboard:hover,
  .v-list-group.ph-list-group .v-list-group__header:hover,
  .ph-list:hover,
  .ph-list-logout:hover {
    background: #1A2791;
    color: #FFF !important;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
  }

  /* Dialog */
  .v-dialog .ph-dialog-title {
    font-family: 'Baloo Extra Bold' !important;
    background: #1A2791;
    color: #FFF;
  }

  .v-dialog .ph-dialog-header {
    font-family: 'Proxima Nova Bold';
  }

  .ph-action-button {
    border-radius: 10px;
    font-family: 'Proxima Nova Bold';
  }

  .v-application--is-ltr .ph-navigation-drawer.v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
    margin-right: 0;
  }
</style>

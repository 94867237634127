<template>
  <v-col class="d-none d-md-block" :md="4">
    <v-card id="summaryOverview" class="px-6 py-6 grey--text text--darken-2" flat>
      <h3 class="text-center mb-md-8">Summary Overview</h3>
      <v-row no-gutters>
        <v-col v-for="data in summaryData" :key="data.text" :cols="12">
          <div class="d-flex justify-space-between">
            <div>{{ data.text }}</div>
            <div>
              <strong>{{ data.value || '—' }}</strong>
            </div>
          </div>
          <v-divider class="my-md-2" style="border-style: dashed" />
        </v-col>
      </v-row>
      <wacom-consent />
      <wacom-transaction
        v-if="currUser.has_wacom"
        @submit-sig="submitSig" type="transactional"
        :referenceSignatures="customerSignaturesReference"
        :trxData="{sender_name: selectedCustomer.full_name, receiver_name: `${transactionData.receiverLastName}, ${transactionData.receiverFirstName}`, total_amount: formatAmount(transactionData.totalAmount)}"
        />
      <wacom-data-consent-first />
      <wacom-data-consent-second />
      <wacom-data-consent-third />
      <wacom-data-consent-fourth />
      <wacom-data-consent-fifth />
      <wacom-data-consent-sixth />
      <wacom-data-consent-seventh
        :remco="transactionData.remcoName"
      />
      <v-checkbox
        v-model="transactionData.agentVerifiedData"
        class="my-0 py-0 mt-md-6"
        label="I have Verified Customer Data"
        hide-details
      />
      <v-btn
        @click="doRiskProfiling"
        class="rounded-lg mt-md-4 font-weight-black"
        color="primary"
        :disabled="!isCompleteForm"
        v-if="transactionData.remcoID === 0"
        outlined
        block
        x-large
      >
        Pre-Print
      </v-btn>
      <!-- <v-btn
        @click="eSignatureNonex"
        class="rounded-lg mt-md-4 font-weight-black"
        color="primary"
        :disabled="!isCompleteForm"
        v-if="transactionData.remcoID !== 0 && currUser.has_wacom && customerSignature === ''"
        outlined
        block
        x-large
      >
        E-Signature
      </v-btn> -->
      <v-btn
        @click="checkEmail"
        class="rounded-lg mt-md-4 font-weight-black"
        v-if="transactionData.remcoID == 0"
        color="primary"
        :disabled="(!isDonePreprint && transactionData.remcoID === 0) || (!isCompleteForm && transactionData.remcoID !== 0)"
        block
        x-large
      >
        Confirm
      </v-btn>
      <v-btn
        @click="checkEmail"
        class="rounded-lg mt-md-4 font-weight-black"
        v-else
        color="primary"
        :disabled="(!isDonePreprint && transactionData.remcoID === 0) || (!isCompleteForm && transactionData.remcoID !== 0)"
        block
        x-large
      >
        Confirm
      </v-btn>
      <v-dialog v-model="otpDialog" width="30%" persistent>
        <v-card class="rounded-lg">
          <v-card-title class="pb-0">
            <v-spacer />
            <v-btn @click="otpDialog = false" x-small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="py-md-4 text-center">
            <img src="@/assets/images/icons/otp-lock-icon.svg" alt="">
            <h3 class="pt-md-4 pb-md-6 custom-heading primary--text">
              OTP
            </h3>
            <p>
              Input your 6-digit One-time password PIN from the authentication app.
            </p>
            <v-otp-input
              v-model="otp"
              id="otp"
              type="number"
              :disabled="isOTPLoading"
              @finish="verifyOTP"
            />
            <p class="red--text">
              {{ otpMessage }}
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="withoutEmailPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            No email address encoded for this customer. Would you like to proceed with printing of OR/OAR?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="updateDetails(selectedCustomer)"
              color="primary"
              text
            >
              Update Customer Record
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="withEmailPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            Receipt will send to the client/s email address. Would you like to proceed this transaction?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="printReceiptInstead"
              color="primary"
              text
            >
              I want a printed Receipt
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="manualConfirmPrompt"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Transaction Confirmation
          </v-card-title>

          <v-card-text class="ph-dialog-header">
            <br>
            Would you like to proceed with this transaction?
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="manualConfirmPrompt = false"
              color="primary"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="proceedTransaction"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="validationDialog" persistent>
        <v-card>
          <v-card-title class="ph-dialog-title">
            Form Validation
          </v-card-title>
          <v-card-text class="ph-dialog-header">
            <br>
            There are errors in the form submitted. Please refer to the message below:
            <br>
            <span v-html="validationMessage"></span>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              class="ph-action-button ph-action-button-receive-confirm"
              elevation="0"
              @click="validationDialog = false"
            >
              Ok, got it.
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <flaggingPrompt @callback="confirm" />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { toMoney, trim, renderToast } from '@/utils'
import { format, parseISO } from 'date-fns'
import numeral from 'numeral'
// import VueCookies from 'vue-cookies'
import wacomMixin from '@/mixins/wacom.js'
import wacomTransaction from '@/components/wacom/wacomTransaction'
import wacomConsent from '@/components/wacom/wacomConsent'
import flaggingPrompt from '@/components/transactionLimit/flaggingPrompt.vue'
import wacomDataConsentFirst from '@/components/wacom/wacomDataConsentFirst'
import wacomDataConsentSecond from '@/components/wacom/wacomDataConsentSecond'
import wacomDataConsentThird from '@/components/wacom/wacomDataConsentThird'
import wacomDataConsentFourth from '@/components/wacom/wacomDataConsentFourth'
import wacomDataConsentFifth from '@/components/wacom/wacomDataConsentFifth'
import wacomDataConsentSixth from '@/components/wacom/wacomDataConsentSixth'
import wacomDataConsentSeventh from '@/components/wacom/wacomDataConsentSeventh'
import { validateWuSend } from '@/utils/validations/wu'

export default {
  mixins: wacomMixin,
  components: {
    wacomTransaction,
    wacomConsent,
    flaggingPrompt,
    wacomDataConsentFirst,
    wacomDataConsentSecond,
    wacomDataConsentThird,
    wacomDataConsentFourth,
    wacomDataConsentFifth,
    wacomDataConsentSixth,
    wacomDataConsentSeventh
  },
  computed: {
    ...mapGetters({
      payoutCountryList: 'send/payoutCountryList',
      transactionData: 'send/transactionData',
      feeInquiryData: 'send/feeInquiryData',
      kycLookupData: 'send/kycLookupData',
      validateData: 'send/validateData',
      selectedCustomer: 'customers/selectedCustomer',
      collections: 'collections',
      currUser: 'auth/currUser',
      remoteData: 'obo/remoteData',
      remcos: 'nonex/remcos',
      receipt: 'receipt',
      consentValues: 'wacom/consentValues',
      riskData: 'compliance/riskData',
      countryID: 'send/phID',
      seriesDetail: 'manualReceipt/seriesDetail'
    }),
    summaryData () {
      const fullData = this.transactionData
      const summary = {
        remcoName: { text: 'Provider', value: null },
        senderName: { text: 'Sender Name', value: null },
        senderBirthDate: { text: 'Sender Birthdate', value: null },
        receiverName: { text: 'Receiver Name', value: null },
        destinationCountryName: { text: 'Destination', value: null },
        exchangeRate: { text: 'Exchange Rate', value: null },
        sendAmount: { text: 'Send Amount', value: null },
        serviceFee: { text: 'Transfer Fee', value: null },
        messageCharge: { text: 'Message Charge', value: null },
        receiveAmount: { text: 'Pay Amount', value: null },
        discountAmount: { text: 'Discount', value: null },
        totalAmount: { text: 'Total Amount', value: null }
      }

      const fields = Object.keys(summary)

      fields.forEach(field => {
        switch (field) {
          case 'senderName':
            if (fullData.senderFirstName && fullData.senderLastName) {
              summary[field].value = `${fullData.senderLastName}, ${fullData.senderFirstName}`
            }
            break
          case 'receiverName':
            if (fullData.receiverFirstName && fullData.receiverLastName) {
              summary[field].value = `${fullData.receiverLastName}, ${fullData.receiverFirstName}`
            }
            break
          case 'receiveAmount':
            if (fullData[field]) {
              summary[field].value = this.formatAmount(fullData[field], fullData.destinationCountryCurrency)
            }
            break
          case 'sendAmount':
          case 'serviceFee':
          case 'totalAmount':
            if (fullData[field]) {
              summary[field].value = this.formatAmount(fullData[field])
            }
            break
          case 'messageCharge':
          case 'discountAmount':
            if (fullData[field]) {
              summary[field].value = this.formatAmount(fullData[field])
            } else {
              delete summary[field]
            }
            break
          default:
            if (fullData[field]) {
              summary[field].value = fullData[field]
            }
            break
        }
      })

      return Object.values(summary)
    },
    isCompleteForm () {
      const {
        destinationCountyID,
        sendAmount,
        remcoID,
        serviceFee,
        receiverFirstName,
        receiverLastName,
        agentVerifiedData
      } = this.transactionData

      return String(destinationCountyID) &&
        Number(sendAmount) > 0 &&
        String(remcoID) &&
        String(serviceFee) &&
        receiverFirstName &&
        receiverLastName &&
        agentVerifiedData
    },
    hasDiscount () {
      const { discountAmount } = this.transactionData

      return (discountAmount && Number(discountAmount) > 0)
    },
    customerID () {
      let img = this.selectedCustomer.img || '{}'
      img = JSON.parse(img)
      return Object.values(img)
    },
    remcoData () {
      const remcoData = this.remcos.data.filter(data => data.id === this.transactionData.remcoID)

      if (remcoData.length === 1) return remcoData[0]
      return null
    }
  },
  watch: {
    customerSignature: {
      handler (getState) {
        if (this.currUser.has_wacom && this.transactionData.remcoID === 0) {
          console.log(getState, 'signature change')
          this.generatePrePrint()
        }
      },
      deep: true
    }
  },
  data () {
    return {
      isDonePreprint: false,
      wuPayload: null,
      nonexPayload: null,
      otpDialog: false,
      isOTPLoading: false,
      otpMessage: '',
      otp: '',
      idTypeInWords: '',
      customerSignaturesReference: [],
      customerSignature: '',
      idExpiryDateNoFormat: '',
      idNumber: '',
      terminalId: '',
      operatorId: '',
      locationId: '',
      withoutEmailPrompt: false,
      withEmailPrompt: false,
      sendViaEmail: true,
      hasCustomerDataConsent: false,
      validationDialog: false,
      validationMessage: '',
      manualConfirmPrompt: false
    }
  },
  methods: {
    renderToast,
    validateWuSend,
    async doRiskProfiling () {
      if (
        this.currUser.cisrat === 1 && this.transactionData.remcoID === 0 &&
        String(this.selectedCustomer.edd_upload_status) !== '3'
      ) {
        const [sourceOfFund] = this.collections.sourceOfFund.filter(item => item.source_of_funds === this.selectedCustomer.source_fund)
        const [purposeOfTransaction] = this.collections.purposeOfTransaction.filter(item => item.purpose === this.selectedCustomer.purpose_transaction)
        const [relationship] = this.collections.relationship.filter(item => item.relationship === this.selectedCustomer.relation_to)
        const [occupation] = this.collections.occupation.filter(item => item.occupation_value === this.selectedCustomer.occupation)

        this.$store.commit('compliance/UPDATE_RISK_DATA', {
          spro: {
            source_score: sourceOfFund?.risk_score || '',
            purpose_score: purposeOfTransaction?.risk_score || '',
            relationship_score: relationship?.risk_score || '',
            occupation_score: occupation?.risk_score || ''
          }
        })

        const payload = {
          trans_details: {
            dom_int: (this.transactionData.destinationCountryID === this.countryID) ? 'DMT' : 'IMT',
            source_fund: sourceOfFund ? String(sourceOfFund.id) : '',
            purpose_transaction: purposeOfTransaction ? String(purposeOfTransaction.id) : '',
            relation_to: relationship ? String(relationship.id) : '',
            occupation: occupation ? String(occupation.id) : '',
            wu_compliance: `{"edd_upload_status": "${this.selectedCustomer.edd_upload_status}"}`,
            principal_amount: (this.transactionData.fixedAmountFlag === 'F') ? this.transactionData.receiveAmount : this.transactionData.sendAmount,
            wu_country_code_paid: this.transactionData.destinationCountryCode || 'PH'
          }
        }

        if (this.riskData.spro) payload.spro = JSON.stringify(this.riskData.spro)
        if (this.riskData.getSum) payload.tf = JSON.stringify(this.riskData.getSum)
        if (this.riskData.searchPNA) payload.pna = JSON.stringify(this.riskData.searchPNA)

        const { code, result } = await this.$store.dispatch('compliance/riskProfiling', payload)

        if (code === 200) {
          let eddStatus
          const reasons = []

          this.$store.commit('compliance/UPDATE_RISK_DATA', { riskProfiling: result })

          if (result.risk_criteria) {
            const riskCriteria = result.risk_criteria

            riskCriteria.forEach(item => {
              if (item.criteria !== 'Fraudsters' && item.criteria !== 'Negative List' && item.criteria !== 'PEP') {
                reasons.push(item.criteria)
              }
            })
          }

          if (String(this.selectedCustomer.edd_upload_status) === '1') eddStatus = 'Need to Upload Documents'
          if (String(this.selectedCustomer.edd_upload_status) === '2') eddStatus = 'Documents under review for compliance'
          if (String(this.selectedCustomer.edd_upload_status) === '4') eddStatus = 'Not Approve/Pending Documents'

          if (String(result.risk_score) === '3' || String(result.risk_score) === '4') {
            await this.$store.dispatch('compliance/saveRisk', {
              trans_details: {
                dom_int: (this.transactionData.destinationCountryID === this.countryID) ? 'DMT' : 'IMT',
                trx_type: 'WU Sendout',
                trx_date: this.currUser.trx_date,
                customer_name: this.selectedCustomer.full_name,
                risk_score: result.risk_score,
                curr_risk_score: this.selectedCustomer.risk_score,
                risk_criteria: (result.risk_criteria) ? JSON.stringify(result.risk_criteria) : '[]',
                customer_id: this.selectedCustomer.customer_id,
                monitoring_type: '1',
                principal_amount: (this.transactionData.fixedAmountFlag === 'F') ? this.transactionData.receiveAmount : this.transactionData.sendAmount
              },
              location_name: this.currUser.location_name,
              location_id: this.currUser.location_id,
              user_id: this.currUser.user_id,
              service_id: '1',
              update_status_by: this.currUser.user_id,
              first_name: this.selectedCustomer.first_name,
              last_name: this.selectedCustomer.last_name,
              compliance_remarks: this.selectedCustomer.compliance_edd_remarks,
              fp_remarks: '',
              false_positive: '',
              edd_alert: 'true',
              _is_not_fraud: '0',
              criteria: (result.risk_criteria) ? JSON.stringify(result.risk_criteria) : '[]',
              reference_no: '',
              actual_date_trx: this.currUser.trx_date
            })
          }

          if (String(result.risk_score) === '4') {
            this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
              isShow: true,
              type: 'FOUND_HIGH_RISK_STOP',
              details: {
                status: eddStatus,
                remarks: this.selectedCustomer.compliance_edd_remarks,
                reasons: reasons.join(', ')
              },
              customer: this.selectedCustomer
            })
            this.$router.push('/pera-remit/send')
            return true
          }
          if (String(result.risk_score) === '3') {
            this.$store.commit('compliance/UPDATE_HIGH_RISK_DIALOG', {
              isShow: true,
              type: 'FOUND_HIGH_RISK_GO',
              details: {
                status: eddStatus,
                remarks: this.selectedCustomer.compliance_edd_remarks,
                reasons: reasons.join(', ')
              },
              customer: this.selectedCustomer
            })
          }
          this.preprint()
        } else {
          renderToast('error', 'API Error', 'Failed to risk profiling')
        }
      } else {
        this.preprint()
      }
    },
    flagChecker () {
      if (this.currUser.first_name === this.selectedCustomer.first_name && this.currUser.last_name === this.selectedCustomer.last_name) {
        renderToast('error', 'Blocked Transaction', 'You are not allowed to process this transaction for this customer')
        return false
      }

      if (this.currUser.last_name === this.selectedCustomer.last_name) {
        this.$store.commit('transactionLimit/SET_FLAGGED_TRANSACTION_PAYLOAD', {
          location_id: this.currUser.location_id,
          user_id: this.currUser.user_id,
          customer_id: this.selectedCustomer.customer_id,
          service_name: 'REMITTANCE',
          service_type: 'OUTBOUND',
          provider_name: this.transactionData.remcoName,
          provider_code: (this.transactionData.remcoID === 0) ? 'WU' : this.transactionData.remcoID
        })
        this.$store.commit('transactionLimit/SET_SHOW_FLAGGED_PROMPT_DIALOG', true)
      } else {
        this.confirm()
      }
    },
    openOTPDialog () {
      this.withoutEmailPrompt = false
      this.withEmailPrompt = false
      this.otpDialog = true
    },
    async proceedTransaction () {
      if (this.currUser.has_wacom && this.transactionData.remcoID !== 0) {
        this.eSignatureNonex()
      } else {
        this.openOTPDialog()
      }
    },
    checkRemcoLimit () {
      var remco = this.transactionData.remcoID
      var filtered = this.remcos.data.filter(function (e) {
        return remco === e.id
      })
      return filtered[0].sendout_limit
    },
    checkEmail () {
      if (this.transactionData.remcoID !== 0) {
        var limit = this.checkRemcoLimit()
        console.log(limit)
        if (parseInt(this.transactionData.sendAmount) > parseInt(limit)) {
          renderToast('error', 'Transaction Limit', `Transaction Amount ${this.formatAmount(this.transactionData.sendAmount)} is over ${this.transactionData.remcoName}'s limit`)
        } else {
          if (this.currUser.location_type === '1') {
            if (this.currUser.receipt_type === 3) {
              this.sendViaEmail = true
              if (this.selectedCustomer.email_add) {
                this.withoutEmailPrompt = false
                this.withEmailPrompt = true
              } else {
                this.withoutEmailPrompt = true
                this.withEmailPrompt = false
                this.sendViaEmail = false
              }
            } else {
              this.withoutEmailPrompt = false
              this.withEmailPrompt = false
              this.sendViaEmail = false
              this.manualConfirmPrompt = true
            }
          } else {
            this.proceedTransaction()
          }
        }
      } else {
        if (this.currUser.location_type === '1') {
          if (this.currUser.receipt_type === 3) {
            this.sendViaEmail = true
            if (this.selectedCustomer.email_add) {
              this.withoutEmailPrompt = false
              this.withEmailPrompt = true
            } else {
              this.withoutEmailPrompt = true
              this.withEmailPrompt = false
              this.sendViaEmail = false
            }
          } else {
            this.withoutEmailPrompt = false
            this.withEmailPrompt = false
            this.sendViaEmail = false
            this.manualConfirmPrompt = true
          }
        } else {
          this.proceedTransaction()
        }
      }
    },
    printReceiptInstead () {
      if (this.currUser.has_wacom && this.transactionData.remcoID !== 0) {
        this.eSignatureNonex()
      } else {
        this.openOTPDialog()
      }
      this.sendViaEmail = false
      // this.openOTPDialog()
    },
    updateDetails (customerDetails) {
      this.$router.push({
        path: `/customers/${customerDetails.customer_number}`,
        query: {
          editable: true,
          persistData: true,
          redirectURL: this.$route.path
        }
      })
    },
    eSignatureNonex () {
      if (this.hasCustomerDataConsent) {
        this.$store.commit('wacom/SET_WACOM_DIALOG', true)
      } else {
        this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
      }
      console.log(this.nonexPayload)
    },
    submitSig (emitted) {
      if (this.transactionData.remcoID === 0) {
        this.customerSignature = emitted
        this.$store.commit('wacom/SET_WACOM_DIALOG', false)
        if (this.hasCustomerDataConsent === false) {
          this.generateConsent()
        }
        this.$store.commit('send/SET_TRANSACTION_DATA', {
          prop: 'customer_signature',
          value: emitted
        })
      } else {
        this.customerSignature = emitted
        if (this.hasCustomerDataConsent === false) {
          this.generateConsent()
        }
        this.$store.commit('wacom/SET_WACOM_DIALOG', false)
        this.openOTPDialog()
      }
    },
    async generateACRNonex (mtcn) {
      const ACRPayload = {}
      const currentAddress = `${this.nonexPayload.current_address.address}, ${this.nonexPayload.current_address.barangay}, ${this.nonexPayload.current_address.city}, ${this.nonexPayload.current_address.province}, ${this.nonexPayload.current_address.country}, ${this.nonexPayload.current_address.zip_code}`
      const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
      ACRPayload.remco_name = this.transactionData.remcoName
      ACRPayload.sender_name = this.nonexPayload.sender_name
      ACRPayload.remco_id = this.nonexPayload.remco_id
      ACRPayload.trx_type = this.nonexPayload.trx_type
      ACRPayload.receiver_name = this.nonexPayload.receiver_name
      ACRPayload.current_address = currentAddress
      ACRPayload.id_type = this.nonexPayload.id_type
      ACRPayload.id_number = this.nonexPayload.id_number
      ACRPayload.contact_number = this.nonexPayload.contact_number
      ACRPayload.purpose_transaction = this.nonexPayload.purpose_transaction
      ACRPayload.control_number = mtcn
      ACRPayload.trx_timestamp = this.formatDateTime(new Date())
      ACRPayload.vlocation_name = this.currUser.location_name
      ACRPayload.currency_name = 'PHP'
      ACRPayload.principal_amount = this.nonexPayload.principal_amount
      ACRPayload.service_charge = this.nonexPayload.service_charge
      ACRPayload.total_amount = this.nonexPayload.total_amount
      ACRPayload.buy_back_amount = this.nonexPayload.buy_back_amount
      ACRPayload.currency_id = this.nonexPayload.currency_id
      ACRPayload.agent_name = agentName
      ACRPayload.location_id = this.currUser.location_id
      ACRPayload.has_wacom = this.currUser.has_wacom
      ACRPayload.crn = this.nonexPayload.client_reference_no
      ACRPayload.uploaded_by = this.currUser.user_id
      ACRPayload.customer_id = this.selectedCustomer.customer_id.toString()
      if (this.currUser.has_wacom === true) {
        ACRPayload.img = this.customerSignature
        ACRPayload.img_fla = this.currUser.userSignature
      }
      await this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
        var decoded = Buffer.from(res.result, 'base64')
        var blob = new Blob([decoded], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(blob)
        window.open(fileURL, '_blank')
      }).catch((error) => {
        console.log(error)
      })
    },
    preprint () {
      if (this.transactionData.remcoID === 0) {
        this.wuValidate()
      } else {
        if (this.hasCustomerDataConsent) {
          this.$store.commit('wacom/SET_WACOM_CONSENT_DIALOG', true)
        } else {
          this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
        }
      }
    },
    async confirm () {
      const { remcoID, sendAmount } = this.transactionData

      try {
        const isProcessable = await this.$store.dispatch('transactionLimit/checkLimit', {
          transactionName: this.transactionData.remcoName,
          payload: {
            customer_id: this.selectedCustomer.customer_id,
            provider_code: (remcoID === 0) ? 'WU' : remcoID,
            service_name: 'REMITTANCE',
            service_type: 'OUTBOUND',
            amount: parseFloat(sendAmount)
          }
        })

        if (isProcessable) {
          if (this.currUser.location_type === '1') {
            if (this.currUser.receipt_type === 3) {
              const getReceipt = await this.$store.dispatch('getReceipt', {
                location_id: this.currUser.location_id,
                type: '2',
                application_id: this.currUser.application_id
              })

              if (getReceipt.code === 200) {
                if (remcoID === 0) {
                  this.wuPayload.form_number = this.receipt.oar_series_no
                  this.wuStore()
                } else {
                  this.nonexInitialize()
                }
              } else {
                renderToast('error', 'Receipt Error', 'Failed to get receipt series data')
              }
            } else {
              const payload = {
                location_id: this.currUser.location_id,
                type: 'OAR'
              }

              await this.$store.dispatch('manualReceipt/getAvailableSeriesNo', payload).then(res => {
                if (res.code >= 200 && res.code <= 299) {
                  if (res.result.length > 0) {
                    if (remcoID === 0) {
                      this.wuPayload.form_number = this.seriesDetail.series_no
                      this.wuStore()
                    } else {
                      this.nonexInitialize()
                    }
                  } else {
                    this.renderToast('error', 'Error', 'No available series number for this branch. Please request from support.')
                  }
                } else {
                  this.renderToast('error', 'Error', res.message)
                }
              })
            }
          } else {
            if (remcoID === 0) {
              this.wuPayload.form_number = ''
              this.wuStore()
            } else {
              this.nonexInitialize()
            }
          }
        }
      } catch (error) {
        renderToast('error', 'Checking Failed', 'Failed to check current limit, please submit a ticket to IT Support')
      }
    },
    async verifyOTP (val) {
      this.otp = val

      if (this.currUser) {
        if (this.transactionData.remcoID === 0 || this.transactionData.remcoID === 9) {
          if (this.transactionData.remcoID === 0) {
            this.confirm()
          } else {
            this.flagChecker()
          }
        } else {
          const payload = {
            user_name: this.currUser.user_name,
            otp_code: this.otp,
            pin: this.currUser.otp_pin,
            module: 'send'
          }

          if (this.otp && this.otp.length === 6) {
            this.isOTPLoading = true
            const OTPVerification = await this.$store.dispatch('auth/verifyOTP', payload)

            if (OTPVerification.result.status && OTPVerification.result.value) {
              this.otpMessage = ''
              this.otp = ''
              this.otpDialog = false
              this.isOTPLoading = false

              if (this.transactionData.remcoID === 0) {
                this.confirm()
              } else {
                this.flagChecker()
              }
            } else {
              this.otpMessage = 'Wrong PIN!'
              this.otp = ''
              this.isOTPLoading = false
            }
          } else {
            this.otpMessage = 'Wrong PIN!'
            this.otp = ''
            this.isOTPLoading = false
          }
        }
      } else {
        this.$store.commit('auth/SET_IS_OTP_PASSED', false)
        this.$store.commit('auth/SET_CURRENT_USER', {})
        this.$store.commit('auth/AUTH_SUCCESS', false)
        window.location.href = `${this.$uspAuthAppURL}/login`
      }
    },
    async ayannahStore () {
      this.nonexPayload.reference_number = this.transactionData.refNo
      this.nonexPayload.control_number = this.transactionData.refNo

      // console.log(this.nonexPayload)
      console.log(this.transactionData)
      const countryDetails = this.collections.countries.filter(item => item.country === this.selectedCustomer.country)

      if (countryDetails.length === 1) {
        this.nonexPayload.country = countryDetails[0].two_iso_code
      }

      console.log(this.nonexPayload, 'ayannah')

      if (this.remcoData) {
        const req = await this.$store.dispatch('nonex/executeSendout', {
          endpoint: this.remcoData.sendout_route,
          payload: this.nonexPayload
        })

        if (!Object.prototype.hasOwnProperty.call(req, 'error')) {
          if (this.currUser.location_type === '1') {
            if (this.currUser.receipt_type === 3) {
              const address = this.selectedCustomer.address
              const barangay = this.selectedCustomer.barangay
              const city = this.selectedCustomer.municipalty
              const province = this.selectedCustomer.province
              const zipCode = this.selectedCustomer.zip_code
              const saveReceipt = await this.$store.dispatch('saveReceipt', {
                ereceipt_id: this.receipt.ereceipt_id,
                application_id: this.currUser.application_id,
                application_header_name: this.currUser.branchDetail.header_name,
                application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                receipt_type: this.receipt.type,
                customer_id: this.selectedCustomer.customer_id,
                customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                customer_tin_no: '',
                customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                cashier_id: this.currUser.user_id,
                cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                oar_receipt_no: this.receipt.oar_receipt_no,
                oar_number: this.receipt.oar_series_no,
                oar_service: 'Ayannah Sendout',
                oar_currency: 'PHP',
                oar_principal_amount: this.nonexPayload.principal_amount,
                oar_discount: 0,
                oar_total: this.nonexPayload.total_amount,
                grand_total: this.nonexPayload.total_amount,
                print_email_hold: 0,
                is_forex_logo: 0
              })

              if (saveReceipt.code === 200) {
                if (saveReceipt.result.receipt_status === 1) {
                  renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                } else if (saveReceipt.result.receipt_status === 2) {
                  const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                    application_id: this.currUser.application_id,
                    ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                  })
                  var newBlob = new Blob([res], { type: 'application/pdf' })
                  var newFileURL = URL.createObjectURL(newBlob)
                  window.open(newFileURL, '_blank')
                } else {
                  renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                }
              } else {
                renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
              }
            } else {
              const payload = {
                id: this.seriesDetail.id,
                status: 2,
                customer_id: this.selectedCustomer.customer_id,
                encoded_by: this.currUser.user_id,
                date_encoded: format(new Date(), 'yyyy-MM-dd'),
                amount: this.nonexPayload.total_amount,
                description: 'Ayannah Sendout',
                remarks: 'Ayannah Sendout'
              }

              // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
              await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
                if (res.code >= 200 && res.code <= 299) {
                  this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
                } else {
                  this.renderToast('error', 'Error', res.message)
                }
              }).catch(err => {
                this.renderToast('error', 'Error', err)
              })
            }
          }

          this.generateACRNonex(req.result.control_number)
          console.log(req.result)
          this.$store.commit('send/SET_STORE_DATA', req.result)
          this.$store.commit('send/SET_CURRENT_PAGE', 2)
          this.$store.commit('obo/SET_REMOTE_DATA', {})
        } else {
          this.renderToast('error', 'Send Money Error', req.message ? req.message : 'Failed to transact send money')
        }
      }
    },
    async usscStore () {
      const payload = {
        panalokard: '',
        amount: this.transactionData.sendAmount,
        ussc_promo: '',
        branch_code: this.currUser.location_code.padStart(5, '0')
        // additional for OTP
        // user_name: this.currUser.user_name,
        // otp_code: this.otp,
        // pin: this.currUser.otp_pin,
        // module: 'send',
        // partner_code: 'USP'
      }

      const req = await this.$store.dispatch('nonex/executeSendout', { endpoint: 'ussc/fee-inquiry', payload: payload })
      // const req = await this.$store.dispatch('nonex/USSCVerification', payload)

      if (req.code === 200) {
        this.nonexPayload.reference_number = req.result.reference_number
        this.nonexPayload.control_number = req.result.reference_number

        if (this.remcoData) {
          const req = await this.$store.dispatch('nonex/executeSendout', {
            endpoint: this.remcoData.sendout_route,
            payload: this.nonexPayload
          })

          if (!Object.prototype.hasOwnProperty.call(req, 'errors')) {
            if (this.currUser.location_type === '1') {
              if (this.currUser.receipt_type === 3) {
                const address = this.selectedCustomer.address
                const barangay = this.selectedCustomer.barangay
                const city = this.selectedCustomer.municipalty
                const province = this.selectedCustomer.province
                const zipCode = this.selectedCustomer.zip_code
                const saveReceipt = await this.$store.dispatch('saveReceipt', {
                  ereceipt_id: this.receipt.ereceipt_id,
                  application_id: this.currUser.application_id,
                  application_header_name: this.currUser.branchDetail.header_name,
                  application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                  receipt_type: this.receipt.type,
                  customer_id: this.selectedCustomer.customer_id,
                  customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                  customer_tin_no: '',
                  customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                  customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                  cashier_id: this.currUser.user_id,
                  cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                  oar_receipt_no: this.receipt.oar_receipt_no,
                  oar_number: this.receipt.oar_series_no,
                  oar_service: 'USSC Sendout',
                  oar_currency: 'PHP',
                  oar_principal_amount: this.nonexPayload.principal_amount,
                  oar_discount: 0,
                  oar_total: this.nonexPayload.total_amount,
                  grand_total: this.nonexPayload.total_amount,
                  print_email_hold: 0,
                  is_forex_logo: 0
                })

                if (saveReceipt.code === 200) {
                  if (saveReceipt.result.receipt_status === 1) {
                    renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                  } else if (saveReceipt.result.receipt_status === 2) {
                    const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                      application_id: this.currUser.application_id,
                      ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                    })
                    var newBlob = new Blob([res], { type: 'application/pdf' })
                    var newFileURL = URL.createObjectURL(newBlob)
                    window.open(newFileURL, '_blank')
                  } else {
                    renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                  }
                } else {
                  renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
                }
              } else {
                const payload = {
                  id: this.seriesDetail.id,
                  status: 2,
                  customer_id: this.selectedCustomer.customer_id,
                  encoded_by: this.currUser.user_id,
                  date_encoded: format(new Date(), 'yyyy-MM-dd'),
                  amount: this.nonexPayload.total_amount,
                  description: 'USSC Sendout',
                  remarks: 'USSC Sendout'
                }

                // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
                await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
                  if (res.code >= 200 && res.code <= 299) {
                    this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
                  } else {
                    this.renderToast('error', 'Error', res.message)
                  }
                }).catch(err => {
                  this.renderToast('error', 'Error', err)
                })
              }
            }

            await this.generateACRNonex(req.result.control_number)
            this.$store.commit('send/SET_STORE_DATA', req.result)
            this.$store.commit('send/SET_CURRENT_PAGE', 2)
            this.$store.commit('obo/SET_REMOTE_DATA', {})
          } else {
            this.renderToast('error', 'Send Money Error', req.message ? req.message : 'Failed to transact send money')
          }
        }
      } else {
        this.renderToast('error', 'USSC Error', req.message)
        this.isOTPLoading = false
        this.otp = ''
        this.otpDialog = false
      }
    },
    async cebuanaStore () {
      console.log(this.nonexPayload, 'cebuana')
      // const req = await this.$store.dispatch('nonex/executeSendout', {
      //   endpoint: 'cebuana/send',
      //   payload: this.nonexPayload
      // })
      const req = await this.$store.dispatch('nonex/cebuanaVerification', this.nonexPayload)

      if (!Object.prototype.hasOwnProperty.call(req, 'error')) {
        if (this.currUser.location_type === '1') {
          if (this.currUser.receipt_type === 3) {
            const address = this.selectedCustomer.address
            const barangay = this.selectedCustomer.barangay
            const city = this.selectedCustomer.municipalty
            const province = this.selectedCustomer.province
            const zipCode = this.selectedCustomer.zip_code
            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.selectedCustomer.customer_id,
              customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
              customer_tin_no: '',
              customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
              customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              oar_receipt_no: this.receipt.oar_receipt_no,
              oar_number: this.receipt.oar_series_no,
              oar_service: 'Cebuana Sendout',
              oar_currency: 'PHP',
              oar_principal_amount: this.nonexPayload.principal_amount,
              oar_discount: 0,
              oar_total: this.nonexPayload.total_amount,
              grand_total: this.nonexPayload.total_amount,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
          } else {
            const payload = {
              id: this.seriesDetail.id,
              status: 2,
              customer_id: this.selectedCustomer.customer_id,
              encoded_by: this.currUser.user_id,
              date_encoded: format(new Date(), 'yyyy-MM-dd'),
              amount: this.nonexPayload.total_amount,
              description: 'Cebuana Sendout',
              remarks: 'Cebuana Sendout'
            }

            // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
            await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
              if (res.code >= 200 && res.code <= 299) {
                this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
              } else {
                this.renderToast('error', 'Error', res.message)
              }
            }).catch(err => {
              this.renderToast('error', 'Error', err)
            })
          }
        }

        this.generateACRNonex(req.result.ControlNumber)
        this.$store.commit('send/SET_STORE_DATA', req.result)
        this.$store.commit('send/SET_CURRENT_PAGE', 2)
        this.$store.commit('obo/SET_REMOTE_DATA', {})
      } else {
        this.renderToast('error', 'Send Money Error', req.message ? req.message : 'Failed to transact send money')
        this.isOTPLoading = false
        this.otp = ''
        this.otpDialog = false
      }
    },
    async smartPadalaStore () {
      let idType = ''
      let idNumber = ''
      let idExpiryDate = ''

      if (this.customerID.length > 0) {
        idType = this.customerID[0].id_type
        idNumber = this.customerID[0].id_number
        idExpiryDate = this.customerID[0].expiry
      }
      const payload = {
        countryOfOrigin: 'PHL',
        senderFirstName: this.transactionData.senderFirstName,
        senderMiddleName: this.transactionData.senderMiddleName,
        senderLastName: this.transactionData.senderLastName,
        type: idType,
        number: idNumber,
        expiryDate: idExpiryDate,
        mobileNumber: this.selectedCustomer.mobile_no,
        nationality: this.selectedCustomer.nationality,
        birthDate: this.selectedCustomer.birth_date,
        placeOfBirth: this.selectedCustomer.birth_place,
        senderPermanentHouseNumber: '',
        senderPermanentStreet: this.selectedCustomer.street1,
        senderPermanentBarangay: this.selectedCustomer.barangay1,
        senderPermanentCity: this.selectedCustomer.city1,
        senderPermanentProvince: this.selectedCustomer.province1,
        senderPermanentZipCode: this.selectedCustomer.zip_code1,
        senderPermanentCountry: this.selectedCustomer.country1,
        senderPresentHouseNumber: '',
        senderPresentStreet: this.selectedCustomer.address,
        senderPresentBarangay: this.selectedCustomer.barangay,
        senderPresentCity: this.selectedCustomer.municipality,
        senderPresentProvince: this.selectedCustomer.province,
        senderPresentZipCode: this.selectedCustomer.zip_code,
        senderPresentCountry: this.selectedCustomer.country,
        sourceOfIncome: this.selectedCustomer.source_fund,
        occupation: this.selectedCustomer.occupation,
        receiverFirstName: this.transactionData.receiverFirstName,
        receiverMiddleName: this.transactionData.receiverMiddleName,
        receiverLastName: this.transactionData.receiverLastName,
        receiverHouseNumber: '',
        receiverStreet: '',
        receiverBarangay: '',
        receiverCity: '',
        receiverProvince: '',
        receiverZipCode: '',
        receiverCountry: '',
        // account: this.transactionData.receiverAccountNumber,
        account: '5299676033414109',
        channel: 'smny',
        amount: this.transactionData.sendAmount,
        currency: 'PHP',
        plocation_id: this.locationId,
        vuser_id: this.operatorId
      }

      const req = await this.$store.dispatch('nonex/executeSendout', { endpoint: 'smartpadala/create', payload: payload })

      if (req.code === 200) {
        if (this.remcoData) {
          const req = await this.$store.dispatch('nonex/executeSendout', {
            endpoint: this.remcoData.sendout_route,
            payload: this.nonexPayload
          })

          if (!Object.prototype.hasOwnProperty.call(req, 'error')) {
            if (this.currUser.location_type === '1') {
              if (this.currUser.receipt_type === 3) {
                const address = this.selectedCustomer.address
                const barangay = this.selectedCustomer.barangay
                const city = this.selectedCustomer.municipalty
                const province = this.selectedCustomer.province
                const zipCode = this.selectedCustomer.zip_code
                const saveReceipt = await this.$store.dispatch('saveReceipt', {
                  ereceipt_id: this.receipt.ereceipt_id,
                  application_id: this.currUser.application_id,
                  application_header_name: this.currUser.branchDetail.header_name,
                  application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
                  receipt_type: this.receipt.type,
                  customer_id: this.selectedCustomer.customer_id,
                  customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
                  customer_tin_no: '',
                  customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
                  customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
                  cashier_id: this.currUser.user_id,
                  cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
                  oar_receipt_no: this.receipt.oar_receipt_no,
                  oar_number: this.receipt.oar_series_no,
                  oar_service: 'Smart Padala Sendout',
                  oar_currency: 'PHP',
                  oar_principal_amount: this.nonexPayload.principal_amount,
                  oar_discount: 0,
                  oar_total: this.nonexPayload.total_amount,
                  grand_total: this.nonexPayload.total_amount,
                  print_email_hold: 0,
                  is_forex_logo: 0
                })

                if (saveReceipt.code === 200) {
                  if (saveReceipt.result.receipt_status === 1) {
                    renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
                  } else if (saveReceipt.result.receipt_status === 2) {
                    const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                      application_id: this.currUser.application_id,
                      ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                    })
                    var newBlob = new Blob([res], { type: 'application/pdf' })
                    var newFileURL = URL.createObjectURL(newBlob)
                    window.open(newFileURL, '_blank')
                  } else {
                    renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
                  }
                } else {
                  renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
                }
              } else {
                const payload = {
                  id: this.seriesDetail.id,
                  status: 2,
                  customer_id: this.selectedCustomer.customer_id,
                  encoded_by: this.currUser.user_id,
                  date_encoded: format(new Date(), 'yyyy-MM-dd'),
                  amount: this.nonexPayload.total_amount,
                  description: 'Smart Padala Sendout',
                  remarks: 'Smart Padala Sendout'
                }

                // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
                await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
                  if (res.code >= 200 && res.code <= 299) {
                    this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
                  } else {
                    this.renderToast('error', 'Error', res.message)
                  }
                }).catch(err => {
                  this.renderToast('error', 'Error', err)
                })
              }
            }

            this.generateACRNonex()
            this.$store.commit('send/SET_STORE_DATA', req.result)
            this.$store.commit('send/SET_CURRENT_PAGE', 2)
            this.$store.commit('obo/SET_REMOTE_DATA', {})
          } else {
            this.renderToast('error', 'Send Money Error', req.message ? req.message : 'Failed to transact send money')
          }
        }
      } else {
        this.renderToast('error', 'Send Money Error', 'Smart padala create failed')
      }
    },
    nonexInitialize () {
      let idType = ''
      let idNumber = ''

      if (this.customerID.length > 0) {
        const idTypes = this.collections.idList.filter(item => item.id_type === this.customerID[0].id_type)

        if (idTypes.length > 0) idType = idTypes[0].id_value
        else idType = this.customerID[0].id_type

        idNumber = this.customerID[0].id_number
      }

      this.nonexPayload = {
        remco_id: this.transactionData.remcoID,
        form_type: (this.currUser.location_type === '1' ? 'OAR' : '0'),
        form_number: (this.currUser.location_type === '1' ? (this.currUser.receipt_type === 3 ? this.receipt.oar_series_no : this.seriesDetail.series_no) : '0'),
        total_amount: this.transactionData.totalAmount,
        service_charge: this.transactionData.serviceFee,
        principal_amount: this.transactionData.sendAmount,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        location_name: this.currUser.location_name,
        trx_date: this.currUser.trx_date,
        customer_id: this.selectedCustomer.customer_id,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.last_name}`,
        remote_location_id: this.currUser.location_id,
        remote_ip_address: this.currUser.ip_address,
        ip_address: this.currUser.ip_address,
        remote_user_id: this.currUser.user_id,
        purpose_transaction: this.selectedCustomer.purpose_transaction,
        source_fund: this.selectedCustomer.source_fund,
        occupation: this.selectedCustomer.occupation,
        relation_to: this.selectedCustomer.relation_to,
        birth_date: this.selectedCustomer.birth_date,
        birth_place: this.selectedCustomer.birth_place,
        birth_country: this.selectedCustomer.country_birth,
        id_type: idType,
        id_number: idNumber,
        address: this.selectedCustomer.address,
        barangay: this.selectedCustomer.barangay,
        city: this.selectedCustomer.municipality,
        province: this.selectedCustomer.province,
        zip_code: this.selectedCustomer.zip_code,
        country: this.selectedCustomer.country,
        contact_number: this.selectedCustomer.mobile_no,
        current_address: {
          address: this.selectedCustomer.address,
          barangay: this.selectedCustomer.barangay,
          city: this.selectedCustomer.municipality,
          province: this.selectedCustomer.province,
          country: this.selectedCustomer.country,
          zip_code: this.selectedCustomer.zip_code
        },
        permanent_address: {
          address: this.selectedCustomer.street1,
          barangay: this.selectedCustomer.barangay1,
          city: this.selectedCustomer.city1,
          province: this.selectedCustomer.province1,
          country: this.selectedCustomer.country1,
          zip_code: this.selectedCustomer.zip_code1
        },
        sender_name: this.selectedCustomer.full_name,
        receiver_name: `${this.transactionData.receiverLastName}, ${this.transactionData.receiverFirstName}`,
        branch_code: this.currUser.location_code.padStart(5, '0'),
        buy_back_amount: '0',
        mc_rate_id: '0',
        mc_rate: '0',
        rate_category: 'N/A',
        trx_type: '1',
        is_domestic: '1',
        dst_amount: '0',
        originating_country: 'PH',
        destination_country: 'PH',
        risk_score: '1',
        risk_criteria: '{}',
        payout_type: '1',
        currency_id: '1',
        client_reference_no: this.transactionData.refNo,
        // additional for OTP
        user_name: this.currUser.user_name,
        otp_code: this.otp,
        pin: this.currUser.otp_pin,
        module: 'send',
        partner_code: 'USP'
      }

      if (this.transactionData.remcoID === 9) {
        this.nonexPayload = {
          ...this.nonexPayload,
          currency_id: 1,
          trx_type: 1,
          is_domestic: 1,
          dst_amount: 0,
          originating_country: 'PH',
          destination_country: 'PH',
          risk_score: 1,
          risk_criteria: '{}',
          form_type: 'OAR',
          payout_type: 1,
          control_number: '',
          reference_number: '',
          send_currency_id: '6',
          beneficiary_id: this.transactionData.beneficiaryID,
          agent_code: this.currUser.location_code
          // agent_code: '01030063'
        }
      }

      if (this.transactionData.remcoID === 10 || this.transactionData.remcoID === 22) {
        this.nonexPayload = {
          ...this.nonexPayload,
          sender_first_name: this.transactionData.senderFirstName,
          sender_middle_name: this.transactionData.senderMiddleName,
          sender_last_name: this.transactionData.senderLastName,
          receiver_first_name: this.transactionData.receiverFirstName,
          receiver_middle_name: this.transactionData.receiverMiddleName,
          receiver_last_name: this.transactionData.receiverLastName,
          receiver_contact_number: this.transactionData.receiverMobileNumber
        }
      }

      if (this.transactionData.remcoID === 3) this.smartPadalaStore()
      if (this.transactionData.remcoID === 9) this.cebuanaStore()
      if (this.transactionData.remcoID === 10) this.usscStore()
      if (this.transactionData.remcoID === 22) this.ayannahStore()

      this.otp = ''
      this.isOTPLoading = false
    },

    async wuStore () {
      this.wuPayload.addl_service_charges = this.validateData.service_code.addl_service_charges
      this.wuPayload.compliance_data_buffer = this.validateData.compliance.compliance_data_buffer
      this.wuPayload.originating_city = this.validateData.payment_details.originating_city
      this.wuPayload.originating_state = this.validateData.payment_details.originating_state
      // this.wuPayload.financials = this.validateData.financials
      this.wuPayload.financials = {
        charges: this.feeInquiryData.charges,
        destination_principal_amount: this.feeInquiryData.destination_principal_amount,
        gross_total_amount: this.feeInquiryData.gross_total_amount,
        message_charge: this.feeInquiryData.message_charge,
        originators_principal_amount: this.feeInquiryData.originators_principal_amount,
        plus_charges_amount: this.feeInquiryData.plus_charges_amount,
        taxes: this.feeInquiryData.taxes
      }
      this.wuPayload.promotions = this.validateData.promotions
      this.wuPayload.mtcn = this.validateData.new_details.mtcn
      this.wuPayload.new_mtcn = this.validateData.new_details.new_mtcn
      this.wuPayload.exchange_rate = Number(this.feeInquiryData.exchange_rate)
      this.wuPayload.otp = this.otp
      this.wuPayload.compliance_details = {
        id_details: {
          id_type: this.wuPayload.id_type,
          id_country_of_issue: this.wuPayload.id_country_of_issue,
          id_number: this.wuPayload.id_number
        },
        id_issue_date: this.wuPayload.id_issue_date,
        id_expiration_date: this.wuPayload.id_expiration_date,
        date_of_birth: this.wuPayload.date_of_birth,
        occupation: this.wuPayload.occupation,
        transaction_reason: this.wuPayload.Purpose_of_Transaction,
        Country_of_Birth: this.wuPayload.Country_of_Birth,
        nationality: this.wuPayload.nationality,
        Source_of_Funds: this.wuPayload.Source_of_Funds,
        Current_address: {
          addr_line1: this.wuPayload.sender_addr_line1,
          addr_line2: this.wuPayload.sender_addr_line2,
          city: this.wuPayload.sender_city,
          state_name: this.wuPayload.sender_state,
          postal_code: this.wuPayload.sender_postal_code,
          country: this.wuPayload.sender_addr_country_name
        },
        Relationship_to_Receiver_Sender: this.wuPayload.Relationship_to_Receiver,
        I_act_on_My_Behalf: this.wuPayload.is_on_behalf,
        galactic_id: this.wuPayload.galactic_id,
        employment_position_level: this.wuPayload.employment_position_level
      }
      this.wuPayload.client_reference_no = this.transactionData.refNo
      this.wuPayload.wu_trx_category = this.transactionData.deliveryService.value
      this.wuPayload.remote_location_id = this.currUser.location_id
      this.wuPayload.trx_date = this.currUser.trx_date
      this.wuPayload.currency_id = '1'
      this.wuPayload.client_ip = this.currUser.ip_address
      this.wuPayload.customer_id = this.selectedCustomer.customer_id
      this.wuPayload.customer_name = this.selectedCustomer.full_name
      this.wuPayload.dom_int = (this.transactionData.destinationCountryID === this.countryID) ? 'DMT' : 'IMT'
      this.wuPayload.risk_score = this.selectedCustomer.risk_score
      this.wuPayload.risk_criteria = this.selectedCustomer.risk_criteria
      this.wuPayload.message = JSON.stringify(this.wuPayload.message)
      // additional for OTP
      this.wuPayload.user_name = this.currUser.user_name
      this.wuPayload.otp_code = this.otp
      this.wuPayload.pin = this.currUser.otp_pin
      this.wuPayload.module = 'send'
      this.wuPayload.partner_code = 'USP'

      const toExclude = [
        'sender_addr_line1',
        'sender_addr_line2',
        'sender_city',
        'sender_state',
        'sender_postal_code',
        'sender_addr_country_name',
        'id_type',
        'id_country_of_issue',
        'id_number',
        'occupation',
        'country_birth',
        'nationality',
        'Source_of_Funds',
        'Sender_Employer',
        'Relationship_to_Receiver',
        'GEN_III_Indicator',
        'ack_flag',
        'employment_position_level',
        'is_on_behalf',
        'galactic_id',
        'gender'
      ]

      toExclude.map(prop => delete this.wuPayload[prop])

      const selectedSerivce = this.transactionData.deliveryService.value
      const deliveryServiceType = this.collections.deliveryServiceTypes.filter(function (e) {
        return e.value === selectedSerivce
      })

      console.log(this.wuPayload)

      // const req = await this.$store.dispatch('send/sendStore', this.wuPayload)
      const req = await this.$store.dispatch('nonex/wuVerification', this.wuPayload)

      if (req.code === 200) {
        if (this.currUser.location_type === '1') {
          if (this.currUser.receipt_type === 3) {
            const address = this.selectedCustomer.address
            const barangay = this.selectedCustomer.barangay
            const city = this.selectedCustomer.municipalty
            const province = this.selectedCustomer.province
            const zipCode = this.selectedCustomer.zip_code
            const saveReceipt = await this.$store.dispatch('saveReceipt', {
              ereceipt_id: this.receipt.ereceipt_id,
              application_id: this.currUser.application_id,
              application_header_name: this.currUser.branchDetail.header_name,
              application_header_vat_reg_tin: this.currUser.branchDetail.header_vat_reg_tin,
              receipt_type: this.receipt.type,
              customer_id: this.selectedCustomer.customer_id,
              customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
              customer_tin_no: '',
              customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
              customer_email_address: (this.sendViaEmail) ? this.selectedCustomer.email_add : null,
              cashier_id: this.currUser.user_id,
              cashier_name: `${this.currUser.first_name} ${this.currUser.last_name}`,
              oar_receipt_no: this.receipt.oar_receipt_no,
              oar_number: this.receipt.oar_series_no,
              oar_service: 'WU Sendout',
              oar_currency: 'PHP',
              oar_principal_amount: (this.transactionData.fixedAmountFlag === 'F') ? this.transactionData.receiveAmount : this.transactionData.sendAmount,
              oar_discount: 0,
              oar_total: this.transactionData.totalAmount,
              grand_total: this.transactionData.totalAmount,
              print_email_hold: 0,
              is_forex_logo: 0
            })

            if (saveReceipt.code === 200) {
              if (saveReceipt.result.receipt_status === 1) {
                renderToast('success', 'e-Receipt Sent', 'Receipt has been sent to customer\'s email')
              } else if (saveReceipt.result.receipt_status === 2) {
                const res = await this.$store.dispatch('eReceipt/downloadEreceipt', {
                  application_id: this.currUser.application_id,
                  ereceipt_api_id: saveReceipt.result.ereceipt_api_id
                })
                var newBlob = new Blob([res], { type: 'application/pdf' })
                var newFileURL = URL.createObjectURL(newBlob)
                window.open(newFileURL, '_blank')
              } else {
                renderToast('error', 'Receipt Error', 'The receipt is already void/cancel')
              }
            } else {
              renderToast('error', 'Receipt Error', 'Failed to get save receipt data')
            }
          } else {
            const payload = {
              id: this.seriesDetail.id,
              status: 2,
              customer_id: this.selectedCustomer.customer_id,
              encoded_by: this.currUser.user_id,
              date_encoded: format(new Date(), 'yyyy-MM-dd'),
              amount: this.transactionData.totalAmount,
              description: 'WU Sendout',
              remarks: 'WU Sendout'
            }

            // Generates Manual Receipt with no print. Transaction is automatically saved on reports.
            await this.$store.dispatch('manualReceipt/updateSeriesNo', payload).then(res => {
              if (res.code >= 200 && res.code <= 299) {
                this.renderToast('success', 'Transaction Saved on Record', `Successfully Saved Transaction on Record - OAR Form Number: ${this.seriesDetail.series_no}`)
              } else {
                this.renderToast('error', 'Error', res.message)
              }
            }).catch(err => {
              this.renderToast('error', 'Error', err)
            })
          }
        }

        this.otpMessage = ''
        this.isOTPLoading = false
        this.otpDialog = false
        console.log(req)
        console.log(this.validateData)
        let i = 0
        let j = 0
        let pinMessage = ''
        let promoTextMessage = ''

        for (i; i < req.result.confirmed_details.pin_message.length; i++) {
          if (req.result.confirmed_details.pin_message[i] !== '') {
            pinMessage += req.result.confirmed_details.pin_message[i] + ' '
          }
        }
        for (j; j < req.result.confirmed_details.promo_text_message.length; j++) {
          if (req.result.confirmed_details.promo_text_message[j] !== '') {
            promoTextMessage += req.result.confirmed_details.promo_text_message[j] + ' '
          }
        }
        let wuMessage = ''
        const printReceiptPayload = {}
        if (this.transactionData.message.length === 0) {
          wuMessage = ''
        } else {
          wuMessage = this.transactionData.message[0]
        }

        console.log(this.transactionData.destinationCountryName)

        printReceiptPayload.print_data = {
          sender: this.transactionData.senderFirstName + ' ' + this.transactionData.senderMiddleName + ' ' + this.transactionData.senderLastName,
          address: this.selectedCustomer.address + ' ' + this.selectedCustomer.barangay + ' ' + this.selectedCustomer.municipality + ' ' + this.selectedCustomer.province + ' ' + this.selectedCustomer.zip_code,
          id_type: this.idTypeInWords,
          id_number: this.wuPayload.compliance_details.id_details.id_number,
          test_question: this.transactionData.testQuestion,
          answer: this.transactionData.answer,
          sender_mobile_number: this.selectedCustomer.mobile_no,
          receiver: this.transactionData.receiverFirstName + ' ' + this.transactionData.receiverMiddleName + ' ' + this.transactionData.receiverLastName,
          r_city: this.wuPayload.destination_city_name + ' ' + this.wuPayload.destination_state_code,
          r_country: this.transactionData.destinationCountryName,
          service: deliveryServiceType[0].text,
          message: wuMessage,
          receiver_mobile_number: '',
          mtcn: req.result.confirmed_details.mtcn.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
          date_time: this.formatDateTime(new Date()),
          agent_name: this.currUser.location_name + ' BRANCH',
          amount: this.formatAmountACR((this.feeInquiryData.originators_principal_amount / 100)),
          transfer_fee: this.formatAmountACR((this.validateData.financials.charges / 100)),
          msg_charge: this.formatAmountACR((this.validateData.financials.message_charge / 100)),
          delivery_charge: '0.00',
          promo_code: this.transactionData.promoCode,
          discount: this.transactionData.discountAmount,
          total: 'Philippine Peso ' + this.formatAmountACR((this.feeInquiryData.gross_total_amount / 100)),
          exchange_rate: this.transactionData.exchangeRate,
          payout_amount: this.formatAmount((this.feeInquiryData.destination_principal_amount / 100), this.transactionData.destinationCountryCurrency),
          promo_text_message: promoTextMessage,
          pin_text_message_set_2: pinMessage,
          want_to_register: '',
          mywu_number: '',
          mywu_points: req.result.confirmed_details.new_points_earned ? req.result.confirmed_details.new_points_earned : '',
          total_mywu_points: '',
          adj_type: '',
          mc_rate: '',
          buyback_amount: '',
          total_amount: '',
          purpose_of_trxn: this.selectedCustomer.purpose_transaction,
          promo_flag: 'N',
          expiration_date: '',
          vuser_group_id: this.currUser.user_group_id
        }
        if (this.kycLookupData && this.kycLookupData.customer && this.kycLookupData.customer.mywu_details) {
          printReceiptPayload.print_data.mywu_number = this.kycLookupData.customer.mywu_details.mywu_number
          printReceiptPayload.print_data.total_mywu_points = parseInt(this.kycLookupData.customer.mywu_details.current_yr_pts) + parseInt(req.result.confirmed_details.new_points_earned)
        }
        if (this.currUser.has_wacom) {
          printReceiptPayload.print_data.fla_signature = this.currUser.userSignature
          printReceiptPayload.print_data.customer_signature = this.transactionData.customer_signature
        }
        printReceiptPayload.data = {
          has_wacom: this.currUser.has_wacom,
          trx_type: 1,
          remco_name: 'Western Union',
          control_number: req.result.confirmed_details.mtcn,
          customer_id: this.selectedCustomer.customer_id.toString(),
          location_id: this.currUser.location_id,
          location_name_uploaded: this.currUser.location_name,
          crn: this.selectedCustomer.customer_number,
          uploaded_by: this.currUser.user_id,
          first_name: this.transactionData.senderFirstName,
          last_name: this.transactionData.senderLastName,
          product: 'WU Send Money',
          receipt_type: 'acr'
        }
        if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
          printReceiptPayload.print_data.agent_name = this.remoteData.remote_location.location_name + ' BRANCH'
          printReceiptPayload.data.location_id = this.remoteData?.remote_location?.location_id
          printReceiptPayload.data.uploaded_by = this.remoteData.remote_user
          printReceiptPayload.data.location_name_uploaded = this.remoteData.remote_location.location_name
        } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
          printReceiptPayload.print_data.agent_name = this.currUser.location_name + ' BRANCH'
          printReceiptPayload.data.location_id = this.currUser.location_id
          printReceiptPayload.data.uploaded_by = this.currUser.user_id
          printReceiptPayload.data.location_name_uploaded = this.currUser.location_name
        }
        const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', printReceiptPayload)
        var decoded = Buffer.from(reqPrePrint.result, 'base64')
        console.log(decoded)
        var blob = new Blob([decoded], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(blob)
        console.log(fileURL)
        window.open(fileURL, '_blank')
        this.$store.commit('send/SET_STORE_DATA', req.result)
        this.$store.commit('send/SET_CURRENT_PAGE', 2)
        this.$store.commit('obo/SET_REMOTE_DATA', {})
      } else if (req.code === 500) {
        this.renderToast('error', 'WU Error', req.message)
        this.isOTPLoading = false
        this.$router.push('/pera-remit/send')
        this.isOTPLoading = false
        this.otp = ''
        this.otpDialog = false
      } else {
        this.renderToast('error', 'WU Error', req.message)
        this.isOTPLoading = false
        this.isOTPLoading = false
        this.otp = ''
        this.otpDialog = false
      }
    },
    async wuValidate () {
      this.validationMessage = ''
      let mobileNumber = this.selectedCustomer.mobile_no || ''
      let mobileCountryCode = this.selectedCustomer.mobile_no !== '' ? (this.selectedCustomer.mobile_country_code || '63') : ''
      let principalAmount = this.transactionData.sendAmount
      let senderCountryCode = ''
      let senderCountryCurrencyCode = ''
      let receiverCountryCode = ''
      let receiverCountryCurrencyCode = ''
      let myWUNumber = ''
      let idType = ''
      let idCountry = ''
      let idNumber = ''
      let idIssueDate = ''
      let idExpiryDate = ''
      // let idExpiryDateNoFormat = ''
      let gender = ''
      let idTypeInWords = ''
      let hasIssueDate = ''
      let hasExpirationDate = ''
      let positionLevel = this.selectedCustomer.position_level

      if (positionLevel === 'N/A') positionLevel = 'Entry Level'
      if (mobileNumber && mobileNumber.substr(0, 1) === '0') mobileNumber = mobileNumber.substr(1, 10)

      if (mobileCountryCode && mobileCountryCode.substr(0, 1) === '+') mobileCountryCode = mobileCountryCode.substr(1)

      const senderCountryDetails = this.collections.countries.filter(item => item.country === this.selectedCustomer.country)
      const receiverCountryDetails = this.payoutCountryList.filter(item => item.id === this.transactionData.destinationCountryID)

      if (senderCountryDetails.length === 1) {
        senderCountryCode = senderCountryDetails[0].two_iso_code
        senderCountryCurrencyCode = senderCountryDetails[0].currency
      }

      console.log(receiverCountryDetails)

      if (receiverCountryDetails.length === 1) {
        receiverCountryCode = receiverCountryDetails[0].iso_country_code
        receiverCountryCurrencyCode = receiverCountryDetails[0].currency_value
      }

      if (this.kycLookupData?.customer?.mywu_details?.mywu_number) {
        myWUNumber = this.kycLookupData?.customer?.mywu_details?.mywu_number
      }

      if (this.customerID.length > 0) {
        const idDetails = this.collections.wuIDList.filter(item => item.id_type === this.customerID[0].id_type)

        if (idDetails.length > 0) {
          idType = idDetails[0].id_value
          idCountry = this.customerID[0].country
          idNumber = this.customerID[0].id_number
          this.idNumber = this.customerID[0].id_number
          if (this.customerID[0].issue) idIssueDate = format(parseISO(this.customerID[0].issue), 'ddMMyyyy')
          if (this.customerID[0].expiry) idExpiryDate = format(parseISO(this.customerID[0].expiry), 'ddMMyyyy')
          this.idExpiryDateNoFormat = this.customerID[0].expiry
          idTypeInWords = this.customerID[0].id_type
          this.idTypeInWords = idTypeInWords

          hasIssueDate = idDetails[0].hasIssueDate
          hasExpirationDate = idDetails[0].hasExpiration

          console.log(idDetails)
        }
      }

      if (this.transactionData.fixedAmountFlag === 'F') principalAmount = this.transactionData.receiveAmount

      if (this.selectedCustomer.gender.toLowerCase() === 'male') gender = 'M'
      if (this.selectedCustomer.gender.toLowerCase() === 'female') gender = 'F'

      const birthDate = this.selectedCustomer.birth_date

      this.wuPayload = {
        foreign_reference_no: this.transactionData.refNo,
        sender_name_type: 'D',
        sender_first_name: this.transactionData.senderFirstName,
        sender_middle_name: this.transactionData.senderMiddleName ? this.transactionData.senderMiddleName : this.selectedCustomer.middle_name,
        sender_last_name: this.transactionData.senderLastName,
        sender_addr_line1: this.selectedCustomer.address,
        sender_addr_line2: this.selectedCustomer.barangay,
        sender_city: this.selectedCustomer.municipality,
        sender_state: this.selectedCustomer.province,
        sender_postal_code: this.selectedCustomer.zip_code,
        sender_addr_country_code: senderCountryCode,
        sender_addr_currency_code: senderCountryCurrencyCode,
        sender_contact_phone: `${this.selectedCustomer.tel_no}`,
        sender_mobile_country_code: mobileCountryCode,
        sender_mobile_no: `${mobileCountryCode}${mobileNumber}`,
        sender_addr_country_name: this.selectedCustomer.country,
        mywu_number: myWUNumber,
        id_type: idType,
        id_country_of_issue: idCountry,
        id_number: idNumber,
        id_issue_date: idIssueDate,
        id_expiration_date: idExpiryDate,
        date_of_birth: birthDate.match('\\d{4}-\\d{2}-\\d{2}') ? format(parseISO(birthDate), 'ddMMyyyy') : birthDate,
        occupation: this.selectedCustomer.occupation,
        Country_of_Birth: this.selectedCustomer.country_birth,
        nationality: this.getCountry(this.selectedCustomer.nationality),
        Source_of_Funds: this.selectedCustomer.source_fund,
        Sender_Employer: this.selectedCustomer.employer_name,
        Relationship_to_Receiver: this.selectedCustomer.relation_to,
        GEN_III_Indicator: '',
        ack_flag: this.transactionData.agentVerifiedData ? 'X' : '',
        reason_for_send: '',
        mywu_enroll_tag: 'none',
        email: this.selectedCustomer.email_add,
        receiver_name_type: 'D',
        receiver_first_name: trim(this.transactionData.receiverFirstName),
        receiver_middle_name: trim(this.transactionData.receiverMiddleName),
        receiver_last_name: trim(this.transactionData.receiverLastName),
        receiver_addr_line1: '',
        receiver_addr_line2: '',
        receiver_city: (this.transactionData.destinationCountryCode === 'US' || this.transactionData.destinationCountryCode === 'MX') ? this.transactionData.receiverCity : '',
        receiver_state: (this.transactionData.destinationCountryCode === 'US' || this.transactionData.destinationCountryCode === 'MX') ? this.transactionData.receiverState : '',
        receiver_postal_code: '',
        receiver_addr_country_code: receiverCountryCode,
        receiver_addr_currency_code: receiverCountryCurrencyCode,
        receiver_contact_phone: '',
        receiver_mobile_country_code: '',
        receiver_mobile_no: '',
        destination_country_code: receiverCountryCode,
        destination_currency_code: receiverCountryCurrencyCode,
        destination_state_code: (this.transactionData.destinationCountryCode === 'US' || this.transactionData.destinationCountryCode === 'MX') ? this.transactionData.receiverState : '',
        destination_city_name: (this.transactionData.destinationCountryCode === 'US' || this.transactionData.destinationCountryCode === 'MX') ? this.transactionData.receiverCity : '',
        transaction_type: this.transactionData.deliveryService.value === 'MIM' ? 'SO' : this.transactionData.deliveryService.value,
        principal_amount: Number(principalAmount) * 100,
        fixed_amount_flag: this.transactionData.fixedAmountFlag,
        promo_code: this.transactionData.promoCode,
        message: this.transactionData.message,
        bank_name: '',
        account_number: '',
        bank_code: '',
        bank_location: '',
        account_suffix: '',
        terminal_id: this.terminalId,
        operator_id: this.operatorId,
        remote_terminal_id: this.currUser.fs_id,
        remote_operator_id: this.currUser.user_id,
        employment_position_level: positionLevel,
        Purpose_of_Transaction: this.selectedCustomer.purpose_transaction,
        is_on_behalf: this.transactionData.isOnBehalf,
        galactic_id: this.transactionData.galacticID,
        location_id: this.locationId,
        user_code: this.currUser.user_id,
        customer_code: this.selectedCustomer.customer_number,
        gender: gender,
        form_number: '',
        form_type: 'OAR',
        test_question: this.transactionData.testQuestion,
        answer: this.transactionData.answer
      }

      const toValidate = {
        id_type: idType,
        id_country_of_issue: idCountry,
        id_number: idNumber,
        id_issue_date: idIssueDate,
        id_expiration_date: idExpiryDate,
        Purpose_of_Transaction: this.selectedCustomer.purpose_transaction,
        is_on_behalf: this.transactionData.isOnBehalf,
        employment_position_level: positionLevel,
        occupation: this.selectedCustomer.occupation,
        sender_addr_country_code: senderCountryCode,
        Source_of_Funds: this.selectedCustomer.source_fund,
        sender_addr_line1: this.selectedCustomer.address,
        mywu_number: myWUNumber,
        destination_country_code: receiverCountryCode,
        Country_of_Birth: this.selectedCustomer.country_birth,
        // reason_for_send: '',
        date_of_birth: birthDate.match('\\d{4}-\\d{2}-\\d{2}') ? format(parseISO(birthDate), 'ddMMyyyy') : birthDate,
        sender_mobile_country_code: mobileCountryCode,
        sender_contact_phone: `${this.selectedCustomer.tel_no}`,
        sender_mobile_no: `${mobileCountryCode}${mobileNumber}`,
        has_issue_date: hasIssueDate,
        has_expiration_date: hasExpirationDate,
        foreign_reference_no: this.transactionData.refNo,
        sender_first_name: this.transactionData.senderFirstName
      }

      console.log(idCountry)

      const checkContactPhoneFormat = toValidate.sender_mobile_no !== '' && toValidate.sender_mobile_no.match(/^\d+$/)
      const checkTelNumberFormat = toValidate.sender_contact_phone !== '' && toValidate.sender_contact_phone.match(/^\d+$/)
      const checkPhoneCountryCodeFormat = toValidate.sender_mobile_country_code !== '' && toValidate.sender_mobile_country_code.match(/^\d+$/)
      const checkMyWUNumber = toValidate.mywu_number !== undefined || toValidate.mywu_number !== null

      const checkOccupation = this.collections.occupation.filter(res => {
        if (res.occupation_value === this.selectedCustomer.occupation) {
          return true
        } else {
          return false
        }
      })

      const checkPurpose = this.collections.purposeOfTransaction.filter(res => {
        if (res.purpose === this.selectedCustomer.purpose_transaction) {
          return true
        } else {
          return false
        }
      })

      const checkSourceOfFund = this.collections.sourceOfFund.filter(res => {
        if (res.source_of_funds === this.selectedCustomer.source_fund) {
          return true
        } else {
          return false
        }
      })

      const checkSenderCountry = this.collections.countries.filter(res => {
        if (res.country === this.selectedCustomer.country) {
          return true
        } else {
          return false
        }
      })

      const checkBirthCountry = this.collections.countries.filter(res => {
        if (res.country === this.selectedCustomer.country_birth) {
          return true
        } else {
          return false
        }
      })

      // const selectedCity = this.collections.cities.filter(item => item.city === this.selectedCustomer.municipality)
      // let currentProvince = ''

      // if (selectedCity.length === 1) currentProvince = selectedCity[0]?.province

      // const checkProvince = currentProvince === this.selectedCustomer.province

      // const toValidate = {
      //   id_type: '',
      //   id_country_of_issue: '',
      //   id_number: '',
      //   id_issue_date: '',
      //   id_expiration_date: '',
      //   Purpose_of_Transaction: '',
      //   is_on_behalf: '',
      //   employment_position_level: '',
      //   occupation: '',
      //   sender_addr_country_code: '',
      //   Source_of_Funds: '',
      //   sender_addr_line1: '',
      //   mywu_number: '',
      //   destination_country_code: '',
      //   Country_of_Birth: '',
      //   // reason_for_send: '',
      //   date_of_birth: '',
      //   sender_mobile_country_code: '',
      //   sender_contact_phone: '',
      //   sender_mobile_no: '',
      //   has_issue_date: hasIssueDate,
      //   has_expiration_date: hasExpirationDate,
      //   foreign_reference_no: ''
      // }
      const validateCheck = await this.validateWuSend(toValidate)

      if (toValidate.sender_mobile_no !== '' && !checkContactPhoneFormat) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Mobile Number<br>'
      } else if (toValidate.sender_mobile_country_code !== '' && !checkPhoneCountryCodeFormat) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Phone Country Code<br>'
      } else if (toValidate.sender_contact_phone !== '' && !checkTelNumberFormat) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Telephone Number<br>'
      } else if (!checkMyWUNumber) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid MYWU Number<br>'
      } else if (checkOccupation.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Occupation<br>'
      } else if (checkPurpose.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Purpose<br>'
      } else if (checkSourceOfFund.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Source Of Funds<br>'
      } else if (checkSenderCountry.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Sender Country<br>'
      } else if (checkBirthCountry.length < 1) {
        this.validationDialog = true
        this.validationMessage += 1 + '. Please Enter Valid Birth Country<br>'
      // } else if (!checkProvince) {
      //   this.validationDialog = true
      //   this.validationMessage += 1 + '. Please Enter Valid Province<br>'
      } else if (validateCheck?.error) {
        this.validationDialog = true
        validateCheck.error.details.forEach((item, index) => {
          this.validationMessage += (index + 1) + '. ' + item.message + '<br>'
        })
      } else {
        if (this.transactionData.destinationCountryCode === 'MX' || this.transactionData.destinationCountryCode === 'US') {
          let isStateInvalid = false

          if (this.transactionData.receiverState === '' && this.transactionData.deliveryService.value !== 'D2B') {
            this.validationDialog = true
            this.validationMessage += 1 + '. Receiver State is Required<br>'
            isStateInvalid = true
          }

          if (this.transactionData.destinationCountryCode === 'MX') {
            if (this.transactionData.receiverCity === '') {
              this.validationDialog = true
              this.validationMessage += (isStateInvalid ? 2 : 1) + '. Receiver City is Required<br>'
            }
          }
        }

        if (this.transactionData.deliveryService.value === 'D2B') {
          const d2bValues = await this.getD2BData(this.transactionData.destinationCountryCode)
          if (d2bValues?.receiver_state) {
            this.wuPayload.destination_state_code = d2bValues.receiver_state
          }

          if (d2bValues?.receiver_city) {
            this.wuPayload.destination_city_name = d2bValues.receiver_city
          }

          this.wuPayload = Object.assign({}, this.wuPayload, d2bValues)
        }

        const req = await this.$store.dispatch('send/sendValidate', this.wuPayload)

        if (req.code === 200) {
          this.$store.commit('send/SET_VALIDATE_DATA', req.result)
          if (this.currUser.has_wacom) {
            if (this.hasCustomerDataConsent) {
              this.$store.commit('wacom/SET_WACOM_CONSENT_DIALOG', true)
            } else {
              this.$store.commit('wacom/SET_WACOM_DATA_CONSENT_FIRST_DIALOG', true)
            }
          } else {
            this.generatePrePrint()
          }
        } else {
          if (req.code === 'USP' && req.message === 'Error in parameter.') {
            this.renderToast('error', 'Incomplete customer details', 'Please update the customer information and try again')
            this.isOTPLoading = false
          } else {
            this.renderToast('error', 'WU Error', req.message)
            this.isOTPLoading = false
          }
        }
      }
    },
    formatAmountACR (amount) {
      return numeral(amount).format('0,0.00')
    },
    async generatePrePrint () {
      console.log(this.transactionData, 'trx_data')
      console.log(this.validateData, 'validate_data')
      const prePrintPayload = {}
      prePrintPayload.print_data = {
        receiver_name: this.transactionData.receiverFirstName + ' ' + this.transactionData.receiverMiddleName + ' ' + this.transactionData.receiverLastName,
        sender_name: this.transactionData.senderFirstName + ' ' + this.transactionData.senderMiddleName + ' ' + this.transactionData.senderLastName,
        mywu_number: '',
        points_earned: '0',
        total_wu_points: '',
        id_type: this.idTypeInWords,
        id_number: this.idNumber,
        id_expiration: this.idExpiryDateNoFormat,
        occupation: this.selectedCustomer.occupation,
        name_of_employer: this.selectedCustomer.employer_name,
        date_of_birth: this.selectedCustomer.birth_date,
        country_of_birth: this.selectedCustomer.country_birth,
        nationality: this.getCountry(this.selectedCustomer.nationality),
        purpose_of_transaction: this.selectedCustomer.purpose_transaction,
        source_of_funds: this.selectedCustomer.source_fund,
        relationship: this.selectedCustomer.relation_to,
        present_address_line1: this.selectedCustomer.address,
        present_address_line2: this.selectedCustomer.barangay,
        present_city: this.selectedCustomer.municipality,
        present_state: this.selectedCustomer.province,
        present_postcode: this.selectedCustomer.zip_code,
        permanent_address_line1: this.selectedCustomer.street1,
        permanent_address_line2: this.selectedCustomer.barangay1,
        permanent_city: this.selectedCustomer.city1,
        permanent_state: this.selectedCustomer.province1,
        permanent_postcode: this.selectedCustomer.zip_code1,
        landline: '',
        mobile_no: this.selectedCustomer.mobile_no,
        mtcn: '',
        date_and_time: this.formatDateTime(new Date()),
        agent_details: this.currUser.location_name + ' BRANCH',
        originating_country: 'Philippines',
        city: '',
        amount_receive: '',
        total: 'Philippine Peso ' + this.formatAmountACR((this.feeInquiryData.gross_total_amount / 100)),
        pay_amount: this.transactionData.destinationCountryCurrency + ' ' + this.formatAmountACR((this.validateData.financials.destination_principal_amount / 100)),
        exchange_rate: this.transactionData.exchangeRate,
        dst_pay_amount: '',
        cash: '',
        checque: '',
        checque_number: '',
        credit_to_bank_account: '',
        account_number: '',
        amount_sent: this.formatAmountACR((this.feeInquiryData.originators_principal_amount / 100)),
        transfer_fee: this.formatAmountACR((this.validateData.financials.charges / 100)),
        delivery_charge: '0.00',
        discount: this.transactionData.discountAmount,
        destination_country: this.transactionData.destinationCountryName,
        originating_curr: '',
        destination_curr: '',
        message_charge: this.formatAmountACR(this.validateData.financials.message_charge),
        message: ''
      }
      if (this.kycLookupData && this.kycLookupData.customer && this.kycLookupData.customer.mywu_details) {
        prePrintPayload.print_data.mywu_number = this.kycLookupData.customer.mywu_details.mywu_number
      }
      if (this.currUser.has_wacom) {
        prePrintPayload.print_data.customer_signature = this.customerSignature
        prePrintPayload.print_data.fla_signature = this.currUser.userSignature
      }
      prePrintPayload.data = {
        has_wacom: this.currUser.has_wacom,
        trx_type: 1,
        remco_name: 'Western Union',
        control_number: this.validateData.new_details.mtcn,
        customer_id: this.selectedCustomer.customer_id.toString(),
        location_id: this.currUser.location_id,
        location_name_uploaded: this.currUser.location_name,
        crn: this.selectedCustomer.customer_number,
        uploaded_by: this.currUser.user_id,
        first_name: this.transactionData.senderFirstName,
        last_name: this.transactionData.senderLastName,
        product: 'WU Send Money',
        receipt_type: 'pre'
      }
      if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        prePrintPayload.print_data.agent_details = this.remoteData.remote_location.location_name + ' BRANCH'
        prePrintPayload.data.location_id = this.remoteData?.remote_location?.location_id
        prePrintPayload.data.uploaded_by = this.remoteData.remote_user
        prePrintPayload.data.location_name_uploaded = this.remoteData.remote_location.location_name
      } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
        prePrintPayload.print_data.agent_details = this.currUser.location_name + ' BRANCH'
        prePrintPayload.data.location_id = this.currUser.location_id
        prePrintPayload.data.uploaded_by = this.currUser.user_id
        prePrintPayload.data.location_name_uploaded = this.currUser.location_name
      }
      const reqPrePrint = await this.$store.dispatch('wu/generateACRWU', prePrintPayload)
      var decoded = Buffer.from(reqPrePrint.result, 'base64')
      console.log(decoded)
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      console.log(fileURL)
      window.open(fileURL, '_blank')
      this.isDonePreprint = true
      console.log(reqPrePrint)
    },
    formatAmount (amount, currency) {
      if (amount) {
        return toMoney(amount, currency)
      } else {
        return ''
      }
    },
    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    getCountry (nationality) {
      const countries = this.collections.countries.filter(country => country.nationality === nationality)

      if (countries.length === 1) return countries[0].country
      return ''
    },
    async generateConsent () {
      const address = this.selectedCustomer.address
      const barangay = this.selectedCustomer.barangay
      const city = this.selectedCustomer.municipality
      const province = this.selectedCustomer.province
      const zipCode = this.selectedCustomer.zip_code
      const payload = {
        customer_signature: this.customerSignature,
        customer_name: `${this.selectedCustomer.first_name} ${this.selectedCustomer.middle_name} ${this.selectedCustomer.last_name}`,
        customer_id: this.selectedCustomer.customer_id,
        customer_address: `${address} ${barangay} ${city} ${province} ${zipCode}`,
        date: this.currUser.trx_date,
        fla_id: this.currUser.user_id,
        location_id: this.currUser.location_id,
        product: this.transactionData.remcoName + ' Send Out',
        nationality: this.selectedCustomer.nationality,
        crn: this.selectedCustomer.customer_number,
        first_clause: this.consentValues.first_clause,
        second_clause: this.consentValues.second_clause,
        third_clause: this.consentValues.third_clause,
        fourth_clause: this.consentValues.fourth_clause,
        fifth_clause: this.consentValues.fifth_clause
      }
      await this.$store.dispatch('dataConsent/generateConsent', payload).then((res) => {
        if (res.code === 200) {
          renderToast('success', 'Success', 'Customer Consent Saved Successfully!')
          this.$store.commit('wacom/SET_CLAUSE_VALUE_DEFAULT')
          this.checkConsent()
        } else {
          renderToast('error', 'Receipt Error', 'Failed save customer consent')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkConsent () {
      const customerConsent = await this.$store.dispatch('dataConsent/checkCustomerConsent', { customer_id: this.selectedCustomer.customer_id })
      this.hasCustomerDataConsent = customerConsent.result
    },
    getD2BData (data) {
      let payload = {}

      switch (data) {
        case 'NG':
          payload = {
            bank_name: this.transactionData.BankName.text,
            account_number: this.transactionData.AccountNumber,
            bank_code: this.transactionData.BankName.value,
            reason_for_send: this.transactionData.PurposeofTransaction.value
          }
          break
        case 'AL':
          payload = {
            bank_name: this.transactionData.BankName.value ? this.transactionData.BankName.value : '',
            iban: this.transactionData.IBAN
          }
          break
        case 'RO':
          payload = {
            iban: this.transactionData.IBAN
          }
          break
        case 'AD': case 'BH':
          payload = {
            bank_name: this.transactionData.BankName,
            bic: this.transactionData.BIC,
            iban: this.transactionData.IBAN
          }
          break
        case 'AR':
          payload = {
            bank_name: this.transactionData.BankName.value,
            bank_account_number: this.transactionData.BankAccountNumberCBU,
            tax_id: this.transactionData.TaxIDCUIL,
            reason_for_send: this.transactionData.PurposeofRemittance.value,
            receiver_nationality: this.transactionData.ReceiverNationality.value,
            receiver_email: this.transactionData.ReceiverEmail,
            receiver_addr_line1: this.transactionData.Address1,
            receiver_addr_line2: this.transactionData.Address2,
            receiver_city: this.transactionData.City,
            receiver_state: this.transactionData.ReceiverProvince.value,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode.value,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'AU':
          payload = {
            bsb: this.transactionData.BSBBankStateBranch,
            account_number: this.transactionData.AccountNumber
          }
          break
        case 'AZ': case 'GE': case 'K1': case 'PL': // No D2B for Kosovo
          payload = {
            iban: this.transactionData.IBAN,
            reason_for_send: this.transactionData.PurposeofTransaction
          }
          break
        case 'IL':
          payload = {
            iban: this.transactionData.IBAN,
            reason_for_send: this.transactionData.PurposeofSend,
            receiver_addr_line1: this.transactionData.Address1,
            receiver_city: this.transactionData.City
          }
          break
        case 'BD':
          payload = {
            bank_name: this.transactionData.BankName.text,
            account_number: this.transactionData.AccountNumber,
            bank_location: this.transactionData.District.value, // district
            branch_number: this.transactionData.BranchName.value,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        // Croatia has Error in D2B
        case 'AT': case 'BY': case 'BE': case 'BG': case 'HR': case 'CY': case 'DK': case 'EE': case 'FI': case 'FR': case 'GF': case 'DE': case 'GR': case 'GP': case 'GW': case 'HU': case 'IS': case 'IE': case 'IT':
        case 'LV': case 'LI': case 'LT': case 'LU': case 'MT': case 'MQ': case 'YT': case 'MC': case 'NL': case 'NE': case 'NO': case 'PT': case 'BL': case 'SN': case 'YU': case 'SK': case 'SI': case 'ES': case 'MF': case 'SE':
        case 'CH': case 'TG':
          payload = {
            iban: this.transactionData.IBAN
          }
          break
        case 'BA':
          payload = {
            iban: this.transactionData.IBAN,
            receiver_addr_line1: this.transactionData.Address1,
            receiver_city: this.transactionData.City,
            receiver_postal_code: this.transactionData.PostalCode
          }
          break
        case 'TR':
          payload = {
            iban: this.transactionData.IBAN,
            receiver_addr_line1: this.transactionData.Address1,
            receiver_city: this.transactionData.City
          }
          break
        case 'UA':
          payload = {
            iban: this.transactionData.IBAN,
            receiver_mobile_country_code: this.transactionData.CountryCode,
            receiver_mobile_no: this.transactionData.PhoneNumber
          }
          break
        case 'BO':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            account_type: this.transactionData.AccountType,
            national_id: this.transactionData.NationalID,
            receiver_nationality: this.transactionData.CountryCode
          }
          break
        case 'BR': // Money in Minutes Only
          payload = {
            bank_name: '',
            branch_number: '',
            account_number: '',
            account_type: '',
            tax_id: '',
            receiver_email: '',
            receiver_mobile_country_code: '',
            receiver_mobile_no: '',
            receiver_contact_phone: ''
          }
          break
        case 'CA':
          payload = {
            institution_number: this.transactionData.InstitutionNumber?.value,
            transit_number: this.transactionData.TransitNumber,
            account_number: this.transactionData.AccountNumber,
            reason_for_send: this.transactionData.PurposeofTransaction.text,
            receiver_addr_line1: this.transactionData.AddressLine1,
            receiver_city: this.transactionData.City,
            receiver_state: this.transactionData.StateProvince.value
            // account_type: this.transactionData.AccountType
          }
          break
        case 'CL':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            account_type: this.transactionData.AccountType,
            tax_id: this.transactionData.RUTTaxID
          }
          break
        case 'CN':
          payload = {
            reason_for_send: this.transactionData.Reasonforremittance,
            bank_card_number: this.transactionData.BankCardNumber,
            bank_name: this.transactionData.BankName,
            // reason_for_send: '',
            receiver_contact_phone: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'CO':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            account_type: this.transactionData.AccountType,
            national_id_number: this.transactionData.NationalIDNumberDNI,
            receiver_addr_line1: this.transactionData.Address1,
            receiver_city: this.transactionData.City,
            receiver_state: this.transactionData.State,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'CR':
          payload = {
            bank_name: this.transactionData.BankName,
            iban: this.transactionData.IBAN,
            national_id: this.transactionData.NationalID,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'CZ':
          payload = {
            bic: this.transactionData.BIC,
            iban: this.transactionData.IBAN
          }
          break
        case 'EC':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            account_type: this.transactionData.AccountType,
            receiver_id: this.transactionData.ReceiverID,
            reason_for_send: this.transactionData.PurposeofTransaction,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'MY': case 'VN':
          payload = {
            bank_name: this.transactionData.BankName.value + 'XXX',
            // bic: this.transactionData.BankName.value + 'XXX',
            account_number: this.transactionData.AccountNumber,
            reason_for_send: this.transactionData.PurposeofTransaction.value,
            sender_relationshiop_to_receiver: this.transactionData.SenderRelationshiptoReceiver.value
          }
          break
        case 'RW': case 'TZ': case 'TH': case 'UG':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            reason_for_send: this.transactionData.PurposeofRemittance
          }
          break
        case 'EG':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.IBANAccountNumber,
            reason_for_send: this.transactionData.PurposeofRemittance,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'GH':
          payload = {
            bank_name: this.transactionData.BankName,
            sort_code: this.transactionData.SortCode,
            account_number: this.transactionData.AccountNumber,
            reason_for_send: this.transactionData.PurposeofSend
          }
          break
        case 'GI':
          payload = {
            bank_name: this.transactionData.BankName,
            sort_code: this.transactionData.SortCode,
            account_number: this.transactionData.AccountNumber
          }
          break
        case 'HK':
          payload = {
            bank_name: this.transactionData.BankName,
            bank_code: this.transactionData.BankCode,
            branch_code: this.transactionData.BranchCode,
            account_number: this.transactionData.AccountNumber,
            account_number_suffix: this.transactionData.AccountNumberSuffix
          }
          break
        case 'IN':
          payload = {
            bank_name: this.transactionData.BankName.value,
            account_number: this.transactionData.AccountNumber,
            ifsc_code: this.transactionData.IFSCCode,
            reason_for_send: this.transactionData.PurposeofRemittance.value,
            receiver_addr_line1: '',
            receiver_city: '',
            receiver_state: '',
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode.value,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'PH':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            reason_for_send: this.transactionData.PurposeofRemittance
          }
          break
        case 'ID':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            reason_for_send: this.transactionData.CountryCode,
            receiver_contact_phone: this.transactionData.ReceiverPhoneNumber
          }
          break
        case 'JM':
          payload = {
            bank_name: this.transactionData.BankName,
            branch_name: this.transactionData.BranchName,
            account_number: this.transactionData.AccountNumber,
            receiver_email: this.transactionData.AccountType,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'JP':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber,
            bic: this.transactionData.BIC
          }
          break
        case 'KZ':
          payload = {
            iban: this.transactionData.IBAN,
            iin: this.transactionData.IndividualIdentificationNumberIIN,
            reason_for_send: this.transactionData.PurposeofTransaction,
            reason_is_resident: this.transactionData.IsResident
          }
          break
        case 'KE': case 'MA':
          payload = {
            bank_name: this.transactionData.BankName,
            account_number: this.transactionData.AccountNumber
          }
          break
        case 'NI': case 'SG':
          payload = {
            bank_name: this.transactionData.BankName.value,
            account_number: this.transactionData.AccountNumber,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode ? this.transactionData.ReceiversMobileCountryCode : '',
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber ? this.transactionData.ReceiversMobileNumber : ''
          }
          break
        case 'KG':
          payload = {
            account_number: this.transactionData.AccountNumber,
            bic: this.transactionData.BIC,
            reason_for_send: this.transactionData.PurposeofRemittance
          }
          break
        case 'MK':
          payload = {
            account_number: this.transactionData.AccountNumber
          }
          break
        case 'MD':
          payload = {
            iban: this.transactionData.IBAN,
            fiscal_code: this.transactionData.FiscalCode,
            reason_for_send: this.transactionData.PurposeofTransaction
          }
          break
        case 'NP':
          payload = {
            bank_name: this.transactionData.BankName.text,
            bank_account_number: this.transactionData.BankAccountNumber,
            reason_for_send: this.transactionData.PurposeofTransaction,
            sender_relationshiop_to_receiver: this.transactionData.SenderRelationshiptoReceiver.value
          }
          break
        case 'NZ':
          console.log(this.transactionData)
          payload = {
            bank_name: this.transactionData.BankName,
            bank_code: this.transactionData.AccountNumber1,
            branch_number: this.transactionData.AccountNumber2,
            account_number: this.transactionData.AccountNumber3,
            account_suffix: this.transactionData.AccountNumber4
          }
          break
        case 'PK':
          payload = {
            bank_name: this.transactionData.BankName,
            iban: this.transactionData.IBANAccountNumber,
            branch_name: this.transactionData.BranchNameAddress,
            receiver_mobile_country_code: this.transactionData.ReceiversMobileCountryCode,
            receiver_mobile_no: this.transactionData.ReceiversMobileNumber
          }
          break
        case 'RE':
          payload = {
            bank_name: '',
            iban: this.transactionData.IBAN
          }
          break
        case 'RU': // Not Available
          payload = {
            bank_name: '',
            branch_city: '',
            russian_bic: '',
            account_number: '',
            reason_for_send: ''
          }
          break
        case 'AE':
          payload = {
            bank_name: this.transactionData.BankName,
            bic: this.transactionData.BIC,
            iban: this.transactionData.IBAN,
            reason_for_send: this.transactionData.PurposeofTransaction.value
          }
          break
        case 'GB':
          payload = {
            bank_name: this.transactionData.BankName,
            sort_code: this.transactionData.SortCode,
            account_number: this.transactionData.AccountNumber
          }
          break
        case 'US':
          payload = {
            bank_name: this.transactionData.BankName,
            aba_routing_number: this.transactionData.ABARoutingNumber,
            account_number: this.transactionData.AccountNumber,
            account_type: this.transactionData.AccountType.value,
            receiver_addr_line1: this.transactionData.Address,
            receiver_city: this.transactionData.ReceiverCity,
            receiver_state: this.transactionData.State.value,
            receiver_mobile_country_code: this.transactionData.ReceiverCountryCode.value,
            receiver_mobile_no: this.transactionData.ReceiverPhoneNumber,
            receiver_postal_code: this.transactionData.ZIPCode
          }
          break
        default:
          return
      }

      return payload
    }
  },
  async mounted () {
    [
      'getPurposeOfTransaction',
      'getOccupation'
    ].forEach(action => this.$store.dispatch(action))
    if (this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.remoteData.ftid
      this.operatorId = this.remoteData.remote_user
      this.locationId = this.remoteData?.remote_location?.location_id
    } else if (!this.remoteData?.remote_user && this.$route.query.is_remote === 'true') {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    } else {
      this.terminalId = this.currUser.fs_id
      this.operatorId = this.currUser.user_id
      this.locationId = this.currUser.location_id
    }
    const that = this
    setTimeout(wacomMixin.methods.checkForSigCaptX, 1000)
    setTimeout(wacomMixin.methods.checkForWacom, 2000)
    const signatures = await that.$store.dispatch('customers/retrieveSignatures', { customer_id: that.selectedCustomer.customer_id })
    that.customerSignaturesReference = signatures.result
    this.checkConsent()
  }
}
</script>

<style scoped>
#summaryOverview {
  position: sticky !important;
  top: 80px !important;
  background: #f4f4fa !important;
  border-radius: 5px !important;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:100%;
}
</style>

<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
    </v-container>
    <v-row class="px-10">
      <v-btn color="primary" @click="uploadView">Batch Upload Historical</v-btn>
    </v-row>
    <v-row class="px-5">
      <v-col>
        <v-text-field
        v-model="reference_number"
          name="name"
          label="Search Reference No."
          class="ph-textbox"
          single-line
          outlined
          id="id"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="searchThis">Search</v-btn>
      </v-col>
    </v-row>
    <v-simple-table class="pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <b>References No.</b>
            </th>
            <th class="text-left">
              <b>Product</b>
            </th>
            <th class="text-left">
              <b>Type</b>
            </th>
            <th class="text-left">
              <b>First Name</b>
            </th>
            <th class="text-left">
              <b>Last  Name</b>
            </th>
            <th class="text-left">
              <b>Customer ID</b>
            </th>
            <th class="text-left">
              <b>CRN</b>
            </th>
            <th class="text-left">
              <b>Location ID</b>
            </th>
            <th class="text-left">
              <b>Location ID Uploaded</b>
            </th>
            <th class="text-left">
              <b>TRX Date</b>
            </th>
            <th class="text-left">
              <b>File</b>
            </th>
            <th class="text-left">
              <b>Customer Profile</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in customerACRList" :key="index">
            <td>{{ value.ref_number }}</td>
            <td>{{ value.product }}</td>
            <td>{{ value.type }}</td>
            <td>{{ value.first_name }}</td>
            <td>{{ value.last_name }}</td>
            <td>{{ value.customer_id }}</td>
            <td>{{ value.crn }}</td>
            <td>{{ value.location_id }}</td>
            <td>{{ value.location_id_uploaded }}</td>
            <td>{{ value.transaction_date }}</td>
            <td><v-btn color="success" @click="download(value.bucket_path)">{{value.isPDF ? 'Download PDF': 'Download Image'}}</v-btn></td>
            <td><v-btn v-if="value.category === 'D'" color="success" :href="`https://usp20.perahub.com.ph/customers/${value.crn}?editable=true&persistData=true&redirectURL=%2Fcustomers`" target="_blank" rel="noopener noreferrer" class="ma-2">Go to Profile</v-btn></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="px-5">
      <v-col cols="12" sm="6">
        <v-menu
          ref="expiryDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          nudge-right="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="ph-textbox"
              color="gray darken-1"
              label="Search Date"
              append-icon="mdi-calendar"
              hide-details
              outlined
              v-model="txtSearchDateCount"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="txtSearchDateCount"
          />
        </v-menu>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="searchCount">Search</v-btn>
      </v-col>
    </v-row>
    <v-simple-table class="pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <b>Location ID</b>
            </th>
            <th class="text-left">
              <b>User ID</b>
            </th>
            <th class="text-left">
              <b>Total</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in uploadCountList[0].locations_count" :key="index">
            <td>{{ value.location_id }}</td>
            <td>{{ value.user_id }}</td>
            <td>{{ value.count }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <label class="px-5"><h2>Total Uploaded:&emsp;{{ totalCount }}</h2> </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Digicur',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Compliance',
          disabled: false,
          href: '/compliance'
        },
        {
          text: 'Digicur',
          disabled: false,
          href: '/compliance/digicur'
        }
      ],
      reference_number: '',
      txtSearchDateCount: '',
      customerACRList: [],
      isPDF: true,
      base64Result: '',
      uploadCountList: [],
      totalCount: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      customerACR: 'digicur/customerACR',
      uploadCount: 'digicur/uploadCount'
    })
  },
  methods: {
    async searchThis () {
      const payload = {
        filename: this.reference_number,
        location_id: this.currUser.location_id,
        limit: 50,
        location_type: 2,
        user_id: this.currUser.user_id,
        page: 1,
        firstKey: '',
        lastKey: '',
        operation: ''
      }
      await this.$store.dispatch('digicur/searchACR', payload)
      this.customerACRList = this.customerACR
      this.customerACR.map(file => {
        const bucketPath = file.bucket_path
        const ext = bucketPath.substring(bucketPath.lastIndexOf('.') + 1, bucketPath.length) || bucketPath
        if (ext !== 'pdf') {
          file.isPDF = false
        } else {
          file.isPDF = true
        }
      })
    },
    async searchCount () {
      const payload = {
        date: this.txtSearchDateCount
      }
      await this.$store.dispatch('digicur/searchACRCount', payload)
      this.uploadCountList = this.uploadCount
      this.totalCount = this.uploadCountList[0].total_count
    },
    async download (url) {
      const bucketPath = {
        url: url
      }
      var filename = url.split('/').pop().split('#')[0].split('?')[0]
      var extension = filename.split('.').pop()
      await this.$store.dispatch('digicur/downloadFile', bucketPath).then(res => {
        this.base64Result = res.base64
      })

      const link = document.createElement('a')
      if (extension === 'pdf') {
        link.setAttribute('href', 'data:"application/pdf;base64,' + this.base64Result)
      } else if (extension === 'jpg') {
        link.setAttribute('href', 'data:"image/jpg;base64,' + this.base64Result)
      } else if (extension === 'png') {
        link.setAttribute('href', 'data:"image/png;base64,' + this.base64Result)
      } else if (extension === 'jpeg') {
        link.setAttribute('href', 'data:"image/jpeg;base64,' + this.base64Result)
      }

      link.setAttribute('download', filename)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    uploadView () {
      // Get the current time and add 5 mins
      const now = new Date()
      now.setMinutes(now.getMinutes() + 5)
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')
      const currentTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      // Set params
      var branchType = this.currUser.location_type
      var locationGroupID = this.currUser.location_group_id
      var locationID = this.currUser.location_id
      var trxDate = this.currUser.trx_date
      var userID = this.currUser.user_id
      var locationName = this.currUser.location_name
      var userName = `${this.currUser.first_name} ${this.currUser.last_name}`
      var timelogout = currentTime
      var uspVeriosn = 2
      var params = '?branchType=' + branchType + '&locationGroupID=' + locationGroupID + '&locationID=' + locationID + '&trxDate=' + trxDate + '&userID=' + userID + '&locationName=' + locationName + '&userName=' + userName + '&timeLogOut=' + timelogout + '&uspVeriosn=' + uspVeriosn

      var encodedParam = btoa(params)
      // var url = 'https://dev-ui-cis-digicur-app-bpsk2lcqiq-as.a.run.app/' + encodedParam
      var url = 'https://prod-ui-cis-digicur-app-tenmgv6bka-as.a.run.app/' + encodedParam
      // var url = 'http://localhost:8080/' + encodedParam
      window.open(
        url,
        '_blank'
      )
    }
  }

}
</script>

<style scoped>
</style>

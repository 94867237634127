<template>
  <div>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
      <v-col :cols="12">
        <v-card elevation="1" class="ph-nonex-datatable-container">
          <v-card-title class="ph-nonex-datatable-title">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="ph-textbox ph-textbox-grid-search"
              outlined
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="branchList"
            class="custom-table"
            :calculate-widths="true"
            :search="search"
          >
            <template v-slot:[`item.is_ereceipt_enabled`]="{ item }">
              <v-switch
                v-model="item.is_ereceipt_enabled"
                :disabled="item.ereceipt_enrollment_status !== 'Enrolled' || (item.manual_enrollment_status !== 'Enrolled' && item.ereceipt_enrollment_status === 'Enrolled')"
                @click="toggleEReceipt(item)"
                readonly
              ></v-switch>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn @click="editBranchInfo(item)" color="primary" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn @click="viewAvaliableSeries(item)" color="primary" icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="50%">
      <v-card class="rounded-lg">
        <v-form ref="updateBranchReceiptInfo">
          <v-card-title class="py-md-6">
            <h3 class="custom-heading primary--text">
              Enter Branch Details
            </h3>
            <v-spacer />
            <v-btn @click="closeEditDialog" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col :cols="12">
                <p>Name: <b>{{ selectedBranchInfo.branch }} Branch</b></p>
                <p v-if="(prefixValue !== '' && branchReceiptData.length !== null) && (branchReceiptData.receipt_format !== '3' && branchReceiptData.receipt_format !== 3)">Preview: <b>{{prefixValue + seriesFrom}} - {{prefixValue + seriesTo}}</b></p>
                <p v-if="branchReceiptData.receipt_format === '3' || branchReceiptData.receipt_format === 3">Header Name: <b>Information Technology Department Branch</b></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12">
                <v-select
                  v-model="branchReceiptData.receipt_format"
                  :items="receiptTypes"
                  item-text="text"
                  item-value="value"
                  class="ph-textbox"
                  label="Receipt Format"
                  outlined
                />
              </v-col>
              <v-col :cols="12" v-if="branchReceiptData.receipt_format !== '3' && branchReceiptData.receipt_format !== 3">
                <v-select
                  v-model="branchReceiptData.receipt_type"
                  :items="oarTypes"
                  item-text="text"
                  item-value="value"
                  class="ph-textbox"
                  label="Receipt Type"
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6" v-if="branchReceiptData.receipt_format !== '3' && branchReceiptData.receipt_format !== 3">
                <v-text-field
                  v-model="prefixValue"
                  class="ph-textbox"
                  label="Prefix"
                  outlined
                  :rules="requiredField"
                />
              </v-col>
              <v-col :cols="12" :md="6" v-if="branchReceiptData.receipt_format !== '3' && branchReceiptData.receipt_format !== 3">
                <v-text-field
                  v-model="branchReceiptData.length"
                  class="ph-textbox"
                  label="Length"
                  outlined
                  :rules="requiredField"
                  :disabled="prefixValue === ''"
                  @change="generateSeriesValue($event)"
                />
              </v-col>
              <v-col :cols="12" :md="6" v-if="branchReceiptData.receipt_format !== '3' && branchReceiptData.receipt_format !== 3">
                <v-text-field
                  v-model="currentSeriesFrom"
                  class="ph-textbox"
                  label="Series From"
                  outlined
                  type="number"
                  :hide-spin-buttons="true"
                  :disabled="prefixValue === '' || hasNoLength"
                  @change="computeSeriesRange($event, 'from')"
                />
              </v-col>
              <v-col :cols="12" :md="6" v-if="branchReceiptData.receipt_format !== '3' && branchReceiptData.receipt_format !== 3">
                <v-text-field
                  v-model="currentSeriesTo"
                  class="ph-textbox"
                  label="Series To"
                  outlined
                  type="number"
                  :hide-spin-buttons="true"
                  :disabled="prefixValue === '' || hasNoLength"
                  @change="computeSeriesRange($event, 'to')"
                />
              </v-col>
            </v-row>
            <!-- For Ereceipt -->
            <v-row v-if="branchReceiptData.receipt_format === '3' || branchReceiptData.receipt_format === 3">
              <v-col :cols="12">
                <v-text-field
                  v-model="branchReceiptData.header_address"
                  class="ph-textbox"
                  label="Header Address"
                  outlined
                  :rules="requiredField"
                />
              </v-col>
              <!-- Home City and Province -->
              <v-col :cols="12" :md="6">
                <v-autocomplete
                  v-model="branchReceiptData.city"
                  :items="collections.cities"
                  item-text="city"
                  item-value="city"
                  :return-object="false"
                  label="City"
                  :rules="requiredField"
                  color="gray darken-1"
                  @change="onCurrentCityChange"
                  class="ph-textbox"
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.province"
                  class="ph-textbox"
                  color="gray darken-1"
                  label="Province"
                  :rules="requiredField"
                  disabled
                  outlined
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.header_vat_reg_tin"
                  class="ph-textbox"
                  label="Header VAT Reg TIN"
                  outlined
                  :rules="requiredField"
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.header_tel_no"
                  class="ph-textbox"
                  label="Header Tel No."
                  outlined
                  :rules="requiredField"
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.footer_acr_no"
                  class="ph-textbox"
                  label="Footer ACR No."
                  outlined
                  :rules="requiredField"
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.footer_ptu"
                  class="ph-textbox"
                  label="Footer PTU"
                  outlined
                  :rules="requiredField"
                />
              </v-col>
              <!-- <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.footer_sn"
                  class="ph-textbox"
                  label="Footer SN"
                  outlined
                  :rules="requiredField"
                />
              </v-col> -->
              <!-- <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.footer_pn"
                  class="ph-textbox"
                  label="Footer PN"
                  outlined
                  :rules="requiredField"
                />
              </v-col> -->
              <!-- <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.footer_min"
                  class="ph-textbox"
                  label="Footer Min"
                  outlined
                  :rules="requiredField"
                />
              </v-col> -->
              <v-col cols="12" sm="6">
                <v-menu
                  ref="issueDatePicker"
                  v-model="issueDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ph-textbox"
                      color="gray darken-1"
                      label="Footer Date Issued"
                      append-icon="mdi-calendar"
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="branchReceiptData.footer_date_issued"
                    />
                  </template>
                  <v-date-picker
                    v-model="branchReceiptData.footer_date_issued"
                    :active-picker.sync="activeIssueDatePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="syncIssueDate"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="expiryDatePicker"
                  v-model="expiryDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ph-textbox"
                      color="gray darken-1"
                      label="Footer Date Expired"
                      append-icon="mdi-calendar"
                      hide-details
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="branchReceiptData.footer_date_expiry"
                    />
                  </template>
                  <v-date-picker
                    v-model="branchReceiptData.footer_date_expiry"
                    :active-picker.sync="activeExpiryDatePicker"
                    :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    @change="syncExpiryDate"
                  />
                </v-menu>
              </v-col>
              <!-- <v-col :cols="12" :md="6">
                <v-text-field
                  v-model="branchReceiptData.footer_year_valid"
                  class="ph-textbox"
                  label="Footer Years Valid"
                  outlined
                  :rules="requiredField"
                />
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-btn
              color="primary"
              outlined
              @click="closeEditDialog"
              class="ph-action-button px-10"
              x-large
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              elevation="0"
              @click="confirmAction(branchReceiptData, 'update')"
              class="ph-action-button px-10"
              x-large
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="availableSeriesDialog"
      width="800"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Available Series Numbers
          <v-spacer />
          <v-btn @click="availableSeriesDialog = false" x-small icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <br>
        <v-card-text>
          <v-row>
            <v-col :cols="12">
              <v-select
                v-model="seriesCategory"
                :items="seriesCategories"
                item-text="text"
                item-value="value"
                class="ph-textbox"
                label="Receipt Type"
                outlined
                @change="searchAvailableSeries(selectedBranchDetail, $event)"
              />
            </v-col>
          </v-row>
          <br>
          <v-data-table
            :headers="seriesHeaders"
            :items="availableSeries"
            class="custom-table"
            :calculate-widths="true"
            elevation="0"
          >
            <template v-slot:[`item.status`]="{ item }">
              <span class="ph-nonex-status-container blue--text" v-if="item.status === 1 || item.status === '1'">
                <v-icon color="blue" class="mr-1" x-small>mdi-circle</v-icon>
                Available
              </span>
              <span class="ph-nonex-status-container red--text" v-if="item.status === 2 || item.status === '2'">
                <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                Allocated
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          {{confirmActionTitle}}
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          {{confirmActionDescription}}
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeConfirmDialog"
            class="ph-action-button"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="executeAction(confirmType)"
            class="ph-action-button"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  renderToast
} from '@/utils'
import { differenceInYears } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'ReceiptMaintenance',
  computed: {
    ...mapGetters({
      collections: 'collections',
      branchList: 'manualReceipt/branchList'
    })
  },
  watch: {
    branchReceiptData: {
      handler (val) {
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.getBranchList()
  },
  data () {
    return {
      branchesList: [
        { branch_name: 'Aguirre', manual_status: 1, ereceipt_status: 1, receipt_type: 1 }
      ],
      headers: [
        {
          text: 'Branch',
          sortable: false,
          value: 'branch'
        },
        {
          text: 'Manual Enrollment Status',
          sortable: true,
          value: 'manual_enrollment_status'
        },
        {
          text: 'E-Receipt Enrollment Status',
          sortable: false,
          value: 'ereceipt_enrollment_status'
        },
        {
          text: 'Receipt Type',
          sortable: false,
          value: 'receipt_type'
        },
        {
          text: 'Use E-Receipt?',
          sortable: false,
          value: 'is_ereceipt_enabled'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Receipt Maintenance',
          disabled: true
        }
      ],
      editDialog: false,
      branchReceiptData: {},
      selectedBranchInfo: {},
      receiptTypes: [
        { text: 'Manual no printing', value: 1 },
        { text: 'Manual Looseleaf', value: 2 },
        { text: 'E-Receipt', value: 3 }
      ],
      oarTypes: [
        { text: 'Service Invoice', value: 'OR' },
        { text: 'Official Acknowledgement Receipt (OAR)', value: 'OAR' }
      ],
      activeIssueDatePicker: null,
      issueDatePicker: false,
      activeExpiryDatePicker: null,
      expiryDatePicker: false,
      requiredField: [
        v => !!v || 'This field is required'
      ],
      confirmDialog: false,
      currentSeriesFrom: '',
      currentSeriesTo: '',
      seriesFrom: '',
      seriesTo: '',
      prefixValue: '',
      seriesLength: 0,
      hasNoLength: true,
      confirmActionTitle: '',
      confirmActionDescription: '',
      confirmType: '',
      deleteBranchData: {},
      availableSeriesDialog: false,
      availableSeries: [],
      seriesHeaders: [
        {
          text: 'Series Number',
          sortable: false,
          value: 'series_no'
        },
        {
          text: 'Availability',
          sortable: false,
          value: 'status'
        }
      ],
      selectedBranchDetail: {},
      seriesCategory: '',
      seriesCategories: [
        { text: 'Official Receipt (OR)', value: 'OR' },
        { text: 'Official Acknowledgement Receipt (OAR)', value: 'OAR' },
        { text: 'All', value: '' }
      ],
      search: '',
      toggleEReceiptPayload: {}
    }
  },
  methods: {
    renderToast,
    async getBranchList () {
      await this.$store.dispatch('manualReceipt/getBranchList')
    },
    async onCurrentCityChange (city) {
      console.log('selected city:' + city)
      const selectedCity = this.collections.cities.filter(item => item.city === city)
      if (selectedCity.length === 1) this.branchReceiptData.province = selectedCity[0]?.province
      // const barangayList = await this.$store.dispatch('getBarangayList', city)
      // if (barangayList.code === 200) this.barangay = barangayList.result
    },
    // Syncs the issue datepicker value to the input field
    syncIssueDate (date) {
      this.$refs.issueDatePicker.save(date)
    },
    // Syncs the expired datepicker value to the input field
    syncExpiryDate (date) {
      this.$refs.expiryDatePicker.save(date)
    },
    // Gets the full details of the branch
    async editBranchInfo (item) {
      this.branchReceiptData = Object.assign({}, this.branchReceiptData, {})
      this.prefixValue = ''
      this.currentSeriesFrom = ''
      this.currentSeriesTo = ''

      await this.$store.dispatch('manualReceipt/getLocationInfo', item.location_id).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.editDialog = true
          this.selectedBranchInfo = res.result[0]
          this.prefixValue = res.result[0].location_code
          this.branchReceiptData = Object.assign({}, this.branchReceiptData, {
            receipt_format: res.result[0].receipt_type.toLowerCase() === 'e-receipt' ? 3 : 1,
            header_vat_reg_tin: '',
            footer_date_issued: '',
            footer_date_expiry: '',
            header_tel_no: res.result[0].tel_no,
            footer_ptu: '',
            footer_acr_no: '',
            header_address: `${res.result[0].street} ${res.result[0].barangay}`,
            city: res.result[0].city,
            footer_year_valid: ''
          })

          this.onCurrentCityChange(this.branchReceiptData.city)
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
      })
    },
    // This automatically generates the series value based on the defined prefix length
    generateSeriesValue (length) {
      this.seriesLength = length - this.prefixValue.length
      const value = '0'

      const getInitialValue = value.padStart(this.seriesLength, value)
      this.seriesFrom = getInitialValue
      this.seriesTo = getInitialValue

      if (length !== '' || length !== null || length !== undefined || length !== '0' || length !== 0) {
        this.hasNoLength = false
      } else {
        this.hasNoLength = true
      }
    },
    // Automatically adds the range of the series value from the receipt series generation
    computeSeriesRange (value, type) {
      let calculateValue = ''
      let generateSeriesFromValue = ''
      let generateSeriesToValue = ''

      switch (type) {
        case 'from':
          if (parseInt(value) > 500) {
            this.renderToast('error', 'Validation Error', 'Series value must not be grater than 500')
          } else if (parseInt(value) < 1) {
            this.renderToast('error', 'Validation Error', 'Series value must be ranging from 1-500')
          } else {
            if (parseInt(value) > parseInt(this.currentSeriesTo) && this.currentSeriesTo !== '' && this.currentSeriesTo !== null) {
              this.renderToast('error', 'Validation Error', 'The value for "Series from" cannot be greater than the value for "Series to"')
              this.currentSeriesFrom = ''
              this.seriesFrom = '0'.padStart(this.seriesLength, '0')
            } else {
              calculateValue = !isNaN(parseInt(value)) ? parseInt(value) : 0
              generateSeriesFromValue = calculateValue.toString().padStart(this.seriesLength, '0')
              this.seriesFrom = generateSeriesFromValue
              this.branchReceiptData.series_from = this.currentSeriesFrom
            }
          }
          break
        case 'to':
          if (parseInt(value) > 500) {
            this.renderToast('error', 'Validation Error', 'Series value must not be grater than 500')
          } else if (parseInt(value) < 1) {
            this.renderToast('error', 'Validation Error', 'Series value must be ranging from 1-500')
          } else {
            if (parseInt(value) < parseInt(this.currentSeriesFrom) && this.currentSeriesFrom !== '' && this.currentSeriesFrom !== null) {
              this.renderToast('error', 'Validation Error', 'The value for "Series to" cannot be less than the value for "Series from"')
              this.currentSeriesTo = ''
              this.seriesTo = '0'.padStart(this.seriesLength, '0')
            } else {
              calculateValue = !isNaN(parseInt(value)) ? parseInt(value) : 0
              generateSeriesToValue = calculateValue.toString().padStart(this.seriesLength, '0')
              this.seriesTo = generateSeriesToValue
              this.branchReceiptData.series_to = this.currentSeriesTo
            }
          }
          break
      }
    },
    closeEditDialog () {
      this.editDialog = false
      this.hasNoLength = true
      this.branchReceiptData = {}
      this.seriesFrom = ''
      this.seriesTo = ''
      this.getSeriesFrom = ''
      this.getSeriesTo = ''
      this.seriesLength = 0
      this.prefixValue = ''
      this.selectedBranchInfo = {}
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.deleteBranchData = {}
    },
    // Executes the intended action of the user
    confirmAction (item, type) {
      this.confirmType = type
      switch (type) {
        case 'update':
          if (this.$refs.updateBranchReceiptInfo.validate()) {
            if (parseInt(this.currentSeriesFrom) > 500) {
              this.renderToast('error', 'Validation Error', 'Series value must be ranging from 1-500')
            } else if (parseInt(this.currentSeriesTo) > 500) {
              this.renderToast('error', 'Validation Error', 'Series value must be ranging from 1-500')
            } else if (parseInt(this.currentSeriesFrom) < 1) {
              this.renderToast('error', 'Validation Error', 'Series value must be ranging from 1-500')
            } else if (parseInt(this.currentSeriesTo) < 1) {
              this.renderToast('error', 'Validation Error', 'Series value must be ranging from 1-500')
            } else {
              this.confirmDialog = true
              this.confirmActionTitle = 'Update Branch Information'
              this.confirmActionDescription = 'Are you sure you want to update the branch information?'
            }
          } else {
            this.renderToast('error', 'Validation Error', 'You must complete the required fields')
          }
          break
      }
    },
    // Opens the confirmation modal whether to switch receipt type or not
    async toggleEReceipt (item) {
      this.toggleEReceiptPayload = {
        receipt_type: item.is_ereceipt_enabled ? 1 : 3,
        is_enrolled_manual: item.manual_enrollment_status === 'Enrolled' ? 1 : 0,
        is_enrolled_ereceipt: item.ereceipt_enrollment_status === 'Enrolled' ? 1 : 0,
        location_id: item.location_id
      }

      if (item.ereceipt_enrollment_status !== 'Enrolled') {
        this.renderToast('error', 'E-Receipt not provisioned on this branch', 'The branch is not yet enrolled on E-Receipt. Kindly enroll the branch first.')
      } else {
        this.confirmDialog = true
        this.confirmActionTitle = 'Change Receipt Type'
        this.confirmActionDescription = `Are you sure you want to switch the Receipt type to ${item.is_ereceipt_enabled ? 'Manual No Print' : 'E-Receipt'}?`
        this.confirmType = 'toggle'
      }
    },
    // Toggles the selection either eReceipt or Manual Receipt
    async executeReceiptTypeToggle () {
      this.confirmDialog = false
      this.confirmActionTitle = ''
      this.confirmActionDescription = ''
      this.confirmType = ''
      await this.$store.dispatch('manualReceipt/updateReceiptType', this.toggleEReceiptPayload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Action Successful', 'Successfully Switched Receipt Type: ' + res.result)
          this.toggleEReceiptPayload = {}
          this.getBranchList()
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
      })
    },
    // Executes the intended actions based on the selection
    executeAction (type) {
      switch (type) {
        case 'update':
          this.updateBranchInfo()
          break
        case 'toggle': {
          this.executeReceiptTypeToggle()
          break
        }
      }

      this.confirmType = ''
    },
    // Displays the available series
    async viewAvaliableSeries (item) {
      this.selectedBranchDetail = item
      const payload = {
        location_id: item.location_id
      }

      await this.searchAvailableSeries(payload, '')
    },
    // Displays the available series with filter capability
    async searchAvailableSeries (item, type) {
      const payload = {
        location_id: item.location_id,
        type: type
      }

      await this.$store.dispatch('manualReceipt/getSeriesByBranch', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.availableSeries = []
          this.availableSeries = res.result
          this.availableSeriesDialog = true
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
      })
    },
    // This updates the branch information
    async updateBranchInfo () {
      let payload = {}
      let endpoint = ''
      let ereceiptValidYear = ''

      if (this.branchReceiptData.receipt_format === 3) {
        if (this.branchReceiptData.footer_date_expiry !== '' && this.branchReceiptData.footer_date_issued !== '') {
          ereceiptValidYear = differenceInYears(new Date(this.branchReceiptData.footer_date_expiry), new Date(this.branchReceiptData.footer_date_issued))
        }

        payload = {
          code: this.selectedBranchInfo.location_code,
          name: this.selectedBranchInfo.branch,
          header_name: this.selectedBranchInfo.branch,
          header_address: this.branchReceiptData.header_address,
          header_vat_reg_tin: this.branchReceiptData.header_vat_reg_tin,
          erp_code: '03',
          header_tel_no: this.branchReceiptData.header_tel_no,
          header_city: this.branchReceiptData.city,
          header_province: this.branchReceiptData.province,
          footer_date_issue: this.branchReceiptData.footer_date_issued,
          footer_date_expired: this.branchReceiptData.footer_date_expiry,
          footer_acr_no: this.branchReceiptData.footer_acr_no,
          footer_ptu: this.branchReceiptData.footer_ptu,
          footer_years_valid: ereceiptValidYear,
          location_id: this.selectedBranchInfo.location_id
        }

        if (this.selectedBranchInfo.ereceipt_enrollment_status === 'Enrolled') {
          endpoint = 'updateEReceipt'
          payload.id = this.selectedBranchInfo.application_id.toString()
        } else {
          endpoint = 'enrollEReceipt'
        }
      } else {
        endpoint = 'createBranchDetail'
        payload = {
          type: this.branchReceiptData.receipt_type,
          series_from: this.branchReceiptData.series_from,
          series_to: this.branchReceiptData.series_to,
          prefix: this.prefixValue,
          length: this.branchReceiptData.length,
          location_id: this.selectedBranchInfo.location_id,
          receipt_type: this.branchReceiptData.receipt_format
        }
      }

      await this.$store.dispatch(`manualReceipt/${endpoint}`, payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Success', 'Saving successful')
          this.getBranchList()
          this.prefixValue = ''
          this.currentSeriesFrom = ''
          this.currentSeriesTo = ''
          this.branchReceiptData = Object.assign({}, this.branchReceiptData, {})
        } else {
          this.renderToast('error', 'Error', res.message)
        }
        this.closeConfirmDialog()
        this.closeEditDialog()
      }).catch(err => {
        this.renderToast('error', 'Error', err)
        this.closeConfirmDialog()
      })
    }
  }
}
</script>
<style scoped>

</style>

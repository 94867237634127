<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12" :sm="4" :md="3">
          <v-select
            v-model="searchCategory"
            :items="statusList"
            item-text="text"
            item-value="value"
            class="ph-textbox ml-md-3"
            label="Status"
            outlined
          />
        </v-col>
        <v-col :cols="12" :sm="4" :md="3">
          <v-btn
            x-large
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-trusted-device-search-btn"
            @click="getTrxCancelRequestByStatus"
          >
            Filter
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="cancelRequestList"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.orig_amount`]="{ item }">
                {{ item.orig_amount !== null ? formatAmount(item.orig_amount) : '-' }}
              </template>
              <template v-slot:[`item.adjusted_amount`]="{ item }">
                {{ item.adjusted_amount !== null ? formatAmount(item.adjusted_amount) : '-' }}
              </template>
              <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDateTime(item.trx_date) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 0 || item.status === '0'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="item.status === 2 || item.status === '2'">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  Approved
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip right v-if="item.status === 0 || item.status === '0'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="cancelTrxAction(item, 'approve')" color="primary" icon>
                      <v-icon>mdi-check-bold</v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeConfirmDialog"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doConfirmAction(confirmPayload, confirmActionType)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'UserTrustedDevices',
  data () {
    return {
      searchLocation: '',
      searchCategory: '',
      statusList: [
        { text: 'Pending', value: 0 },
        { text: 'Approved', value: 2 }
      ],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Transaction Cancellation Approval',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Transaction ID',
          sortable: false,
          value: 'trx_id'
        },
        {
          text: 'Transaction Date',
          sortable: false,
          value: 'trx_date'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location_name'
        },
        {
          text: 'Original Amount',
          sortable: false,
          value: 'orig_amount'
        },
        {
          text: 'Type',
          sortable: false,
          value: 'service_code'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: '',
      confirmPayload: '',
      editDialog: false,
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      currentLocation: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      cancelRequestList: 'transactionCancel/cancelRequestList',
      collections: 'collections'
    })
  },
  async beforeMount () {
    await this.$store.dispatch('transactionCancel/getCancelRequestList', {
      status: 0,
      trx_date: this.currUser.trx_date
    })
    this.searchCategory = 0
  },
  methods: {
    renderToast,
    formatDateTime (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    cancelTrxAction (item, type) {
      this.confirmPayload = item
      this.confirmActionType = type
      this.confirmDialog = true
      this.confirmDialogTitle = 'Approve Request'
      this.confirmDialogText = 'Are you sure you want to approve this request?'
    },
    async doConfirmAction (item, type) {
      const payload = {
        id: item.trx_id,
        service_code: item.service_code,
        status: '2',
        adj_requested_ip: this.currUser.ip_address,
        adj_approved_by: this.currUser.user_id,
        adj_approved_date: this.currUser.trx_date,
        adj_approved_remarks: 'Approved!'
      }

      let confirmAction = ''

      switch (type) {
        case 'perapalit':
          confirmAction = 'transactionCancel/cancelPeraPalitRequest'
          break
        default:
          confirmAction = 'transactionCancel/cancelRequest'
      }

      await this.$store.dispatch(confirmAction, payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Action Successful', 'The request has been successfully approved')
          this.confirmDialog = false
          this.editDialog = false
          this.confirmDialogTitle = ''
          this.confirmDialogText = ''
          this.currentLocation = ''
          this.searchCategory = 2
          this.getTrxCancelRequestByStatus()
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
        this.confirmDialog = false
        this.editDialog = false
        this.confirmDialogTitle = ''
        this.confirmDialogText = ''
        this.currentLocation = ''
      })
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    closeEditDialog () {
      this.editDialog = false
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    confirmEdit () {
      if (this.$refs.updateLocation.validate()) {
        this.confirmDialog = true
      } else {
        this.renderToast('error', 'Validation Error', 'You must select a location')
      }
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    async getTrxCancelRequestByStatus () {
      await this.$store.dispatch('transactionCancel/getCancelRequestList', {
        status: this.searchCategory,
        trx_date: this.currUser.trx_date
      })
    }
  }
}
</script>
<style>
/* .browser-item-container {
  display: flex;
  flex-direction: row;
}

.item-container {
  margin-right: 10px;
}

.item-icon {
  height: 18px;
  width: auto;
  position: relative;
  top: 4px;
}

.ph-primary-button.ph-trusted-device-search-btn {
  margin: 0 10px;
  height: 49px !important;
} */
</style>

import apigee from '@/api/apigee'

export default {
  search (keyword) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-search?name=${keyword}`)
  },
  save (details) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-save', details)
  },
  update (details) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-update', details)
  },
  delete (customerID, userID) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/customer/${customerID}?user_id=${userID}`)
  },
  transactionLogs (customerID, yearNo = 5) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-trx-log?customer_id=${customerID}&year_no=${yearNo}`)
  },
  transactionCount (customerID, yearNo = 5) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-trx-cnt-log?customer_id=${customerID}&year_no=${yearNo}`)
  },
  recentlyAdded (locationCode) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-recently-added?branchcode=${locationCode}`)
  },
  dynamicFields (module) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-module-field?module=${module}`)
  },
  generateACR (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-acr-nonex', payload)
  },
  generateACRMoneygram (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-acr-moneygram', payload)
  },
  retrieveSignatures (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-signature-search', payload)
  },
  newRequests (branchCode) {
    return apigee.get(`/uspdata/v1/usp-maintenance/staging/customer?branchcode=${branchCode}`)
  },
  deleteNewRequest (id) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/staging/customer/${id}`)
  },
  clientPolicyList (firstName, lastName, birthDate) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/insurance/get-policy-list?first_name=${firstName}&last_name=${lastName}&birthdate=${birthDate}`)
  },
  checkIfDuplicate (mobileNo) {
    return apigee.get(`/uspdata/v1/usp-maintenance/customer-contacts?search_by=mobile&value=${mobileNo}`)
  },
  getCustSpecificDetails (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-selected', payload)
  }
}

import { endpoints } from '@/api/endpoints'

const digicur = endpoints.use('digicur')

export default {
  searchACR: ({ commit }, payload) => new Promise((resolve) => {
    // Call searchACR method in digicur module
    digicur.searchACR(payload).then(({ data }) => {
      const result = data.data
      commit('SET_CUSTOMER_ACR', result)
      resolve(data)
    })
  }),
  searchACRCount: ({ commit }, payload) => new Promise((resolve) => {
    // Call searchCount method in digicur module
    digicur.searchCount(payload).then(({ data }) => {
      const result = data.data
      commit('SET_ACR_COUNT', result)
      resolve(data)
    })
  }),
  downloadFile: ({ commit }, payload) => new Promise((resolve) => {
    // Call downloadFile method in digicur module
    digicur.downloadFile(payload).then(({ data }) => {
      const result = data
      resolve(result)
    })
  })
}

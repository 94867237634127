export default {
  transactions: state => state.transactions,
  inquireResult: state => state.inquireResult,
  remcos: state => state.remcos,
  currentTrx: state => state.currentTrx,
  sendoutConfirmDialog: state => state.sendoutConfirmDialog,
  sendoutOTPDialog: state => state.sendoutOTPDialog,
  sendoutServiceFees: state => state.sendoutServiceFees,
  sendoutReceiverDetails: state => state.sendoutReceiverDetails,
  sendoutCompleted: state => state.sendoutCompleted,
  sendoutFormData: state => state.sendoutFormData,
  payoutFormData: state => state.payoutFormData,
  cebuanaClientData: state => state.cebuanaClientData,
  payoutReferenceNo: state => state.payoutReferenceNo,
  isGCashTrx: state => state.isGCashTrx,
  getCebuanaGenderList: state => state.cebuanaGenderList, // Get the current state of cebuanaGenderList
  getCebuanaPurposeList: state => state.cebuanaPurposeList, // Get the current state of cebuanaPurposeList
  getCebuanaRelationList: state => state.cebuanaRelationList, // Get the current state of cebuanaRelationList
  getCebuanaOccupationList: state => state.cebuanaOccupationList, // Get the current state of cebuanaOccupationList
  getNonexRemcoList: state => state.remcoList // Get the list of Nonex Remcos
}

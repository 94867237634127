import apigee from '@/api/apigee'

export default {
  getCancelRequestList (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/transaction-cancellation-grid?status=${payload.status}&trx_date=${payload.trx_date}`)
  },
  requestCancellation (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/transaction-cancellation', payload)
  },
  requestPeraPalitCancel (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/perapalit-transaction-cancellation', payload)
  }
}

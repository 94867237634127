<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
        :items="items"
        divider=">"
      ></v-breadcrumbs>
      <v-form ref="peraPayManualEntry">
        <v-row class="ph-transaction-container">
          <v-col :cols="12" :md="4">
            <h3 class="pb-md-6 custom-heading primary--text">
              Manual Entry
            </h3>
            <p class="text-body-2">
              Please enter the necessary details
            </p>
          </v-col>
        </v-row>
        <v-row class="ph-transaction-container">
          <v-col :cols="12" :md="4">
            <v-text-field
              v-model="transactionData.trx_no"
              class="ph-textbox"
              label="Transaction Number"
              :rules="requiredRules"
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4">
            <v-text-field
              v-model="transactionData.reference_no"
              class="ph-textbox"
              label="Reference Number"
              :rules="requiredRules"
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="4">
            <v-menu
              ref="trxDatePicker"
              v-model="trxDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ph-textbox"
                  color="gray darken-1"
                  label="Transaction Date"
                  append-icon="mdi-calendar"
                  hide-details
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="transactionData.trx_date"
                />
              </template>
              <v-date-picker
                v-model="transactionData.trx_date"
                :active-picker.sync="activeTrxDatePicker"
                :rules="requiredRules"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="syncIssueDate"
              />
            </v-menu>
          </v-col>
          <v-col :cols="12" :md="6">
            <v-text-field
              v-model="transactionData.account_number"
              class="ph-textbox"
              label="Account Number"
              :rules="requiredRules"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
            v-model="transactionData.biller"
              item-text="biller_name"
              item-value="biller_code"
              label="Biller"
              :items="peraPayBillers"
              color="gray darken-1"
              class="ph-textbox"
              outlined
              placeholder="Select Biller"
              return-object
              :rules="requiredRules"
              @change="getBillerData($event)"
            >
            </v-autocomplete>
          </v-col>
          <v-col :cols="12" :md="6">
            <v-text-field
              v-model="transactionData.amount"
              class="ph-textbox"
              label="Amount"
              :rules="requiredRules"
              type="number"
              hide-spin-buttons
              outlined
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-text-field
              v-model="transactionData.other_charges"
              class="ph-textbox"
              label="Other Charges"
              :rules="amountFieldRules"
              outlined
              type="number"
              hide-spin-buttons
              readonly
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row class="ph-transaction-container action-buttons">
        <v-col>
          <v-spacer/>
          <v-btn
            color="danger"
            outlined
            text
            light
            x-large
            class="ph-primary-button ph-stepper-button ph-action-button"
            to="/pera-pay"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            x-large
            class="ph-primary-button ph-stepper-button ph-primary-execute-btn"
            @click="confirmSave"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="confirmDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Confirm Action
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          Are you sure you want to save this entry?
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="confirmDialog = false"
            class="ph-action-button"
          >
            No
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="saveEntry"
            class="ph-action-button"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'PeraPayNewTrx',
  data () {
    return {
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Bills',
          disabled: false,
          href: '/pera-bills'
        },
        {
          text: 'Pera Pay',
          disabled: false,
          href: '/pera-pay'
        },
        {
          text: 'Manual Entry',
          disabled: true,
          href: '#'
        }
      ],
      billerList: [
        { name: 'Test', value: 'Test' }
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      amountFieldRules: [
        v => (!isNaN(parseFloat(v)) && v >= 0) || 'Please insert an amount'
      ],
      transactionData: {},
      confirmDialog: false,
      trxDatePicker: false,
      activeTrxDatePicker: null
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      peraPayBillers: 'peraPay/peraPayBillers'
    })
  },
  async beforeMount () {
    await this.$store.dispatch('peraPay/getPeraPayBillers')
  },
  mounted () {
    this.transactionData = {}
  },
  methods: {
    renderToast,
    syncIssueDate (date) {
      this.$refs.trxDatePicker.save(date)
    },
    confirmSave () {
      if (this.$refs.peraPayManualEntry.validate()) {
        this.confirmDialog = true
      } else {
        this.renderToast('error', 'Incomplete Fields', 'Please complete all required fields to proceed.')
      }
    },
    async saveEntry () {
      this.confirmDialog = false

      const payload = {
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        trx_date: this.currUser.trx_date,
        billerCode: this.transactionData.biller_code,
        ServiceCode: this.transactionData.service_code,
        biller_name: this.transactionData.biller_name,
        account_number: this.transactionData.account_number,
        amount: this.transactionData.amount,
        OtherCharges: this.transactionData.other_charges,
        PartnerRefNo: this.transactionData.reference_no,
        TransactionNo: this.transactionData.trx_no,
        client_ip: this.currUser.ip_address
      }

      console.log(payload)
      await this.$store.dispatch('peraPay/submitPeraPayManualEntry', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          window.location.href = '/pera-pay'
          this.renderToast('success', 'Success', 'Manual Entry Save Successful')
        } else {
          this.renderToast('error', 'Error', res.error.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getBillerData (data) {
      this.transactionData = Object.assign({}, this.transactionData, {
        other_charges: data.other_charges,
        biller_code: data.biller_code,
        service_code: data.service_code,
        biller_name: data.biller_name
      })
    }
  }
}
</script>
<style scoped>
  .action-buttons .col {
    display: flex;
    flex-direction: row;
  }

  .ph-transaction-container .col {
    padding: 15px;
  }

  .ph-breadcrumbs {
    padding: 5px 5px;
  }

  .ph-primary-execute-btn {
    margin-right: 0;
    margin-left: 10px;
  }

  @media only screen and (min-width: 600px) {
    .ph-transaction-container {
      padding: 0 25px;
    }

    .ph-transaction-container .col {
      padding: 12px;
    }

    .ph-breadcrumbs {
      padding: 18px 12px;
      padding-left: 24px;
    }
  }
</style>

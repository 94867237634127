<template>
  <v-row class="ph-wu-success-page-container">
    <v-col cols="12">
      <h1 class="ph-wu-form-title">Success!</h1>
      <p class="ph-wu-form-subtitle">Thank you. Transaction has been completed.</p>
    </v-col>
    <v-col cols="12" md="8">
      <p class="ph-wu-success-receiver-name"><b>Summary Overview:</b></p>
      <v-row class="ph-wu-success-details">
        <v-col cols="12" sm="5">
          <v-simple-table class="ph-transfer-summary-table">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in peraDaliOverview"
                  :key="item.name"
                >
                  <td class="transfer-info-title">{{ item.name }}</td>
                  <td class="transfer-info-value">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        elevation="0"
        class="ph-primary-button ph-primary-execute-btn"
        @click="backToPeraDali"
      >
        Back to Pera Dali
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatMTCN, renderAVPSpiel, getAge } from '@/utils'
import numeral from 'numeral'

export default {
  name: 'PeraDaliSuccess',
  data () {
    return {
      loyaltyCardNumber: '',
      loyaltyPoints: '',
      mtcn: '',
      crossSellingData: {},
      peraDaliOverview: [
        { name: 'Provider', value: '-' },
        { name: 'Reference Number', value: '-' },
        { name: 'OAR Number', value: '-' },
        { name: 'Service Fee', value: '-' },
        { name: 'Amount to Collect', value: '-' },
        { name: 'Total Amount to Collect', value: '-' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      peraDaliCurrentTrx: 'dali/peraDaliCurrentTrx',
      selectedCustomer: 'customers/selectedCustomer',
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    if (this.selectedCustomer) {
      const peraDaliPayloadData = this.peraDaliCurrentTrx

      // const payload = {
      //   product: 'cash-in',
      //   amount: currentTrx.principal_amount,
      //   gender: this.selectedCustomer.gender,
      //   age: this.getAge(this.selectedCustomer.birth_date),
      //   type: this.selectedCustomer.civil_status
      // }

      // await this.$store.dispatch('getCrossSellingSpiel', payload).then(res => {
      //   this.crossSellingData = res.result[0]
      //   this.renderAVPSpiel(this.crossSellingData)
      // }).catch(err => {
      //   console.log(err)
      // })

      this.peraDaliOverview = [
        { name: 'Provider', value: peraDaliPayloadData.provider ? peraDaliPayloadData.provider : '-' },
        { name: 'Reference Number', value: peraDaliPayloadData.reference_no ? peraDaliPayloadData.reference_no : '-' },
        { name: 'OAR Number', value: peraDaliPayloadData.form_number ? peraDaliPayloadData.form_number : '-' },
        { name: 'Service Fee', value: peraDaliPayloadData.service_fee ? `PHP ${this.formatAmount(peraDaliPayloadData.service_fee)}` : '-' },
        { name: peraDaliPayloadData.trx_type === 'D' ? 'Amount to Withdraw' : 'Amount to Collect', value: peraDaliPayloadData.principal_amount ? `PHP ${this.formatAmount(peraDaliPayloadData.principal_amount)}` : '-' },
        { name: peraDaliPayloadData.trx_type === 'D' ? 'Total Amount to Release' : 'Total Amount to Collect', value: peraDaliPayloadData.total_amount ? `PHP ${this.formatAmount(peraDaliPayloadData.total_amount)}` : '-' }
      ]
    }
  },
  methods: {
    formatMTCN,
    renderAVPSpiel,
    getAge,
    backToPeraDali () {
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', null)
      this.$store.commit('dali/SET_PERA_DALI_CURRENT_PAGE', null)
      this.$store.commit('dali/SET_PERA_DALI_RESPONSE_DATA', null)
      this.$store.commit('dali/SET_PERA_DALI_ACCOUNT_NO', '')

      window.location.href = '/pera-dali'
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    generateOAR () {
      window.open(`/receipt?ref=${this.peraDaliCurrentTrx.form_number}&trx_type=peradali&type=OAR`, '_blank')
    }
  }
}
</script>
<style scoped>
  .ph-wu-form-title {
    font-family: 'Baloo Extra Bold' , sans-serif;
    font-size: 28px;
    color: #1A2791;
  }

  .ph-wu-success-detail {
    border: 1px solid #CCC !important;
    border-radius: 10px !important;
    display: flex;
    padding: 10px 18px;
  }

  .ph-wu-success-detail-text {
    margin-left: 10px;
    margin-top: 4px;
  }

  .ph-primary-button {
    background: #FFF !important;
    color: #1A2791;
    border: 2px solid #1A2791;
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    transition: .2s;
    height: 60px !important;
    width: 100%;
    margin: 10px 0;
  }

  .ph-primary-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    border: 2px solid #fff;
  }

  .ph-primary-button.ph-primary-execute-btn {
    background: #1A2791;
    color: #fff;
    font-size: 14px;
  }

  .ph-primary-button.ph-oar-button {
    margin-right: 10px;
  }

  .transfer-info-title {
    font-weight: bolder;
    padding-left: 0 !important;
  }

  .transfer-info-value {
    text-align: right;
    padding-right: 0 !important;
  }

  .ph-transfer-summary-table {
    background-color: transparent !important;
  }

  .ph-action-button {
    margin-right: 10px;
  }

  @media only screen and (min-width: 600px) {
    .ph-primary-button {
      width: 250px;
      margin-left: auto;
    }
  }
</style>

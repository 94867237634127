<template>
  <v-container fluid>
    <v-breadcrumbs class="px-2 px-md-1 mx-md-4" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-row class="px-2 mt-md-3 px-md-5 mb-md-12" no-gutters>
      <v-col :cols="12" :sm="4" :md="4">
        <v-text-field
          v-model="search"
          class="ph-textbox"
          placeholder="Search Transaction"
          append-icon="mdi-magnify"
          hide-details
          outlined
        />
      </v-col>
      <v-col class="mt-2 mt-sm-4 mt-md-8" :cols="12">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :search="search"
          class="custom-table"
          calculate-widths
        >
          <template v-slot:[`item.user`]="{ item }">
            <span v-if="item.user">
              <v-avatar class="mr-1" size="26px" :style="avatarColor(item.user)">
                <span class="white--text text-body-2">{{ avatarInitial(item.user) }}</span>
              </v-avatar>
              {{ toProperCase(item.user) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.customer_name`]="{ item }">
            <span v-if="item.customer_name">
              <v-avatar class="mr-1" size="26px" :style="avatarColor(item.customer_name)">
                <span class="white--text text-body-2">{{ avatarInitial(item.customer_name) }}</span>
              </v-avatar>
              {{ toProperCase(item.customer_name) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.forex_amount`]="{ item }">
            <span v-if="item.forex_amount">
              {{ toMoney(item.forex_amount, '$') }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.forex_rate`]="{ item }">
            <span v-if="item.forex_rate">
              {{ toMoney(item.forex_rate) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.peso_amount`]="{ item }">
            <span v-if="item.peso_amount">
              {{ toMoney(item.peso_amount) }}
            </span>
            <span v-else>
              —
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="reprintMCPrePrint(item)" v-if="item.is_cancel === '0'">
                  <v-list-item-title>
                    Print MC Pre-Printed
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="printOR(item)" v-if="item.is_cancel === '0' && item.type === 'SELL' && currUser.location_type === '1'">
                  <v-list-item-title>
                    Print OR
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip left v-if="item.is_cancel == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="requestCancellation(item)" color="primary" icon>
                  <v-icon>mdi-marker-cancel</v-icon>
                </v-btn>
              </template>
              <span>Request for Transaction Cancellation</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="sellTransaction"
            color="#F76F34"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/mc-sell.svg" width="18" />
          </v-btn>
        </template>
        <span>SELL</span>
      </v-tooltip>
      <v-tooltip left content-class="ph-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="buyTransaction"
            color="#46B746"
            v-bind="attrs"
            v-on="on"
            small
            fab
          >
            <img src="@/assets/images/mc-buy.svg" width="18" />
          </v-btn>
        </template>
        <span>BUY</span>
      </v-tooltip>
    </v-speed-dial>
    <v-dialog
      v-model="cancelTrxDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Cancel Selected Transaction
        </v-card-title>
        <br>
        <v-card-text class="ph-dialog-header">
          <p>
            Are you sure you want to request this transaction for cancellation?
          </p>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            @click="closeCancelTrxDialog"
            class="ph-action-button"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="executeRequestCancel"
            class="ph-action-button"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  avatarInitial,
  avatarColor,
  toProperCase,
  toMoney,
  renderToast
} from '@/utils'

export default {
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  data () {
    return {
      fab: false,
      search: '',
      transactions: [],
      breadcrumbsItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Pera Palit',
          disabled: true
        }
      ],
      headers: [
        {
          text: 'Teller',
          sortable: true,
          value: 'user',
          width: '170px'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'type',
          width: '80px'
        },
        {
          text: 'Customer',
          sortable: true,
          value: 'customer_name',
          width: '170px'
        },
        {
          text: 'OR/Ref No.',
          sortable: true,
          value: 'form_number',
          width: '140px'
        },
        {
          text: 'Currency',
          sortable: true,
          value: 'currency_code',
          width: '100px'
        },
        {
          text: 'Forex Amount',
          sortable: true,
          value: 'forex_amount',
          width: '140px'
        },
        {
          text: 'Rate',
          sortable: true,
          value: 'forex_rate',
          width: '140px'
        },
        {
          text: 'PHP Amount',
          sortable: true,
          value: 'peso_amount',
          width: '140px'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '100px'
        }
      ],
      cancelTrxDialog: false,
      cancelRequestPayload: {}
    }
  },
  methods: {
    async printOR (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptID',
        {
          location_id: this.currUser.location_id,
          receipt_type: '1',
          or_series_no: data.form_number,
          oar_series_no: '',
          trx_date: this.currUser.trx_date
        }
      )
      this.getOARDetail(res.result)
    },
    async getOARDetail (data) {
      const res = await this.$store.dispatch('eReceipt/getEreceiptByID', data.ereceipt_api_id)
      this.downloadOAR(res.result)
    },
    async downloadOAR (data) {
      var payload = { application_id: data.application_id, ereceipt_api_id: data.id }
      const res = await this.$store.dispatch('eReceipt/downloadEreceipt', payload)
      var newBlob = new Blob([res], { type: 'application/pdf' })
      var newFileURL = URL.createObjectURL(newBlob)
      window.open(newFileURL, '_blank')
    },
    reprintMCPrePrint (data) {
      if (data) {
        window.open(`/receipt?ref=${data.form_number}&trx_type=mc&type=PRE-PRINT`, '_blank')
      }
    },
    reprintOR (data) {
      if (data && data.type === 'SELL') {
        window.open(`/receipt?ref=${data.form_number}&trx_type=mc&type=OR`, '_blank')
      }
    },
    buyTransaction () {
      this.$store.dispatch('moneyChange/resetForm')
      this.$router.push({ path: '/pera-palit/buy' })
    },
    sellTransaction () {
      this.$store.dispatch('moneyChange/resetForm')
      this.$router.push({ path: '/pera-palit/sell' })
    },
    async getGrid () {
      const req = await this.$store.dispatch('moneyChange/getGrid', {
        trxDate: this.currUser.trx_date,
        locationID: this.currUser.location_id
      })

      if (req.code === 200) {
        this.transactions = req.result
      }
    },
    requestCancellation (item) {
      this.cancelRequestPayload = item
      this.cancelTrxDialog = true
    },
    closeCancelTrxDialog () {
      this.cancelRequestPayload = {}
      this.cancelTrxDialog = false
    },
    async executeRequestCancel () {
      const item = this.cancelRequestPayload

      const payload = {
        id: item.trx_mc_id,
        is_cancel: 1,
        cancel_remarks: ''
      }

      await this.$store.dispatch('transactionCancel/requestPeraPalitCancel', payload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          this.renderToast('success', 'Success', 'The Transaction Cancellation has been successfully requested.')
          this.closeCancelTrxDialog()
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      })
    },
    avatarInitial,
    avatarColor,
    toProperCase,
    toMoney,
    renderToast
  },
  beforeMount () {
    this.getGrid()
  }
}
</script>

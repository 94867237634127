import apigee from '@/api/apigee'

export default {
  getPeraPayManualGrid (payload) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/perapay-manual-entry-grid?location_id=${payload.location_id}&trx_date=${payload.trx_date}`)
  },
  getPeraPayBillers () {
    return apigee.get('/uspdata/v1/usp-maintenance/maintenance/perapay-manual-entry-billers')
  },
  submitPeraPayManualEntry (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/perapay-manual-entry-save', payload)
  },
  deletePeraPayManualEntry (id) {
    return apigee.delete(`/uspdata/v1/usp-maintenance/maintenance/perapay-manual-entry-delete/${id}`)
  },
  assignSingleManualEntryOAR (payload) {
    return apigee.put(`/uspdata/v1/usp-maintenance/maintenance/manual-entry-billspayment/${payload.id}`, payload.data)
  },
  assignMultipleManualEntryOAR (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/maintenance/manual-entry-billspayment', payload)
  }
}

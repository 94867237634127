import { endpoints } from '@/api/endpoints'

const peraPay = endpoints.use('peraPay')

export default {
  getPeraPayManualGrid: ({ commit }, payload) => new Promise((resolve, reject) => {
    peraPay.getPeraPayManualGrid(payload)
      .then(({ data }) => {
        commit('SET_PERA_PAY_MANUAL_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  getPeraPayBillers: ({ commit }) => new Promise((resolve, reject) => {
    peraPay.getPeraPayBillers()
      .then(({ data }) => {
        commit('SET_PERA_PAY_BILLER_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  submitPeraPayManualEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    peraPay.submitPeraPayManualEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  deletePeraPayManualEntry: ({ commit }, payload) => new Promise((resolve, reject) => {
    peraPay.deletePeraPayManualEntry(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  assignSingleManualEntryOAR: ({ commit }, payload) => new Promise((resolve, reject) => {
    console.log(payload)
    peraPay.assignSingleManualEntryOAR(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  }),
  assignMultipleManualEntryOAR: ({ commit }, payload) => new Promise((resolve, reject) => {
    peraPay.assignMultipleManualEntryOAR(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err.response)
      })
  })
}

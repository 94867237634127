export default {
  transactions: state => state.transactions,
  cashInCurrentTrx: state => state.cashInCurrentTrx,
  cashOutCurrentTrx: state => state.cashOutCurrentTrx,
  cashInCurrentPage: state => state.cashInCurrentPage,
  cashOutCurrentPage: state => state.cashOutCurrentPage,
  totalAmount: state => state.totalAmount,
  convenienceFee: state => state.convenienceFee,
  cicoProvider: state => state.cicoProvider
}

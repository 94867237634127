import { endpoints } from '@/api/endpoints'

const trxCancelRequest = endpoints.use('trxCancelRequest')
console.log(trxCancelRequest)

export default {
  getCancelRequestList: ({ commit }, payload) => new Promise((resolve, reject) => {
    trxCancelRequest.getCancelRequestList(payload)
      .then(({ data }) => {
        commit('SET_CANCEL_REQUEST_LIST', data.result)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  cancelRequest: ({ commit }, payload) => new Promise((resolve, reject) => {
    trxCancelRequest.requestCancellation(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  }),
  requestPeraPalitCancel: ({ commit }, payload) => new Promise((resolve, reject) => {
    trxCancelRequest.requestPeraPalitCancel(payload)
      .then(({ data }) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
  })
}

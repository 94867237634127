export default {
  SET_CASHIN_CURRENT_PAGE (state, payload) {
    state.cashInCurrentPage = payload
  },
  SET_CASHOUT_CURRENT_PAGE (state, payload) {
    state.cashOutCurrentPage = payload
  },
  SET_CASHIN_CURRENT_TRX (state, payload) {
    state.cashInCurrentTrx = payload
  },
  SET_CASHOUT_CURRENT_TRX (state, payload) {
    state.cashOutCurrentTrx = payload
  },
  SET_TRANSACTIONS (state, payload) {
    state.transactions = payload
  },
  SET_CONVENIENCE_FEE (state, fee) {
    state.convenienceFee = fee
  },
  SET_TOTAL_AMOUNT (state, amount) {
    state.totalAmount = amount
  },
  SET_CICO_PROVIDER (state, amount) {
    state.cicoProvider = amount
  }
}

import apigee from '@/api/apigee'
import api from '@/api'
import consolidator from '@/api/consolidator'

export default {
  transactions (trxDate, locationID, trxType, wuTrxCategory) {
    return apigee.get(`/uspdata/v1/usp-transaction/trx/remit?trx_date=${trxDate}&location_id=${locationID}&trx_type=${trxType}` + (wuTrxCategory !== '' ? `&wu_trx_category=${wuTrxCategory}` : ''))
  },
  consolidator (payload) {
    return consolidator.post('/v1/remit/nonex/consolidator/search-all', payload)
  },
  payoutStatus (controlNo) {
    return apigee.get(`/uspdata/v1/usp-transaction/maintenance/adjustment/trx/search?control_number=${controlNo}`)
  },
  remcos () {
    return apigee.get('/uspdata/v1/usp-transaction/maintenance/remco')
  },
  cebuanaIntUpdateBeneficiary (payload) {
    return apigee.post('/remit/v1/cebuana-international/update-beneficiary', payload)
  },
  executePayout (endpoint, payload) {
    return apigee.post('/remit/v1/' + endpoint, payload)
  },
  retryPayout (endpoint, payload) {
    return apigee.post('/remit/v1/' + endpoint, payload)
  },
  sendOutServiceFee (amount, agentCode, branchCode) {
    return apigee.get(`/uspdata/v1/usp-maintenance/nonex-sf-all?principal_amount=${amount}&agent_code=${agentCode}&branch_code=${branchCode}`)
  },
  remcoServiceFee (amount, remcoID) {
    return apigee.get(`/uspdata/v1/usp-maintenance/nonex-sf?principal_amount=${amount}&remco_id=${remcoID}`)
  },
  sendout (endpoint, payload) {
    return apigee.post(`/remit/v1/${endpoint}`, payload)
  },
  retrySendout (endpoint, payload) {
    return apigee.post('/remit/v1/' + endpoint, payload)
  },
  cebuanaFindClient (customer) {
    return apigee.get(`/remit/v1/cebuana/find-client?first_name=${customer.first_name}&last_name=${customer.last_name}&birth_date=${customer.birth_date}&client_number=`)
  },
  cebuanaAddClient (payload) {
    return apigee.post('/remit/v1/cebuana/add-client', payload)
  },
  cebuanaViewBeneficiary (clientID) {
    return apigee.get(`/remit/v1/cebuana/beneficiary-by-sender?sender_client_id=${clientID}`)
  },
  cebuanaAddBeneficiary (payload) {
    return apigee.post('/remit/v1/cebuana/add-beneficiary', payload)
  },
  cebuanaSendExecute (payload) {
    return apigee.post('/remit/v1/cebuana/add-beneficiary', payload)
  },
  getRemcoStateCity (state, city, remcoId) {
    return apigee.get(`/uspdata/v1/usp-maintenance/maintenance/states?state=${state}&city=${city}&remco_id=${remcoId}`)
  },
  uploadACR (payload) {
    return apigee.post('/uspdata/v1/usp-maintenance/customer-acr-upload', payload)
  },
  gcashInquireTrx (payload) {
    return apigee.post('/remit/v1/gcash-payout/inquire', payload)
  },
  getUnitellerOccupation () {
    return apigee.get('/remit/v1/uniteller/occupations')
  },
  cebuanaGetGender () {
    return apigee.get('/remit/v1/cebuana/get-gender')
  },
  cebuanaGetRelation () {
    return apigee.get('/remit/v1/cebuana/get-relation')
  },
  cebuanaGetPurpose () {
    return apigee.get('/remit/v1/cebuana/get-purpose')
  },
  cebuanaGetOccupation () {
    return apigee.get('/remit/v1/cebuana/get-occupation')
  },
  getRemcoList () {
    return api.get('/v1/maintenance/api/collections/get-nonex-regex')
  },
  inquire (payload) {
    return consolidator.post('/v1/remit/nonex/consolidator/search-remco', payload)
  },
  wuVerification (payload) {
    return api.post('/v1/remit/wu/wu/trx/send-money/store', payload)
  },
  cebuanaVerification (payload) {
    return apigee.post('/remit/v1/cebuana/send', payload)
  },
  USSCVerification (payload) {
    return apigee.post('/remit/v1/ussc/send', payload)
  }
}

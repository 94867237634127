<template>
  <div class="ph-container">
    <v-container fluid class="ph-nonex-container">
      <v-breadcrumbs
      :items="items"
      divider=">"
      ></v-breadcrumbs>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12" :sm="4" :md="3">
          <v-select
            v-model="searchCategory"
            :items="statusList"
            item-text="text"
            item-value="value"
            class="ph-textbox ml-md-3"
            label="Status"
            outlined
          />
        </v-col>
        <v-col :cols="12" :sm="4" :md="3">
          <v-btn
            x-large
            color="primary"
            elevation="0"
            class="ph-primary-button ph-primary-execute-btn ph-trusted-device-search-btn"
            @click="filterExcessFundList(searchCategory)"
          >
            Filter
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-md-12 mb-md-10 ma-5" no-gutters>
        <v-col :cols="12">
          <v-card elevation="1" class="ph-nonex-datatable-container">
            <v-data-table
              :headers="headers"
              :items="adjListFiltered"
              class="custom-table"
              :calculate-widths="true"
            >
              <template v-slot:[`item.requested_date`]="{ item }">
                {{ formatDate(item.requested_date) }}
              </template>
              <!-- <template v-slot:[`item.trx_date`]="{ item }">
                {{ formatDate(item.trx_date) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ !isNaN(item.amount) ? formatAmount(item.amount) : 0 }}
              </template> -->
              <template v-slot:[`item.action_taken`]="{ item }">
                {{ item.action_taken.toUpperCase() }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="ph-nonex-status-container red--text" v-if="item.status === 1 || item.status === '1'">
                  <v-icon color="orange" class="mr-1" x-small>mdi-circle</v-icon>
                  Pending
                </span>
                <span class="ph-nonex-status-container green--text" v-if="parseInt(item.status) >= 2 && parseInt(item.status) <= 4 || item.status === '6' || item.status === 6">
                  <v-icon color="green" class="mr-1" x-small>mdi-circle</v-icon>
                  {{ getApprovedStatusDesc(item.status) }}
                </span>
                <span class="ph-nonex-status-container red--text" v-if="item.status === 5 || item.status === '5'">
                  <v-icon color="red" class="mr-1" x-small>mdi-circle</v-icon>
                  Disapproved
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="displayAdjData(item)" color="primary" icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Info</span>
                </v-tooltip> -->
                <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="displayAdjData(item, 'approve')" color="primary" icon>
                      <v-icon>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>
                <v-tooltip right v-if="item.status === 1 || item.status === '1'" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="item.status === 1 || item.status === '1'" @click="displayAdjData(item, 'disapprove')" color="primary" icon>
                      <v-icon>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Disapprove</span>
                </v-tooltip>
                <!-- <v-btn v-if="item.status !== 1 && item.status !== '1'" disabled color="primary" icon>
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="adjSummaryDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            Adjustment Confirmation
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <br>
            <div class="ph-confirm-subtitle">
              <p><b>Kindly check all the details before {{ adjDecision === 'approve' ? 'approving' : 'disapproving' }} the request.</b></p>
            </div>
            <br>
            <table class="ph-payout-summary" v-if="Object.keys(adjSummaryData).length !== 0 && adjSummaryDialog">
              <tr>
                <td><strong>Transaction Details</strong></td>
              </tr>
              <tr>
                <td>Control Number</td>
                <td>{{ adjSummaryData.control_number }}</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td>PHP</td>
              </tr>
              <tr>
                <td>Remittance Company</td>
                <td>{{ getRemcoName(adjSummaryData.remco_id) }}</td>
              </tr>
              <tr>
                <td>Service Charge</td>
                <td>{{ adjSummaryData.service_charge }}</td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{{ adjSummaryData.total_amount }}</td>
              </tr>
              <br>
              <tr>
                <td><strong>Sender Details</strong></td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{{ adjSummaryData.trx_data.sender_name }}</td>
              </tr>
              <tr>
                <td>Birth Date</td>
                <td>{{ adjSummaryData.trx_data.birth_date }}</td>
              </tr>
              <tr>
                <td>ID Type</td>
                <td>{{ adjSummaryData.trx_data.id_type }}</td>
              </tr>
              <tr>
                <td>ID Number</td>
                <td>{{ adjSummaryData.trx_data.id_number }}</td>
              </tr>
              <tr>
                <td>Present Address</td>
                <td v-if="adjSummaryData.trx_data.current_address !== null && adjSummaryData.trx_data.current_address !== undefined && adjSummaryData.trx_data.current_address !== {}">
                  {{ `${adjSummaryData.trx_data.current_address.address_1},
                  ${adjSummaryData.trx_data.current_address.address_2 ? adjSummaryData.trx_data.current_address.address_2 + ',': ''}
                  ${adjSummaryData.trx_data.current_address.barangay},
                  ${adjSummaryData.trx_data.current_address.city},
                  ${adjSummaryData.trx_data.current_address.province},
                  ${adjSummaryData.trx_data.current_address.country},
                  ${adjSummaryData.trx_data.current_address.zip_code}` }}
                </td>
                <td v-else>
                  {{ `${adjSummaryData.trx_data.address},
                  ${adjSummaryData.trx_data.barangay},
                  ${adjSummaryData.trx_data.city},
                  ${adjSummaryData.trx_data.province},
                  ${adjSummaryData.trx_data.country},
                  ${adjSummaryData.trx_data.zip_code}` }}
                </td>
              </tr>
              <br>
              <tr>
                <td><strong>Receiver Details</strong></td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{{ adjSummaryData.trx_data.receiver_name }}</td>
              </tr>
              <br>
              <tr>
                <td><strong>Adjustment Details</strong></td>
              </tr>
              <tr>
                <td>Adjustment Type</td>
                <td>{{ adjSummaryData.trx_data.adj_category.toUpperCase() }}</td>
              </tr>
            </table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              class="ph-action-button"
              @click="adjSummaryDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ph-action-button"
              elevation="0"
              @click="adjApprovalAction(adjSummaryData, adjDecision)"
            >
              {{adjDecision === 'approve' ? 'Approve Request' : 'Disapprove Request'}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="disapproveDialog" :fullscreen="$vuetify.breakpoint.mdAndDown" persistent width="50%">
        <v-card class="rounded-lg">
          <v-card-title class="py-md-6">
            <h3 class="custom-heading primary--text">
              Add Disapprove Remarks
            </h3>
            <v-spacer />
            <v-btn @click="disapproveDialog = false" small icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="disapproveForm">
              <v-row class="mb-md-8">
                <v-col :cols="12" :md="12">
                  <v-textarea
                    v-model="disapproveReason"
                    label="Reason for disapproving *"
                    :rules="requiredRules"
                    rows="10"
                    filled
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="disapproveDialog = false"
              class="ph-action-button"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="submitDisapproveRequest"
              class="ph-action-button"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        width="500"
        persistent
      >
        <v-card>
          <v-card-title class="ph-dialog-title">
            {{ confirmDialogTitle }}
          </v-card-title>
          <br>
          <v-card-text class="ph-dialog-header">
            {{ confirmDialogText }}
          </v-card-text>
          <br>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="closeConfirmDialog"
              class="ph-action-button"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="doConfirmAction(confirmPayload, confirmActionType)"
              class="ph-action-button"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import numeral from 'numeral'
import { renderToast } from '@/utils'
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

export default {
  name: 'NonexAdjustmentApproval',
  data () {
    return {
      searchLocation: '',
      searchCategory: '',
      statusList: [
        { text: 'All', value: 0 },
        { text: 'Pending', value: 1 },
        { text: 'Amended', value: 2 },
        { text: 'Cancelled', value: 3 },
        { text: 'Refunded', value: 4 },
        { text: 'Disapproved', value: 5 },
        { text: 'Unpaid', value: 6 }
      ],
      items: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Admin',
          disabled: false,
          href: '#'
        },
        {
          text: 'Nonex Adjustment Approval',
          disabled: true,
          href: '#'
        }
      ],
      headers: [
        {
          text: 'Transaction Date',
          sortable: true,
          value: 'trx_date'
        },
        {
          text: 'Request Date',
          sortable: true,
          value: 'requested_date'
        },
        {
          text: 'Location Name',
          sortable: true,
          value: 'location_name'
        },
        {
          text: 'Control Number',
          sortable: true,
          value: 'control_number'
        },
        {
          text: 'Type',
          sortable: true,
          value: 'action_taken'
        },
        {
          text: 'Remarks',
          sortable: true,
          value: 'remarks'
        },
        {
          text: 'Requested By',
          sortable: true,
          value: 'requesting_fla'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogText: '',
      confirmActionType: '',
      confirmPayload: '',
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      currentLocation: '',
      adjListFiltered: [],
      adjSummaryDialog: false,
      disapproveDialog: false,
      adjSummaryData: {},
      disapproveReason: '',
      adjDecision: ''
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      adjApprovalList: 'nonexAdjustment/adjApprovalList',
      cebuanaClientData: 'nonex/cebuanaClientData',
      collections: 'collections'
    })
  },
  async beforeMount () {
    await this.getPendingList()
  },
  methods: {
    renderToast,
    formatDate (date) {
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    formatAmount (amount) {
      return numeral(amount).format('0,0.00')
    },
    adjApprovalAction (item, type) {
      this.confirmPayload = item
      this.confirmActionType = type

      switch (type) {
        case 'approve':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Approve Request'
          this.confirmDialogText = 'Are you sure you want to approve this request?'
          break
        case 'disapprove':
          this.confirmDialog = true
          this.confirmDialogTitle = 'Disapprove Request'
          this.confirmDialogText = 'Are you sure you want to reject this request?'
          break
        case 'disapproveComment':
          this.disapproveDialog = true
          break
      }
    },
    async doConfirmAction (item, type) {
      const adjTransactionData = item.trx_data
      let setAdjStatus = ''
      let newBeneficiaryId = ''
      let isExistingCebuanaTrx = ''

      console.log(adjTransactionData)

      if (item.action_taken === 'amend' && type === 'approve') {
        switch (item.remco_id) {
          case 9:
            isExistingCebuanaTrx = await this.checkIfExistingTransaction(item)
            if (isExistingCebuanaTrx) {
              newBeneficiaryId = this.cebuanaAddBeneficiary(adjTransactionData)
            } else {
              newBeneficiaryId = ''
              this.renderToast('error', 'Error', 'Transaction not found')
            }
            break
          default :
            newBeneficiaryId = ''
        }
      }

      if (type === 'approve') {
        switch (item.action_taken) {
          case 'amend':
            setAdjStatus = 2
            break
          case 'cancel':
            setAdjStatus = 3
            break
          case 'refund':
            setAdjStatus = 4
            break
          case 'unpay':
            setAdjStatus = 6
            break
        }
      } else {
        setAdjStatus = 5
      }

      const payload = {
        approved_by: this.currUser.user_id,
        approved_date: format(new Date(), 'yyyy-MM-dd'),
        approved_ip: this.currUser.ip_address,
        status: setAdjStatus,
        remarks: this.disapproveReason !== '' ? this.disapproveReason : '',
        trx_id: this.adjSummaryData.trx_data.id,
        action_taken: item.action_taken,
        control_number: item.control_number,
        new_beneficiary_id: newBeneficiaryId,
        user_id: this.currUser.user_id,
        amendment_fee: '0',
        principal_amount: item.principal_amount,
        reference_number: item.reference_number,
        remco_id: item.remco_id,
        trx_adjustment_id: item.trx_id,
        trx_date: item.trx_date
      }

      let confirmTypeText = ''

      switch (type) {
        case 'approve':
          confirmTypeText = 'The request has been successfully approved'
          break
        case 'disapprove':
          confirmTypeText = 'The request has been successfully rejected'
          break
      }

      let isSameDayTrx = false
      let adjustmentEndpoint = ''

      if (item.trx_date === payload.approved_date) {
        isSameDayTrx = true
      }

      if (item.action_taken === 'refund' || item.action_taken === 'cancel') {
        const res = await this.$store.dispatch('eReceipt/getEreceiptID',
          {
            location_id: this.currUser.location_id,
            receipt_type: '2',
            or_series_no: '',
            oar_series_no: adjTransactionData.form_number,
            trx_date: item.trx_date
          }
        )

        if (res.code >= 200 && res.code <= 299) {
          const cancelPayload = {
            id: item.id,
            data: {
              cancel_cashier_id: item.requested_by,
              cancel_cashier_name: item.requesting_fla,
              cancel_date: format(new Date(), 'yyyy-MM-dd'),
              cancel_reason: this.disapproveReason,
              cancel_remark: this.disapproveReason
            }
          }

          await this.$store.dispatch('eReceipt/cancelEReceipt', cancelPayload).then(res => {
            if (res.code >= 200 && res.code <= 299) {
              this.renderToast('success', 'Success', 'E-Receipt cancellation successful')
            } else {
              this.renderToast('error', 'Error', res.message)
            }
          }).catch(err => {
            this.renderToast('error', 'Error', err)
          })
        }
      }

      if (payload.remco_id === 20 && payload.status === 6 && isSameDayTrx === true) {
        payload.currency = 'PHP'
        payload.location_id = this.currUser.location_id
        payload.location_code = `PERAHUB-${this.currUser.location_code}`
        payload.location_name = this.currUser.location_name

        adjustmentEndpoint = 'unpayUnitellerTrx'
      } else if (payload.remco_id === 21 && payload.status === 6 && isSameDayTrx === true) {
        payload.currency = 'PHP'
        payload.location_id = this.currUser.location_id
        payload.location_code = 'PERAH-00001'
        payload.location_name = this.currUser.location_name
        payload.principal_amount = item.principal_amount
        payload.action = 'reverse'

        adjustmentEndpoint = 'unpayRemitlyTrx'
      } else if (payload.remco_id === 22 && payload.status === 2 && isSameDayTrx === true) {
        adjustmentEndpoint = 'amendAyannahTrx'
      } else if (payload.remco_id === 22 && payload.status === 3 && isSameDayTrx === true) {
        adjustmentEndpoint = 'cancelAyannahTrx'
      } else {
        adjustmentEndpoint = 'updateAdjustmentTrx'
      }

      const nonexAdjustmentPayload = {
        id: item.id,
        data: payload
      }

      await this.$store.dispatch(`nonexAdjustment/${adjustmentEndpoint}`, nonexAdjustmentPayload).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          if (setAdjStatus !== 5) {
            this.generateACR(item)
          }

          this.renderToast('success', 'Action Successful', confirmTypeText)
          this.confirmDialogTitle = ''
          this.confirmDialogText = ''
          this.currentLocation = ''
          this.disapproveReason = ''
          this.confirmDialog = false
          this.disapproveDialog = false
          this.adjSummaryDialog = false
          this.getPendingList()
        } else {
          this.renderToast('error', 'Error', res.message)
        }
      })
    },
    async checkIfExistingTransaction (item) {
      const generateDeviceId = `${this.currUser.user_id}${this.currUser.location_id}`
      const branchCode = String('00000' + this.currUser.location_id).slice(-5)

      const payload = {
        location_name: this.currUser.location_name,
        reference_number: item.reference_number,
        control_number: item.control_number,
        location_id: this.currUser.location_id,
        user_id: this.currUser.user_id,
        branch: this.currUser.location_name,
        international_partner_code: 'PL0005',
        // agent_code: '01030063',
        agent_code: this.currUser.location_code,
        agent_id: '84424911',
        branch_code: branchCode,
        location_code: `PERAHUB-${this.currUser.location_code}`,
        currency: 'PHP',
        device_id: Buffer.from(generateDeviceId).toString('base64')
      }

      let isExisting = ''

      await this.$store.dispatch('nonex/getInquireResult', payload).then(res => {
        if (res.data.length > 0) {
          isExisting = true
        } else {
          isExisting = false
        }
      }).catch(err => {
        this.renderToast('error', 'Error', err)
      })

      return isExisting
    },
    async cebuanaAddBeneficiary (item) {
      const addBeneficiaryRequest = await this.$store.dispatch('nonex/cebuanaAddBeneficiary', {
        beneficiary: {
          first_name: item.adj_firstname,
          middle_name: item.adj_middlename ? item.adj_middlename : '',
          last_name: item.adj_lastname,
          birth_date: item.birth_date
        },
        currUser: this.currUser,
        clientID: this.cebuanaClientData.client_id
      })

      if (addBeneficiaryRequest.message === 'Successful') {
        return addBeneficiaryRequest.result.BeneficiaryID
      } else {
        return ''
      }
    },
    closeConfirmDialog () {
      this.confirmDialog = false
      this.confirmDialogTitle = ''
      this.confirmDialogText = ''
    },
    async filterExcessFundList (statusId) {
      await this.getGrid()
      if (statusId === 0) {
        this.adjListFiltered = this.adjApprovalList
      } else {
        this.adjListFiltered = this.adjApprovalList.filter(res => res.status === statusId.toString())
      }
    },
    async getGrid () {
      await this.$store.dispatch('nonexAdjustment/getAdjApprovalList', this.currUser.user_id)
    },
    async getPendingList () {
      await this.$store.dispatch('nonexAdjustment/getAdjApprovalList', this.currUser.user_id)
      this.adjListFiltered = this.adjApprovalList.filter(res => res.status === '1')
    },
    async displayAdjData (adjData, type) {
      this.adjSummaryData = adjData
      this.adjDecision = type === 'approve' ? type : 'disapproveComment'

      await this.$store.dispatch('nonexAdjustment/customerCSLookup', adjData.control_number).then(res => {
        if (res.code >= 200 && res.code <= 299) {
          if (res?.result) {
            if (res.result.length > 0) {
              const getData = res.result.filter(item => item.id === adjData.trx_id)
              this.adjSummaryData = Object.assign({}, this.adjSummaryData, { trx_data: getData[0] })

              const that = this
              setTimeout(function () {
                that.adjSummaryDialog = true
              }, 500)
            } else {
              this.adjSummaryDialog = false
            }
          }
        } else {
          this.renderToast('error', 'Error', 'Customer Record not Found')
        }
      })
    },
    submitDisapproveRequest () {
      if (this.$refs.disapproveForm.validate()) {
        this.adjApprovalAction(this.adjSummaryData, 'disapprove')
      } else {
        this.renderToast('error', 'Validation Error', 'You must add a remark')
      }
    },
    getRemcoName (id) {
      let remcoName = ''

      switch (id) {
        case 1:
          remcoName = 'iRemit'
          break
        case 2:
          remcoName = 'BPI'
          break
        case 7:
          remcoName = 'Transfast'
          break
        case 8:
          remcoName = 'Metrobank'
          break
        case 9:
          remcoName = 'Cebuana Lhuillier'
          break
        case 10:
          remcoName = 'USSC'
          break
        case 11:
          remcoName = 'Express Money'
          break
        case 12:
          remcoName = 'Ria'
          break
        case 13:
          remcoName = 'Landbank'
          break
        case 14:
          remcoName = 'USSC DOLE'
          break
        case 15:
          remcoName = 'USSC DOLE'
          break
        case 16:
          remcoName = 'InstantCash'
          break
        case 17:
          remcoName = 'JapanRemit'
          break
        case 19:
          remcoName = 'Cebuana International'
          break
        case 20:
          remcoName = 'Uniteller'
          break
        case 21:
          remcoName = 'Remitly'
          break
        case 22:
          remcoName = 'Ayannah'
          break
        case 24:
          remcoName = 'Intel Express'
          break
        case 26:
          remcoName = 'GCash Payout'
          break
        case 27:
          remcoName = 'Fastpay'
          break
        case 28:
          remcoName = 'Teleremit'
          break
        default:
          remcoName = 'Not Provided'
      }

      return remcoName
    },
    generateACR (transactionData) {
      const that = this
      const trxData = transactionData.trx_data

      console.log(transactionData)

      const currentAddress = `${trxData.address}, ${trxData.barangay}, ${trxData.city}, ${trxData.province}, ${trxData.country}, ${trxData.zip_code}`
      const agentName = `${this.currUser.last_name}, ${this.currUser.first_name}`
      const ACRPayload = {
        remco_name: this.getRemcoName(trxData.remco_id),
        sender_name: trxData.sender_name,
        remco_id: trxData.remco_id,
        trx_type: trxData.trx_type,
        receiver_name: trxData.receiver_name,
        current_address: currentAddress,
        id_type: trxData.id_type,
        id_number: trxData.id_number,
        contact_number: trxData.contact_number,
        purpose_transaction: trxData.purpose_transaction,
        control_number: trxData.control_number,
        trx_timestamp: this.formatDateTime(new Date()),
        vlocation_name: this.currUser.location_name,
        currency_name: trxData.currency_id === 1 ? 'PHP' : 'USD',
        principal_amount: trxData.principal_amount,
        service_charge: trxData.service_charge,
        total_amount: trxData.total_amount,
        buy_back_amount: trxData.buy_back_amount,
        currency_id: trxData.currency_id,
        agent_name: agentName,
        location_id: trxData.location_id,
        has_wacom: this.currUser?.has_wacom ? this.currUser.has_wacom : false,
        customer_id: trxData.customer_id.toString(),
        crn: trxData.client_reference_no,
        uploaded_by: this.currUser.user_id
      }

      if (this.currUser.has_wacom) {
        ACRPayload.img_fla = this.currUser.userSignature
      }

      if (transactionData.remco_id === 19) {
        ACRPayload.originating_country = this.transactionData.country
        ACRPayload.trx_date = this.currUser.trx_date
        this.$store.dispatch('customers/generateACRMoneygram', ACRPayload).then((res) => {
          that.base64String = res.result
          that.pdfConvert()
        }).catch((error) => {
          console.log(error)
        })
      } else {
        this.$store.dispatch('customers/generateACR', ACRPayload).then((res) => {
          that.base64String = res.result
          that.pdfConvert()
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    getApprovedStatusDesc (status) {
      let statusDesc = ''
      switch (status) {
        case '2':
          statusDesc = 'Amended'
          break
        case '3':
          statusDesc = 'Cancelled'
          break
        case '4':
          statusDesc = 'Refunded'
          break
        case '6':
          statusDesc = 'Unpaid'
          break
      }

      return statusDesc
    },
    formatDateTime (date) {
      return format(date, 'yyyy-MM-dd HH:mm:SS')
    },
    pdfConvert () {
      var decoded = Buffer.from(this.base64String, 'base64')
      var blob = new Blob([decoded], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(blob)
      window.open(fileURL)
      // FileSaver.saveAs(blob, 'filename')
    }
  }
}
</script>
<style>
.browser-item-container {
  display: flex;
  flex-direction: row;
}

.item-container {
  margin-right: 10px;
}

.item-icon {
  height: 18px;
  width: auto;
  position: relative;
  top: 4px;
}

.ph-primary-button.ph-trusted-device-search-btn {
  margin: 0 10px;
  height: 49px !important;
}
</style>
<style scoped>
  .ph-payout-summary {
    width: 100%
  }

  .ph-payout-summary tr td:first-child {
    text-align: left !important
  }

  .ph-payout-summary tr td:last-child {
    text-align: right
  }

  .ph-action-button-receive-confirm {
    padding: 25px !important
  }
</style>
